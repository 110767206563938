
.layout-container {
  & { display: flex; width: 100%; height: 100vh; }
  ::-webkit-scrollbar { width: 0px; background: transparent; }
}

.main-container {
  & {  width: 100%; color: #000; overflow-y: auto; }
}

.main {
  // & { display: flex; height: 100%; max-width: 1100px; margin: 0 auto; width: 100%; background: #f2f3f7; color: #000; width: inherit;}
  & { max-width: 100%; margin: 0 auto; width: 100%; background: #fff; color: #000; width: inherit;}
  .loading { height: 400px; display: flex; justify-content: center; align-items: center; }
}
.assessments-main {
  & {  height: 100%; max-width: 100%;  width: 100%; background: #fff; color: #000; width: inherit; overflow-x: hidden; }
  .loading { height: 400px; display: flex; justify-content: center; align-items: center; }
}

.main-margin-0 {
  margin: 0;
}



// -------------------------- SideBar ----------------------------//
.sidebar-container {
  & { height: 100%; background: #171717; color: #fff; width: 256px; height: 100%;  display: flex; flex-direction: row; align-items: left; gap: 10px; transition: width 200ms ease;box-shadow:0px 4px 6px rgba(0, 0, 0, 0.1), 0px 8px 10px rgba(0, 0, 0, 0.1), 0px 12px 15px rgba(0, 0, 0, 0.1), 0px 16px 20px rgba(0, 0, 0, 0.05); z-index: 10; }
}
