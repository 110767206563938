@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

// F37

@font-face { 
  font-family: 'F37 Jan'; 
  font-weight: 700; 
  font-style: normal; 
  src: url("../Fonts/F37Jan/F37Jan-Bold.otf") format("opentype"); 
}

@font-face { 
  font-family: 'F37 Jan'; 
  font-weight: 700; 
  font-style: italic; 
  src: url("../Fonts/F37Jan/F37Jan-BoldItalic.otf") format("opentype"); 
}

@font-face { 
  font-family: 'F37 Jan'; 
  font-weight: 300; 
  font-style: normal; 
  src: url("../Fonts/F37Jan/F37Jan-Light.otf") format("opentype"); 
}

@font-face { 
  font-family: 'F37 Jan'; 
  font-weight: 300; 
  font-style: italic; 
  src: url("../Fonts/F37Jan/F37Jan-LightItalic.otf") format("opentype"); 
}

@font-face { 
  font-family: 'F37 Jan'; 
  font-weight: 400; 
  font-style: normal; 
  src: url("../Fonts/F37Jan/F37Jan-Regular.otf") format("opentype"); 
}

@font-face { 
  font-family: 'F37 Jan'; 
  font-weight: 400; 
  font-style: italic; 
  src: url("../Fonts/F37Jan/F37Jan-RegularItalic.otf") format("opentype"); 
}

@font-face { 
  font-family: 'F37 Jan'; 
  font-weight: 100; 
  font-style: normal; 
  src: url("../Fonts/F37Jan/F37Jan-Thin.otf") format("opentype"); 
}

@font-face { 
  font-family: 'F37 Jan'; 
  font-weight: 100; 
  font-style: italic; 
  src: url("../Fonts/F37Jan/F37Jan-ThinItalic.otf") format("opentype"); 
}



$F37: 'F37 Jan', sans-serif;
$Poppins: 'Poppins', sans-serif;