@import '../../../Assests/Styles/Fonts.scss';

.banner { font-family: "Arial", sans-serif; display: flex; justify-content: center; align-items: center; background-color: #ffffff; border-radius: 10px; box-shadow: 0px 1.1px 3.3px 0px #00000040;
  &__content { display: flex; align-items: center; justify-content: space-between; max-width: 1200px; width: 100%; min-height: 435px; gap: 20px; padding: 0 20px;
    @media (max-width: 1156px) { gap: 10px; }
    @media (max-width: 776px) { flex-direction: column; min-height: auto; padding: 20px; }
  }
  &__text { padding: 48px; flex: 0 1 50%; min-height: 435px; display: flex; flex-direction: column; justify-content: center; align-items: start;
    @media (max-width: 1156px) { padding: 30px; }
    @media (max-width: 776px) { flex: 1; min-height: auto; padding: 24px; width: 100%; }
    div { font-family: $F37; line-height: 43.2px; font-size: 36px; font-weight: 700; color: #171717; width: 494px;
      @media (max-width: 1156px) { width: 100%; font-size: 32px; line-height: 38px; }
      @media (max-width: 776px) { font-size: 28px; line-height: 34px; }
      span { color: #851DD3; }
    }
    p { font-family: $Poppins; margin: 1rem 0; font-size: 16px; line-height: 24px; font-weight: 300; color: #000000; max-width: 450px;
      @media (max-width: 1156px) { max-width: 100%; }
    }
    .banner-btn { font-family: $Poppins; font-weight: 700; line-height: 19.8px; padding: 0.75rem 1.5rem; background-color: #171717; color: #fff; border: none; cursor: pointer; font-size: 13.2px; border-radius: 8.64px;transition: padding 200ms ease, background 0.3s ease,transform 80ms ease; text-decoration: none;
      &:hover { background-color: #424142;padding: 12px 34px;  }
      &:active {
        background: #424142;
        transform: translateY(5px);
      }
    }
  }
  &__image { flex: 0 1 50%; min-width: 400px; height: 435px; display: flex; align-items: center; justify-content: center; padding-right: 48px;
    @media (max-width: 1156px) { min-width: 350px; padding-right: 30px; }
    @media (max-width: 776px) { width: 100%; min-width: unset; height: auto; min-height: 300px; padding: 24px; }
  }
  &__banner-image { width: 100%; height: 100%; border-radius: 10px; object-fit: contain;
    @media (max-width: 776px) { max-height: 300px; }
  }
}