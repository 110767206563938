.time-container { width: 185px; background: #FAFAFA; border: 1px solid var(--Charcoal-200, #E5E5E5); border-radius: 8px; cursor: pointer; position: relative; 
  &:hover { background-color: #fafafa7f; }
}
  .time-header { display: flex; justify-content: space-between; padding: 8px 16px; align-items: center; }
  .time-icon-text { display: flex; gap: 8px; align-items: center; }
  .time-icon { margin-bottom: 3px; }
  .time-text { color: #737373; font-family: Poppins; font-weight: 300; font-size: 14px; line-height: 21px; }
  .time-dropdown { position: absolute; top: 100%; left: 0; width: 200px; background: #FFFFFF; border: 1px solid var(--Charcoal-200, #E5E5E5); border-radius: 8px; z-index: 10; margin-top: 8px; box-shadow: 0px 4px 6px 0px #0000000D, 0px 10px 15px -3px #0000001A; height: 208px; padding: 12px; overflow-y: auto; }
  .time-option { padding: 8px 16px; cursor: pointer; transition: background-color 0.2s ease; color: var(--Charcoal-500, #737373); font-family: Poppins; font-weight: 700; font-size: 14px; line-height: 21px; display: flex; justify-content: space-between; align-items: center; }
  .tick-icon { width: 16px; height: 16px; }
  .time-dropdown::-webkit-scrollbar { width: 4px; height: 100px; }
  .time-dropdown::-webkit-scrollbar-track { background: transparent; }
  .time-dropdown::-webkit-scrollbar-thumb { background: var(--Charcoal-300, #D4D4D4); height: 64px; border-radius: 8px; }