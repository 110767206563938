.flourishing-main { padding: 0 24px;
  .flourishing-title { color: #000000; font-weight: 400; font-size: 32px; line-height: 44px; font-family: $F37; }
  .flourishing-subtitle { & { color: #737373; font-weight: 400; font-size: 18px; line-height: 24px; height: 48px; margin-top: 4px; display: flex; justify-content: space-between; align-items: center; }
    .left-paragraph { max-width: 44ch; font-family: $Poppins; font-size: 18px; font-weight: 400; line-height: 24px; color: #737373; }
    .right-paragraph { text-align: end; font-family: $Poppins; font-size: 18px; font-weight: 400; line-height: 24px; color: #737373; }
  }
  .flourishing-container { & { margin: 24px 0px; background-color: #ffffff; border-radius:  20px; box-shadow: 0px 0px 6px 0px #0000000D, 0px 0px 15px -3px #0000001A; padding: 32px 60px 32px 32px; display: flex; justify-content: space-between; align-items: center; } 
    .img-container { & { width: 65%; max-width: 536px; position: relative; }
      img { width: 100%; height: auto; }
      .absolute-content { & { width: 100%; height: 100%; position: absolute; top: 0; left: 0; display: flex; justify-content: center; align-items: center; gap: 20px; flex-direction: column; text-align: center; } 
        h1 { font-family: $F37; font-weight: 400; font-size: 24px; line-height: 29px; color: #171717; max-width: 285px; margin: 0 auto; }
        button { & { display: flex; justify-content: center; align-items: center; padding: 12px 24px; background-color: #171717; color: #FFFFFF; gap: 8px; border-radius: 8px; cursor: pointer; } 
          p { font-size: 14px; line-height: 21px; font-weight: 700; margin: 0; }
          img { width: 24px; height: 24px; }
        }
      }
    }
    .content { & { display: flex;  font-family: $Poppins; flex-direction: column; justify-content: center; align-items: flex-start; gap: 44px; width: 36%; max-width: 330px;} 
      .content-card { & { width: 100%; } 
        h1 { font-size: 24px; font-family: $F37; line-height: 18px; font-weight: 400; color: #171717; margin: 0; margin-bottom: 16px; }
        .chart-item {
          & { display: flex; align-items: center; justify-content: space-between; padding: 8px; text-decoration: none; color: unset; margin-bottom: 6px; }
          .item-heading {
            & { display: flex; align-items: center; gap: 12px; }
            img { height: 32px; }
            h3 { font-size: 14px; font-weight: 400; font-family: $Poppins; line-height: 21px; color: #171717; text-transform: capitalize; }
          }
          .item-score { 
            & { display: flex; align-items: center; justify-content: flex-end; gap: 24px; }
            p { font-size: 14px; font-weight: 400; line-height: 17px; text-transform: capitalize; color: #737373; }
          }
        }
        p { font-size: 14px; line-height: 20px; font-weight: 400; color: #737373; margin: 0 }
      }
    }
  }
  .deploy-card { 
    & { background: #FAFAFA; border-radius: 17.24px; padding: 60px 0px; height: auto; display: flex; justify-content: center; text-align : center; }
    .cards {
      & { width: 100%; display: flex; align-items: stretch; justify-content:space-between; background-color: #FFFFFF; }
      .relationships {
        & { min-height: 310px; height: 100%; width: 32%; background-color: #FAFAFA; border-radius: 18px; padding: 32px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; }
        .icon {
          & { display: flex; align-items: center; justify-content: center; height: 40px; width: 40px; background-color: #D2F5EA; border-radius: 50%; position: relative; }
          img { position: absolute; top: 30%; left: 28%; }
        }
        .relationship-statement { 
          & { font-size: 28px; font-weight: 500; line-height: 40px; text-align: left; color: #41C0A1; max-width: 15ch; font-family: $Poppins;}
          .highlight-green { color: #1D846F; }
        }
        .relationship-statement-ThrivingDimensions { 
          & { font-size: 28px; font-weight: 500; line-height: 40px; text-align: left; color: #41C0A1; max-width: 16ch; font-family: $Poppins; }
          .highlight-green { color: #1D846F; }
        }
      }
      .health {
        & { min-height: 310px; height: 100%; width: 32%; background-color: #FAFAFA; border-radius: 18px; padding: 32px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; }
        .icon {
          & { display: flex; align-items: center; justify-content: center; height: 40px; width: 40px; border-radius: 50%; position: relative; }
          img { height: 20px; width: 25px; position: absolute; top: 25%; left: 20%; }
        }
        // .health-statement {
        //   & { font-size: 28px; font-weight: 500; line-height: 40px; text-align: left; color: #EB73FF; max-width: 15ch; font-family: $Poppins; }
        //   .highlight-orange { color: #A715B6; }
        // }
        .health-statement-ThrivingDimensions {
          & { font-size: 28px; font-weight: 500; line-height: 40px; text-align: left; color: #FDBA74; max-width: 16ch; font-family: $Poppins; }
          .highlight-orange { color: #EA580C; }
        }
      }
      .finance {
        & { min-height: 310px; height: 100%; width: 32%; background-color: #FAFAFA; border-radius: 18px; padding: 32px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; }
        .icon {
          & { display: flex; align-items: center; justify-content: center; height: 40px; width: 40px; background-color: #FBE7FF; border-radius: 50%; position: relative; }
          img {position: absolute; top: 25%; left: 25%; }
        }
        .contentment-statement {
          & { font-size: 28px; font-weight: 500; line-height: 40px; text-align: left; color: #06B6D4; max-width: 15ch; font-family: $Poppins; }
          .highlight-orange { color: #0891B2; }
        }
        .finance-statement {
          & { font-size: 28px; font-weight: 500; line-height: 40px; text-align: left; color: #EB73FF; max-width: 15ch; font-family: $Poppins; }
          .highlight-purple { color: #A715B6; }
        }
        .fade-in { opacity: 1; transition: opacity 1s ease-in; }
        .fade-out { opacity: 0; transition: opacity 1s ease-out; }
      }
    }
    .capitalize { text-transform: capitalize; border-bottom: 1px dashed; }
    .eyeIcon { & { width: 40px; height: 40px; padding: 9px; border-radius: 50px; background: #D2F5EA; display: flex; margin: auto; }
      .img { width: 100%; height: 100%; text-align : center; }   
    }
    .deploy-card-title { & { width: 400px; }
      p {  margin: 10px 0px !important;  color: #737373; font-weight: 500; font-size: 28px; line-height: 40px; }
      .notfound { font-family: $F37; }
    }
    .deploy-button-container { & { display: flex; justify-content: center; }
      .deploy-button { & { background-color: #FFFFFF; border: 1px solid #E5E5E5; width: 226px; padding: 16px 15px; border-radius: 10px; display: flex ;  align-items: center;justify-content: center; gap: 7px; cursor: pointer; }
        .deploy-button-btn-text { color: #262626; font-weight: 700; font-size: 14px; line-height: 21px; font-family: $Poppins; }
      }
    }
  }
}

.ThrivingDimensions-main { padding: 0 24px;
  .ThrivingDimensions-header { padding: 24px 0;
    .ThrivingDimensions-title { color: #000000; font-weight: 400; font-size: 32px; line-height: 44px; font-family: $F37;}
    .ThrivingDimensions-subtitle { color: #737373; font-weight: 400; font-size: 18px; line-height: 28px; font-family: $Poppins; max-width: 46ch; height: 48px; margin-top: 4px;
    }
  }
  .ThrivingDimensions-boxes { display: flex; gap: 20px;
    .box-1 { width: 100%; height: 300px; background-color: #FFFFFF; border-radius: 20px; padding: 22px 24px; box-shadow: 0px 0px 6px 0px #0000000D, 0px 0px 15px -3px #0000001A; display: flex; flex-direction: column; }
    .box1-title { color: #171717; font-weight: 400; font-size: 24px; line-height: 28.8px; font-family: $F37;}
    .box1-subtitle {
      & { color: #737373; font-weight: 400; font-size: 14px; line-height: 20px; margin-top: 20px; display: flex; flex-direction: column; gap: 16px; }
      .chart-item {
        & { display: flex; align-items: center; justify-content: space-between; }
        .item-heading {
          & { display: flex; align-items: center; gap: 16px; }
          .img-container { & { width: 32.59px; height: 32.59px; display: flex; justify-content: center; align-items: center; background-color: #F2FBF8; border-radius: 7px; }
            img { height: 11px; width: auto; }
          }
          h3 { font-size: 14px; font-weight: 400; text-transform: capitalize; line-height: 21px; color: #171717; font-family: $Poppins;}
        }
        .item-score {
          & { display: flex; align-items: center; gap: 24px; }
          p { font-size: 14px; font-weight: 400; text-transform: capitalize; line-height: 18px; color: #737373; font-family: $Poppins; }
        }
      }
    }
    .box1-button-container { margin-top: auto; display: flex; justify-content: flex-start; }
    .box1-button { background-color: #FFFFFF; border: 1px solid #E5E5E5; width: 226px; padding: 16px 15px; border-radius: 10px; display: flex ;  align-items: center;justify-content: center; gap: 7px; cursor: pointer; }
    .box1-button-btn-text { color: #262626; font-weight: 700; font-size: 14px; line-height: 21px; }
    .box-2 { width: 100%; height: 300px; background-color: #FFFFFF; border-radius: 20px; padding: 22px 24px; box-shadow: 0px 0px 6px 0px #0000000D,0px 0px 15px -3px #0000001A; display: flex; flex-direction: column; }
    .box2-title { color: #171717; font-weight: 400; font-size: 24px; line-height: 28.8px; font-family: $F37; }
    .box2-subtitle {
      & { color: #737373; font-weight: 400; font-size: 14px; line-height: 20px; margin-top: 20px; display: flex; flex-direction: column; gap: 16px; }
      .chart-item {
        & { display: flex; align-items: center; justify-content: space-between; }
        .item-heading {
          & { display: flex; align-items: center; gap: 16px; }
          .img-container { & { width: 32.59px; height: 32.59px; display: flex; justify-content: center; align-items: center; background-color: #F2FBF8; border-radius: 7px; }
            img { height: 11px; width: auto; }
          }
          h3 { font-size: 14px; font-weight: 400; text-transform: capitalize; line-height: 21px; color: #171717; font-family: $Poppins;}
        }
        .item-score {
          & { display: flex; align-items: center; gap: 24px; }
          p { font-size: 14px; font-weight: 400; text-transform: capitalize; line-height: 18px; color: #737373; font-family: $Poppins;}
        }
      }
    }
    .box2-button-container { margin-top: auto; display: flex; justify-content: flex-start; }
    .box2-button {background-color: #FFFFFF; border: 1px solid #E5E5E5; width: 226px; padding: 16px 15px; border-radius: 10px; display: flex ;  align-items: center;justify-content: center; gap: 7px; cursor: pointer; }
    .box2-button-btn-text { color: #262626; font-weight: 700; font-size: 14px; line-height: 21px; }
  } 
  .ThrivingDimensions-grid { display: grid; grid-template-columns: repeat(3, 1fr); gap: 16px; margin: 16px 0;
    .dimension-card { background-color: #FFFFFF;border-radius: 12px;padding: 20px;box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 15px -3px rgba(0, 0, 0, 0.1);
      .dimension-title { display: flex; align-items: center; margin-bottom: 10px; gap :10px; font-family: $Poppins;
        p { color: #171717; font-weight: 500; font-size: 20px; line-height: 30px; }
      }
      .dimension-description { font-size: 14px; color: #737373; font-weight: 400; line-height: 18px; margin-bottom: 40px; font-family: $Poppins; height: 56px; }
      .dimension-items { display: grid; grid-template-columns: repeat(2, 1fr); gap: 10px;
        .item { background-color: #F3F4F6; border-radius: 6px; padding:  12px; font-size: 12px; color: #4B5563; height: 90px; display: flex;  flex-direction: column; justify-content: space-between;
        }
        .item > div { display: flex; flex-direction: column; height: 100%; }
        .bottom-items { display: flex; justify-content: space-between; align-items: flex-end; margin-top: auto;
          .bottom-items-name { width: 40px; color: #D4D4D4; font-weight: 400; font-size: 12px; line-height: 16px; font-family: $F37; }
        }
      }
    }
  }
}

.demographics-main { padding: 0 24px;
  .demographics-title { display: flex; justify-content: space-between; align-items: center; padding: 24px 0; 
    .left {
      .heading { color: #000000; font-weight: 400; font-size: 32px; line-height: 44px; font-family: $F37; }
      .subheading { color: #737373; font-family: $Poppins; font-weight: 400; font-size: 18px; line-height: 28px; }
    } 
    .right {
      .cta-viewall { background-color: #FFFFFF; border: 1px solid var(--Charcoal-200, #E5E5E5); display: flex; align-items: center; gap: 8px; font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 21px; color: #262626; padding: 12px 24px; border-radius: 8px; cursor: pointer; }
    }
  }
  .cards {
    & { width: 100%; display: flex; align-items: center; justify-content:space-between; gap: 15px; }
    .card {
      & { height: 300px; max-height: 100%; width: 33%; background-color: #FAFAFA; border-radius: 18px; padding: 16px 24px 44px 24px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; margin-bottom: 15px; }
      .dimension-title { color: #171717; font-weight: 400; font-size: 21px; line-height: 25px; font-family: $F37; } 
      .icon { height: 40px; }
      .statement-box { & {width: 100%; height: 100%; justify-content: space-between;}
        .chart-container { display: flex; gap: 16px; margin-top: 16px; }
      }
      .statement {
        & { display: flex; flex-direction: column; gap: 24px; }
        .col-statement { 
          & { font-size: 25px; font-weight: 700; line-height: 40px; text-align: left; color: rgba(0, 0, 0, 0.44); width: 280px; font-family: $F37;}
        }
        .percentages { font-size: 56px; font-weight: 700; line-height: 36px; color: #1D846F; font-family: $F37; }
      }
    }
    .highlight-green { color: #2AAD8F; }
  }
  .demographics-content {
    background-color: #FAFAFA; border-radius: 17.24px; display: flex ; align-items: center; justify-content: center; padding: 32px ;
  .eyeIcon { & { width: 40px; height: 40px; padding: 9px; border-radius: 50px; background: #D2F5EA; display: flex; margin: auto; }
    .img { width: 100%; height: 100%; text-align : center; }   
  }
  .deploy-card-title { & { width: 452px; margin: 0 auto; text-align: center; }
    p {  margin: 10px 0px !important;  color: #737373; font-weight: 500; font-size: 28px; line-height: 40px;font-family: "F37 Jan"; }
  }
  .deploy-button-container { & { display: flex; justify-content: center; padding: 10px ; }
    .deploy-button { & { background-color: #FFFFFF; border: 1px solid #E5E5E5; width: 226px; padding: 16px 15px; border-radius: 10px; display: flex ;  align-items: center;justify-content: center; gap: 7px; cursor: pointer;}
      .deploy-button-btn-text { color: #262626; font-weight: 700; font-size: 14px; line-height: 21px; }
    }
  }
}

.pieTooltiptext { max-width: 250px; padding: 10px; box-shadow: 0px 0px 10px #b2b2b2; background: #fff; font-size: 6.78px; font-weight: 300; line-height: 21px; color: #171717; font-family: Poppins; line-height: 10.17px; border-radius: 4px;}
.pieTooltip-arrow:after { content: ""; position: absolute; left: 0; right: 0; margin: 0 auto; width: 0; height: 0; border-top: 5px solid #fff; border-left: 5px solid transparent; border-right: 5px solid transparent; }
} 

@media screen and (max-width: 944px) {
  .flourishing-main .flourishing-container { flex-direction: column; }
  .flourishing-main .flourishing-container .img-container { width: 100%; }
  .result-container .bottomghaph { flex-wrap: wrap; }
  .header .header-section1 .header-text1 { font-size: 31px; }
  .flourishing-main .flourishing-title { font-size: 28px; }
  .flourishing-main .flourishing-subtitle .left-paragraph { font-size: 15px; }
  .flourishing-main .flourishing-subtitle .right-paragraph { font-size: 15px; }
  .flourishing-main .deploy-card .cards .relationships .relationship-statement { font-size: 14px; }
  .flourishing-main .deploy-card .cards .health .health-statement { font-size: 14px; }
  .flourishing-main .deploy-card .cards .finance .finance-statement { font-size: 14px; }
  .deploy-card .cards .health .health-statement-ThrivingDimensions { font-size: 14px; }
  .deploy-card .cards .finance .finance-statement { font-size: 14px; }
  .dashboard-container .flourishing-main .flourishing-subtitle .left-paragraph { font-size: 14px; }
  .flourishing-main .flourishing-subtitle .right-paragraph { font-size: 14px; }
  .ThrivingDimensions-main .ThrivingDimensions-header .ThrivingDimensions-subtitle { font-size: 14px; }
  .demographics-main .demographics-title .left .subheading { font-size: 14px; }
  .ThrivingDimensions-main .ThrivingDimensions-header .ThrivingDimensions-title { font-size: 28px; }
  .demographics-main .demographics-title .left .heading { font-size: 28px; }
  .flourishing-main, .ThrivingDimensions-main { .flourishing-container, .ThrivingDimensions-grid {  display: flex;  flex-direction: column;  gap: 15px;  align-items: flex-start; } }
  .flourishing-main .flourishing-container .content { width: 100%; max-width: 700px; }
  .demographics-main .cards .card .statement .percentages { font-size: 46px; }
  .dashboard-container { padding: 10px; 
    .flourishing-main .flourishing-container .content .content-card h1 { width: 400px; }
    .flourishing-main .flourishing-subtitle .left-paragraph { padding-top: 20px; }     
    .flourishing-main .flourishing-container .content .content-card .chart-item .item-score p {  margin-left: 80px; }    
    .deploy-card .cards .relationships .relationship-statement-ThrivingDimensions { font-size: 14px; }     
    .content-card, .box-1, .box-2, .dimension-card { width: 100%; h1 { font-size: 1rem; } p { font-size: 0.8rem; } }
    .img-container { .absolute-content { top: 15%; left: 10%; transform: translate(-10%, -15%); h1 { font-size: 1.2rem; padding-left: 95px; }  button { padding: 8px 12px; font-size: 0.8rem; img { width: 12px; } } } }
    .demographics-main .cards {  flex-direction: column; }
    .demographics-main .cards .card {  width: 100%; }
    .ThrivingDimensions-boxes { .box1-button, .box2-button { padding: 8px; font-size: 0.8rem; img { width: 12px; } } }
    .dimension-items { .item { width: 100%; } }    
    .flourishing-main .flourishing-container .img-container .absolute-content button { margin-left: 111px; }
  }
  }
  @media (max-width: 700px) { }

  @media (max-width: 550px) {

  .flourishing-main .flourishing-container .img-container { height: 300px; }
  .flourishing-main .flourishing-container .img-container .absolute-content button { padding: 6px 12px; }  
  .ThrivingDimensions-main .ThrivingDimensions-boxes { flex-direction: column; }
  img, picture, video, canvas, svg { display: block; max-width: none !important; }
  .recharts-surface { width: 311px !important; height: 244px !important; }
  .demographics-main .cards .card .statement-box .chart-container { gap:0px; }   
  }