.react-datepicker { border: 1px solid var(--Charcoal-200, #E5E5E5) !important; border-radius:  12px !important; height: 288px; position: relative; width: 284px; padding: 10px 16px; }
.react-datepicker__header  { background-color: white !important; border-bottom: none !important; border-radius:  12px !important; }
.react-datepicker__month-container { width: 100%; }
.monthText { color:  var(--charcoal-900-black, #171717); font-weight: 700; font-size: 14px; line-height: 16.8px; font-family: F37 Jan; }
.react-datepicker__day-name { color:  var(--Charcoal-500, #737373) !important; font-family:  Poppins !important; font-weight: 700 !important; font-size: 12px !important; line-height: 18px !important; text-transform: capitalize !important; }
.react-datepicker__day--outside-month { color:  white !important; }
.react-datepicker__day { color: var(--charcoal-900-black, #171717) ; font-family: Poppins !important; font-weight: 300 !important; font-size: 14px !important; line-height: 21px !important; }
.button-container { position: absolute; bottom: 20px; display: flex; gap: 12px; padding: 0px 16px;
    .today-button { width: 120px; height: 32px; border-radius: 8px; background: var(--charcoal-900-black, #171717); font-family: Poppins; font-weight: 700; font-size: 12px; line-height: 18px; color: var(--White-White, #FFFFFF); display: flex; justify-content: center; align-items: center; cursor: pointer; }
    .clear-button { width: 120px; height: 32px; border-radius: 8px; background: var(--White-White, #FFFFFF); font-family: Poppins; font-weight: 700; font-size: 12px; line-height: 18px; color: var(--Charcoal-800, #262626); display: flex; justify-content: center; align-items: center; border: 1px solid var(--Charcoal-200, #E5E5E5); cursor : pointer; }
}
.calendar-container { width: 225px; background: #FAFAFA; border: 1px solid var(--Charcoal-200, #E5E5E5); border-radius: 8px; cursor: pointer;
  &:hover { background-color: #fafafa7f; }
}
.calendar-header { display: flex; justify-content: space-between; padding: 8px 16px; align-items: center; }
.calendar-icon-text { display: flex; gap: 8px; align-items: center; }
.calendar-icon { margin-bottom: 3px; }
.calendar-text { color: #737373; font-family: Poppins; font-weight: 300; font-size: 14px; line-height: 21px; }
.date-picker-container { position: absolute; z-index: 1000; margin-top: 8px; }
.calender-main-container { margin: 10px; display: flex; flex-direction: column; background: white; }
.header-container { display: flex; justify-content: space-between; align-items: center; }
.month-button { background: white; border: none; cursor: pointer; font-size: 14px; color: #737373; }

.react-datepicker__day--keyboard-selected{
    background: transparent !important;
    color: black !important;
}
.react-datepicker__day--selected{
    background: transparent !important;
    color: white !important;
}
