.table-container {
    & { border: 1px solid var(--Charcoal-200, #E5E5E5); border-radius: 16px; box-shadow: 0px 1px 2px 0px #1018280F; box-shadow: 0px 1px 3px 0px #1018281A; margin: 10px 0px; }
}

.organization-table {
    & { display: flex; flex-direction: column; }
    .header-row, .row { display: flex; width: 100%; }
    .header-row { font-weight: 700; background-color: #FAFAFA; font-family: 'Poppins', sans-serif; font-size: 12px; font-weight: 700; line-height: 18px; text-align: left; height: 44px;}
    .row { border-top: 1px solid #E5E5E5; padding: 14px 0; box-sizing: border-box; font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 300; line-height: 20px; text-align: left; color: #737373; height: 72px; }
    .cell { display: flex; align-items: center; padding: 0 20px; gap: 2px; }
    .checkbox-column {
      & {width: 64px;}
      input[type="checkbox"] { transform: scale(1.3); margin: 0; cursor: pointer; }
      img { cursor: pointer; }
    }
    .no-data-message { text-align: center; margin: 20px 0; width: 100%; }
    .name-column { width: 483px; font-weight: 700; color: #171717; cursor: pointer; }
    .url-column { width: 114px; font-size: 12px; }
    .link-column { width: 96px; padding: 0; justify-content: center; }
    .row:hover { background-color: #F9FAFB; }
    .pagination {
      & { display: flex; justify-content: space-between; padding: 16px 24px; border-top: 1px solid #E5E5E5; }
      .items-perpage {
        & { display: flex; gap: 12px; align-items: center; }
        p { font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 300; line-height: 20px; text-align: left; color: #171717 }
        .dropdown-container {
          & { position: relative; }
          .rows-dropdown {
          & { appearance: none; padding: 8px; font-size: 14px; font-weight: 300; line-height: 20px; border: 1px solid #D4D4D4; border-radius: 8px; background-color: white; cursor: pointer; min-width: 82px }
          &:hover { border-color: #9ca3af; }
          &:focus { outline: none; border-color: #171717; box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1); }
        }
        .dropdown-icon { position: absolute; right: 0.75rem; top: 50%; transform: translateY(-50%); pointer-events: none; color: #171717; }}
      }
      .right-btns {
        & { display: flex; align-items: center; }
        .btn { border: 1px solid #D4D4D4; padding: 6px 8px; height: 30px; }
        .previous { border-radius: 8px 0 0 8px; }
        .next { border-radius: 0 8px 8px 0; }
        span { font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 700; line-height: 21px; text-align: center; color: #737373; padding: 0 10px; border: 1px solid #D4D4D4; min-width: 30px; min-height: 30px; display: flex; align-items: center; justify-content: center; }
        .current { background-color: #262626; color: #fff; }
      }
    }
  }
  @media (max-width: 700px) {
    .organization-table .pagination .items-perpage p { font-size: 11px; }
  }