@import "../../../Assests/Styles/Fonts.scss";

.church-thriving {background: #FFFFFF;border-radius: 12px;padding: 32px;box-shadow: 0px 0px 6px 0px #0000000D, 0px 0px 15px -3px #0000001A;

&__title { font-family: $F37; line-height: 33.6px;font-size: 28px;
  font-weight: 700; margin-bottom: 8px;
 }
&__subtitle {max-width: 474px;font-family: $Poppins;line-height: 18px;font-weight: 300;font-size: 14px; color: #525252;
margin-bottom: 24px;
strong {
   font-weight: 700;
  }
 }
 &__metric-card-container { display: flex; gap: 16px; padding-bottom: 16px; justify-content: space-between; align-items: stretch;flex-wrap: wrap;
   > * {  flex: 1;
   min-width: 240px;
  }
 }
 &__chart-section { margin-bottom: 32px; padding: 20px 0px 20px 0px;
 &__chart-image {  width: 100%;  border-radius: 8px;  height: 100%;  border-radius: 10px;  object-fit: contain;
  }
 }

 &__chart-legend { margin-bottom: 32px; font-family: $Poppins; font-size: 12px; font-weight: 300; color: #171717; display: flex; gap: 16px; flex-wrap: wrap; justify-content: center; align-items: center;

  &-item { display: flex; align-items: center;   gap: 8px;

   .grey-dot {background-color: #C581FF;border-radius: 50%;width: 8px;height: 8px;
   }

   .green-dot { background-color: #00A469; border-radius: 50%; width: 8px; height: 8px;
   }
  }
 }
 &__insights {display: flex; gap: 16px; flex-wrap: wrap;

 &__button {display: flex;align-items: center;justify-content: center;gap: 8px;font-family: $Poppins;padding: 12px 24px;border-radius: 7px;border: 1px solid #E5E5E5; background-color: #fff; color: #262626; cursor: pointer; font-size: 14px;font-weight: 700;line-height: 18px;
 }
}

.metric-card {padding: 24px; border-radius: 24px;border: 1px solid #FFFFFF;background: #FAFAFA;cursor: pointer;
 transition: all 0.1s ease-in-out;
 &:hover {background: #E5E5E5; padding: 24px 34px; }
 &:active { background: #fafafac1;  transform: translateY(8px); }
 &--active {border: 1px solid #424142; background-color: #FFFFFF;box-shadow: 0px 4px 6px 0px #0000000D, 0px 10px 15px -3px #0000001A;
 }

 &__title {font-family: $F37;font-size: 22px;font-weight: 700;line-height: 26.4px;color: #171717;margin-bottom: 12px;
 }

 &__description {font-family: $Poppins;font-size: 12px;font-weight: 300;line-height: 18px;color: #525252; width: 312px;
 }
}
}