.church-health-header-container {
    & { display: flex; justify-content: space-between; align-items: center; width: 100%; padding: 20px 24px; background-color: #ffffff; z-index: 9; height: 88px; position: fixed; border-bottom: 1px solid #E5E5E5; box-shadow:0px 0.5px 0.5px #E5E5E5;
        
        .ch-heading { font-family: $F37; font-size: 36px; font-weight: 700; color: #171717; line-height: 43.2px; }
        .header-section2 { display: flex; gap: 12px; align-items: center; max-width: 378px;
            
           .images-container { display: flex; gap:  4px;
                .Image-1 { background-color: #D03138;  padding: 6.02px; border-radius: 4.81px; width: 36.09px; height: 36.09px;
                    img { width: 100%; height: 100%; }
                }
                .Image-2 { background-color: #ffffff; border-radius: 4.81px; width: 36.09px; height: 36.09px;
                    img { width: 100%; height: 100%; }
                }

            }
            .header-text2 {
                font-family:$Poppins; font-weight: 400; font-size: 12px; line-height: 18px; color: #737373;
            }
        }  
    }
    
    @media only screen and (max-width: 830px) {
    
        .header { flex-direction: column; position: relative;}
        .header .header-section1 .header-text1 p {
            width: 430px;
            padding-bottom: 20px;
        }
    }
    
}


