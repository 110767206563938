.partners-container { display: flex; width: 100%; gap: 120px;
  .leftpart { width: 280px;}
  .rightpart { 
    .partnerssearch {
      .partnerssearch-text { color: #171717; font-weight: 700; font-size: 14px;  line-height: 21px; }
    }
    .button { display: flex; gap: 10px;
      .cta-add { display: flex; font-size: 12px; font-weight: 700; line-height: 18px; background: transparent; border: 1px solid var(--Charcoal-200, #E5E5E5); border-radius: 8px; padding: 10px 25px; align-items: center; width: 201px; justify-content: center; }
    }
    .partnersname{ margin: 20px 0; display: flex; gap: 4px; flex-wrap: wrap;
      .subpartnersname { display: flex; gap: 4px; flex-wrap: wrap; }
      .pathericon { display: flex; justify-content: center; align-items: center; }
      .big { width: 186px; height: 174px; font-size: 40px;  background-color: #D03038; color: #ffffff; border-radius: 12px; }
      .youversionicon { width: 82px; height: 82px; border-radius: 8px; background-color: #000000; }
      .americanicon { background-color: #005F96; width: 82px; height: 82px; border-radius: 8px; }
      .careyicon { background-color: #4687C6; width: 82px; height: 82px; border-radius: 8px; }
      .bibleprojecticon { background-color: #FAFAFA; width: 184px; height: 92px; }
      .klovelogo { background-color: #ffffff; display: flex; justify-content: center; align-items: center; flex-direction: column; gap: 4px;  
        .blackup, .blackdown { background-color: #000000; width: 188px; height: 59px; border-radius: 8px; }
        .imag { background-color: #FAFAFA; border-radius: 12px; padding: 14px 0; }
      }
    }
  }
}