
.qrcode {  border-top: 1px solid #E3E3E3; padding : 32px 0px ;   border-bottom: 1px solid var(--Charcoal-200, #E5E5E5)} 
.share-container { font-family: $Poppins;
  .css-19kzrtu { padding: 10px 24px !important; }
  .share { & { display: flex; gap: 120px;  }
  .left-share { & { width: 278px;}
    p { font-size: 12px; font-weight: 300; color: #525252; margin: 12px 0px 0px 0px; font-family: $Poppins; line-height: 18px; }
    .unique-description { margin-bottom: 32px; }
    .heaping-share { & { display: flex; }
      .headtext { font-size: 21px; font-weight: 400; color: #171717;  margin: 2px 0 0; font-family: $F37;
      span { font-size: 18px; width: 200px; }
    }
    
    }
    .headSubtext{ color: var(--Charcoal-600, #525252); font-family: $Poppins; font-weight: 300; font-size: 12px;
      line-height: 18px; }
  }
  .right-share { font-family: $Poppins;
    input { font-family: 'Poppins'; font-size: 14px; font-weight: 300; color: #171717; }
    .urltext { font-family: $Poppins; font-weight: 600; size: 14px; line-height: 21px; color:  var(--charcoal-900-black, #171717); margin-bottom: 10px; }
    .url-input-container { width: 365px;  border-radius: 8px;  background-color:  var(--Charcoal-50, #FAFAFA);
      border: 1px solid var(--Charcoal-200, #E5E5E5) ; display: flex; align-items: center;  
      .https-text { font-weight: 300; font-size: 14px; line-height: 21px; color: var(--charcoal-900-black, #171717); padding-right: 20px; border-right: 1px solid #E5E5E5; padding: 8px 16px; height: 48px; display: flex; align-items: center; }
      .url-input { height: 48px; width: 100%; border-top-right-radius: 8px; border-bottom-right-radius: 8px; background: none; border: none;
      &:focus { outline: none; }
      }
    }
 
    .css-1wgn40h-MuiInputBase-root-MuiOutlinedInput-root { min-width: 400px; }
    .css-dmmspl-MuiFormGroup-root {
      & { flex-direction: column; gap: 20px; }
      .anonymous {
        & { display: flex; flex-direction: column; }
        .anonymous-note { 
          & { margin-left: 32px; font-family: $Poppins; font-size: 12px; font-weight: 300; line-height: 18px; color: #737373  ; }
        }
        .custom-label { 
          & { color: #171717; }
        }
        .custom-radio {
          & { color: #171717; }
        }
      }
      .collect-name-and-phone {
        & { display: flex; flex-direction: column; }
        .collect-note {
          & { margin-left: 32px; font-family: $Poppins; font-size: 12px; font-weight: 300; line-height: 18px; color: #737373  ; }
        }
        .custom-label { 
          & { color: #171717; }
         }
      }
    }
    .radio-container { display: flex; flex-direction: column; gap: 32px;
      .name-collection-container { 
        height: 139px; display: flex; flex-direction: column; gap: 20px;
        .name-collection { font-family: $Poppins; font-weight: 600; font-size: 16px; line-height: 24px; color: var(--charcoal-900-black, #171717);
        }
       }
       
      
      .collection-option { display: flex; gap: 12px;
        
        .radio-button { height: 16px; margin-top: 2px; cursor: pointer; }
        .text {
          .option { font-family: $Poppins; font-weight: 600; font-size: 14px; line-height: 21px; color: #171717; cursor: pointer;  }
          .description{font-family: Poppins;font-size: 12px;font-weight: 300;line-height: 18px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #737373;
            }
          .gray-text { color: var(--Charcoal-400, #A3A3A3);
          }
          .subtext { color: #737373; font-family: $Poppins; font-weight: 300; line-height: 18px; font-size: 12px; max-width: 216px; }
        }
        .phone { 
          display: flex; gap: 8px ; align-items: center;
         }
      }
      .integrations-container {
        font-family: $Poppins; font-weight: 600; font-size: 16px; line-height: 24px; color: var(--charcoal-900-black, #171717); display: flex; flex-direction: column; gap: 20px;
        .integrations-subcontainer { 
          display: flex; gap: 11px; align-items: center;
        }
      }
      
    }
    .user-inputs {
      & { display: flex; gap: 12px; width: 100%; }
      .from-name-input {
        & { width: 50%; }
        .from-name { font-family: $F37; font-size: 14px; font-weight: 700; line-height: 16.8px; color: #171717; }
        .user-inputs {
          & { width: 100%; border: 1px solid #E5E5E5; background-color: #FAFAFA; outline: none; border-radius: 8px; }
        }
      }
      .from-organisation-input {
        & { width: 50%; }
        .from-organisation { font-family: $F37; font-size: 14px; font-weight: 700; line-height: 16.8px; color: #171717; }
        .user-inputs {
          & { width: 100%; border: 1px solid #E5E5E5; background-color: #FAFAFA; outline: none; border-radius: 8px; }
        }
      }
    }
    .css-1edfpdg-MuiTypography-root { font-family: $Poppins; }
    .css-6od3lo-MuiChip-label { background-color: #D9F1E1; color: #117D41; font-size: 12px; font-weight: 700; border-radius: 12px; font-family: $Poppins; }
    .css-bql6zh-MuiInputBase-root-MuiOutlinedInput-root, .css-1wgn40h-MuiInputBase-root-MuiOutlinedInput-root { border-radius: 12px; }
    .css-pmk1r9-MuiAutocomplete-root .MuiOutlinedInput-root { padding: 4px 10px; border: 1px solid #D4D4D4; }
    .css-pmk1r9-MuiAutocomplete-root .MuiAutocomplete-tag  { background-color: transparent; color: transparent; } 
    .css-1g9bazu-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon,.css-1pkh4lu-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator { background-color: transparent; color: transparent; display: none; }
    .link { width: 400px; padding: 10px; border: 1px solid #D4D4D4; border-radius: 8px; background-color: #d4d4d423; }
    .copy-link-container { display: flex; justify-content: space-between; align-items: center; width: 100%; min-width: 400px; max-width: 700px; padding: 16px; border-radius: 8px; border: 1px solid #D4D4D4; background: #FAFAFA; font-size: 12px; font-weight: 700; font-family: $Poppins;  position: relative;
      & .clink {background-color: #D9F1E1; color: #117D41; padding: 4px 8px; border-radius: 18px; word-break: break-word; flex-grow: 1; white-space: normal; }
      & svg {cursor: pointer; width: 16px; height: 18px; margin-left: 10px; transition: transform 0.3s; }
      .tooltip .tooltiptext {left: calc(0% - 50px); min-width: 120px; border-radius: 10px; text-align: center;}
      .tooltip:hover .tooltiptext::after { width: 10px; height: 10px; bottom: -5px; left: calc(50% - 5px); }
    }
    .uniquelink { color: #171717; font-size: 14px; font-weight: 700; line-height: 21px; font-family: $Poppins; }
    .cta-share { display: flex; font-size: 12px; cursor: pointer; font-weight: 700; line-height: 18px; background: transparent; border: 1px  solid var(--Charcoal-200, #E5E5E5); border-radius: 8px; padding: 12px 24px; align-items: center;  gap: 4px;   height: 40px;
    background: var(--White-White, #FFFFFF); color: var(--Charcoal-800, #262626); font-family: $Poppins;

    }
    .createbroadcastshare { margin-bottom: 16px; }
    .customtextbox {  
      .image-uploaded-section { & { display: flex; justify-content: flex-start; align-items: center; gap: 8px; margin-top: 24px; } 
        img { width: 48px; height: 48px; border-radius: 100px; }
        h1 { font-size: 14px; line-height: 21px; font-weight: 700 !important; color: #171717; font-family: $Poppins; }
        h2 { font-size: 12px; line-height: 18px; font-weight: 300 !important; color: #737373; }
        .remove-image-btn { margin-left: 16px; color: #B91C1C; text-decoration: underline; font-size: 12px; font-weight: 700; line-height: 18px; cursor: pointer; }
      }
      p.error-text { color: red; font-size: 0.75rem; font-weight: normal; }
      p { color: #171717; font-weight: 600; font-size: 16px; line-height: 24px; margin: 6px 0; }
      
      .text-box-container { border: 1px solid #e0e0e0; border-radius: 8px; padding: 8px; width: 600px; height: 200px; max-width: 600px; box-sizing: border-box; background-color: #FAFAFA;
        &.error { border: 1px solid #d32f2f; }
      .text-box { position: relative;
        .character-count { font-size: 12px; color: #737373; position: absolute; top: -2px; left: 12px; }
        textarea { width: 100%; height: 148px; border: none; padding: 12px; font-size: 14px; font-weight: 300; resize: none; box-sizing: border-box; outline: none; background-color: #FAFAFA;
        &::placeholder { color: #737373; }
      }
      .selected-image-container {
        display: flex;
      }
      }
  .toolbar { display: flex; justify-content: flex-start; align-items: center; gap: 10px; margin-top: 8px; color: #666;
    .icon { font-size: 18px; cursor: pointer; color: #666; }
  }
}
    }
  }
}
.switchtext, .css-1edfpdg-MuiTypography-root {  font-weight: 600; font-size: 14px; line-height: 21px; color: #171717; font-family: $Poppins; }
}
.deploy {
  padding : 32px 0px ;  border-bottom: 1px solid var(--Charcoal-200, #E5E5E5) ; height: 160px;
  .left-share {
    .description { width: 252px; }
  }
}
.state {
  padding : 32px 0px ;  border-bottom: 1px solid var(--Charcoal-200, #E5E5E5) ; height: 160px;
  .left-share {
    .description { width: 252px; }
  }
  .right-share {
    .getresource { margin-top: 10px; }
  }
}
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label { font-size: 14px; font-weight: 600; line-height: 21px; color: #171717; }
.css-1naghc5-MuiSwitch-root .MuiSwitch-thumb { width: 17.5px !important; height: 17.5px !important; }

@media only screen and (max-width: 880px) {
  .share-container .share { flex-direction: column; gap: 0; }
  .share-container .share .right-share { margin: 34px; }
  .share-container .share .right-share .link { width: 250px; }
  .share-container .share .left-share p { margin: 14px 0 0; }
}