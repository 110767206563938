// .church-health-header-container {
//     & { display: flex; justify-content: space-between; align-items: center; width: 100%; padding: 20px 24px; background-color: #ffffff; z-index: 9; height: 88px; position: fixed; border-bottom: 1px solid #E5E5E5; box-shadow:0px 0.5px 0.5px #E5E5E5;
//     .mobile-header-container {display: flex; justify-content: space-between; width: 100%;  align-items: center;
//         .mobile-header-text { color: var(--charcoal-900-black, #171717); font-family: "F37 Jan"; font-weight :700 ; font-size : 28px ;  line-height: 33.6px;
//           .headername { padding: 10px 18px; }    
//         }
//         .mobile-header-cta { width: 40px; height: 40px; border-radius: 8px; padding: 8px; border: 1px solid var(--Charcoal-200, #E5E5E5); display: flex; align-items: center; justify-content: center; }
//     }
//     }
// }

.mobile-header-container { width: 100%; background-color: #ffffff; z-index: 10;
  .limitedtimebanner-container { padding: 10px 11px 0; background-color: #ffffff; }
  .mobile-header-text { color: #171717; font-family: "F37 Jan"; font-weight :700 ; font-size : 28px ;  line-height: 33.6px; background-color: #ffffff; z-index: 10; width: 100vw;
    .headername { padding: 20px 26px; }    
  }
}