.register-page { display: flex !important; justify-content: center !important; align-items: center; min-height: 100vh; background-color: #f3f4f6;
  }
  .register-container { display: flex; flex-direction: column; align-items: center; width: 100%; max-width: 36rem;  padding: 4rem 1rem; margin: 0 auto; }
  .register-card { background-color: white; width: 100%; padding: 3rem 4rem; border-radius: 0.75rem; box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); }
  .powered-by { display : flex ; justify-content: center; text-align: center; margin-top: 2.5rem;
    p { font-size: 0.875rem;  color: #474a52;  font-weight: 600; }
    .logo-wrapper { margin-top: 1rem; margin-bottom: 2rem; }
    .glooai-logo { width: 172px; height: 60px; object-fit: contain; }
  }
  .register-content {
    .logo-container { display: flex; justify-content: center; margin-bottom: 1.5rem;
      .logo { max-width: 250px; height: auto; }
    }
    .sign-in-text { text-align: center; font-size: 1.5rem; font-weight: 600; margin-bottom: 1.5rem; }
    .register-form {
        .form-group { margin-bottom: 1.5rem;
          label { display: block; font-size: 0.875rem; margin-bottom: 0.5rem;
            .required { color: #ef4444; margin-left: 0.25rem; }
          }
          input,
          .password-input-container { width: 100%; padding: 10px 0.75rem; font-size: 0.875rem; border: 1px solid #d1d5db; border-radius: 0.375rem; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            &:focus-within { outline: none; border-color: #7242F9; box-shadow: 0 0 0 3px rgba(114, 66, 249, 0.1); }
          }
          input[name="password"],
          input[name="confirmpassword"] { border: none; background: transparent; box-shadow: none;
            &:focus { outline: none; box-shadow: none; }
          }
          .password-input-container { display: flex; align-items: center; padding: 0;
            input { flex-grow: 1; border: none; background: transparent; padding: 0.75rem; outline: none;
            }
            .password-toggle { background: none; border: none; padding: 0.5rem; cursor: pointer;
              .password-icon { width: 20px; height: 20px;
              }
            }
          }
          .forgot-password { text-align: right; margin-top: 0.5rem;
            a { color: #7242F9; text-decoration: none; font-size: 0.875rem;
              &:hover { text-decoration: underline; }
            }
          }
        
      }
      .submit-button { width: 100%; padding: 0.75rem; background-color: #7242F9; color: white; font-size: 1rem; font-weight: 600; border: none; border-radius: 0.375rem; cursor: pointer; transition: background-color 0.2s ease;
        &:hover { background-color: darken(#7242F9, 10%); }
      }
      .login-link { margin-top: 1.5rem; text-align: center; font-size: 0.875rem; color: #6b7280; display: flex; justify-content: center; align-items: center;
        p { margin-right: 0.5rem; }
        .login-text { color: #7242F9; font-weight: 600; text-decoration: none;
            &:hover { text-decoration: underline; }
        }
    }
    }
    .captcha-group { margin-bottom: 1.5rem;
        .captcha-container { display: flex; align-items: center; gap: 1rem; margin-bottom: 0.5rem;
            .captcha-image { width: 12rem; height: 60px; border: 1px solid #d1d5db; border-radius: 7px 0.375rem; display: flex; justify-content: center; align-items: center; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                .sample-captcha { font-family: 'Courier New', monospace; font-size: 1.5rem; font-weight: bold; color: #4b5563; letter-spacing: 0.25rem;
                }
            }
            .reload-button { background: none; border: 1px solid #d1d5db; border-radius: 50%; padding: 0.5rem; cursor: pointer; transition: background-color 0.2s ease;
                &:hover { background-color: #f3f4f6; }
                .reload-icon { width: 20px; height: 20px; }
            }
        }
        input[name="captcha"] { margin-top: 0.5rem; }
        .error-message { color: #ef4444; font-size: 0.75rem; margin-top: 0.25rem; }
    }
  }
  @media (max-width: 640px) {
    .register-container { padding: 2rem 1rem;  max-width: 100%; }
    .register-card { padding: 1.5rem; }
  }
  @media (min-width: 641px) and (max-width: 1024px) {
    .register-container { max-width: 36rem; }
  }