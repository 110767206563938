.Demographics-container {  max-width: 1200px; margin: 8px auto; display: flex; justify-content: center;
    .demographics-title { display: flex; justify-content: space-between; align-items: center; padding: 24px 0; 
        .left {
          .heading { color: #000000; font-family: $F37; font-size: 28px; font-weight: 700; line-height: 33.6px; }
          .subheading { color: #525252; font-family: $Poppins; font-size: 14px; font-weight: 300; line-height: 21px; margin-top: 5px; }
        } 
        .right {
          .cta-viewall { background-color: #FFFFFF; border: 1px solid var(--Charcoal-200, #E5E5E5); display: flex; align-items: center; gap: 8px; font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 21px; color: #262626; padding: 12px 24px; border-radius: 8px; cursor: pointer;transition: padding 200ms ease, background 0.3s ease,transform 80ms ease;
            &:hover{background: #E5E5E5;padding: 12px 34px;}
            &:active {
              background: #E5E5E5;
              transform: translateY(5px);
            }
           }

        }
      }
    .Demographics-cards { width: 1189px; height: 402px; display: flex; gap: 16px; overflow-x: auto;
       
        .ch-card3 { width: 386.67px; border-radius: 16px; padding: 32px; background: var(--White-White, #FFFFFF); 
            .ch-card3-img-container { margin: 40px 0px 20px 0px; }
            .ch-card3-detail-container { height: 239px; width: 300px;   display: flex;  align-items: end;
                .ch-card3-description { font-family: "F37 Jan"; font-weight: 400 ; font-size: 28px; line-height: 33.6px; color: #171717; margin-bottom: 20px;
                }
                .ch-card3-cta { background: var(--White-White, #FFFFFF); width: auto; height: 48px; border-radius: 8px; padding: 12px 24px; border: 1px solid var(--Charcoal-200, #E5E5E5) ; display: flex; justify-content: space-between; align-items: center; cursor: pointer;transition: padding 200ms ease, background 0.3s ease,transform 80ms ease;
                  &:hover{padding: 12px 34px;background: #E5E5E5;}
                  &:active {
                    background: #E5E5E5;
                    transform: translateY(5px);
                  }
                    .ch-card3-cta-title { font-family: "Poppins"; font-weight: 700; font-size: 14px; line-height: 21px; color: var(--Charcoal-800, #262626); 
                    }
                    .img { width: 24px; height: 24px; }
                }
            }
            
        }
    }
    .Demographics-cards::-webkit-scrollbar {
      display: none;
    }
}
.church-health-Demographics { padding-bottom: 20px; }


@media (max-width: 1200px) {
  .Demographics-container .Demographics-cards { width: 100%; }

}
@media (max-width: 700px) {
  .growthgoal-container { height: auto !important; }

}