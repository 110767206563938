@import '../../../Assests/Styles/Fonts.scss';

.create-deploy-assesment {display: flex;justify-content: center; width: 100%; align-items: center; background-color: hsl(0, 0%, 100%);border-radius: 12px; background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A3A3A3FF' stroke-width='2' stroke-dasharray='8%2c 14' stroke-dashoffset='0' stroke-linecap='rounded'/%3e%3c/svg%3e");box-shadow: 0px 1px 2px -1px #0000001A, 0px 1px 3px 0px #0000001A; max-width: 584px; height: 114px;
&__btn {
  font-family: $Poppins;padding: 12px 24px; border-radius: 7px; border: 1px solid #E5E5E5; background-color: #fff; color: #262626; cursor: pointer;font-size: 12px; font-weight: 700; line-height: 18px;transition: padding 200ms ease, background 0.3s ease,transform 80ms ease;
  &:hover {
    background: #E5E5E5;
    padding: 12px 34px; 
  }
  &:active {
    background: #dbdbdb;
    transform: translateY(5px);
  }
 }
}
