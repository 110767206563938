.ch-card { width: 386.67px; border-radius: 16px; padding: 32px; background: var(--White-White, #FFFFFF); height: 402px;
    .ch-card-description { width: 306.67px; height: 67px; 
        .ch-card-title-box { display: flex; gap: 15px; align-items: center;height:20px;
            .img { width: 31px; }
         .ch-card-title { color: var(--Charcoal-500, #171717); font-family: "F37 Jan"; font-weight: 700; font-size: 18px; line-height: 21.6px; }
        }
        .ch-card-subtitle { font-family: "Poppins"; font-weight: 400; font-size: 14px; line-height: 21px; color: var(--Charcoal-500, #525252); margin-top: 16px; width: 306.67px;height:31px ;
        }
    }
    .ch-card-bar-container {
        width: 322.67px; height: 195px; margin: 34px 0px; display: flex; gap: 20px;justify-content: center;
        .blur-card-container { width: 142px; height: 195px; 
            .blur-card-box { position: relative; height: 166px; width: 142px; background: #F5F5F5; border-top-left-radius: 8px; border-top-right-radius: 8px; overflow: hidden; filter: blur(5px);
                .percentage-bar {  position: absolute; bottom: 0; left: 0; right: 0; background-color: #D4D4D4; border-top-left-radius: 8px; border-top-right-radius: 8px; transition: height 300ms ease-in-out; filter: blur(5px);
                }
              }
         .blur-bar-percentage { font-family: "F37 Jan"; font-weight: 700; font-size: 22px; line-height: 26.4px; color: var(--Charcoal-400, #A3A3A3); margin-top: 10px; text-align: center;
         }
        }
        .national-avg-card { width: 142px; height: 195px;
            .national-avg-card-box { position: relative; height: 166px; width: 142px; background: #F5F5F5; border-top-left-radius: 8px; border-top-right-radius: 8px; overflow: hidden;
                .national-percentage-bar { position: absolute; bottom: 0; left: 0; right: 0; background-color: #00C980; border-top-left-radius: 8px; border-top-right-radius: 8px; transition: height 300ms ease-in-out;
                  }
              } 
            .bar-percentage { font-family: "F37 Jan"; font-weight: 700; font-size: 22px; line-height: 26.4px;  margin-top: 10px; text-align: center; color: var(--gloo-green-500-main, #00C980);
           }
        }
     }
     .lableaboutdata { display: flex; align-items: center; gap: 16px; justify-content: center; width: 100%;height:8px;
      .mydata { display: flex; align-items: center; gap: 6px;
        .circle { background-color: #C581FF; }
      }
      .nationalaverage { display: flex; align-items: center; gap: 6px;
        .circle { background-color: #00C980; }
      }
      .circle { width: 8px; height: 8px; border-radius: 8px; }
      .dataname { color: #171717; font-family: $Poppins; font-size: 12px; font-weight: 300; line-height: 18px; }
    }
}