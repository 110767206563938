.church-health {
    & { min-height: 100vh; width: 100%; background-color: #F5F5F5  ;}
    .heading { font-size: 12px }
    .filters-container { display: flex; justify-content: center; }
    &__banner { display: flex; justify-content: space-between; align-items: center; padding: 14px 8px; max-width: 1200px; margin: 0 auto; padding-top: 75px;
        @media (max-width: 768px) { flex-direction: column; padding:14px 8px; padding-top: 105px; }
    }
    &__Filters-Section { display: flex; gap: 1rem; width: 100vw; max-width: 1200px; justify-content: center; padding: 14px 8px; background-color: #F5F5F5; position: relative; z-index: 10; padding: 24px 0;
        @media (max-width: 768px) { flex-direction: column; padding:14px 8px }
    }

    .church-health__Filters-Section { display: flex; gap: 1rem; max-width: 1200px; margin: 0 auto; padding: 14px 8px; position: relative;
        @media (max-width: 768px) { flex-direction: column; padding:14px 8px }
    }

    &__people-flourishing { display: flex; gap: 1rem; max-width: 1200px; margin: 0 auto; padding: 14px 8px; margin-top: 151px;
        @media (max-width: 768px) { flex-direction: column; padding:14px 8px }
    }
 
    &__thriving { display: flex; gap: 1rem; max-width: 1200px; margin: 0 auto; padding: 14px 8px; 
     @media (max-width: 768px) { flex-direction: column; padding:14px 8px } }

     .church-health-deploy-sticky { position: fixed; z-index: 10; top: 88px;  }

}
