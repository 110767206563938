.container-assessmentstools { & { background-color: #ffffff; min-height: 100vh; padding: 40px 20px; }
  .toolsheader { & { margin-bottom: 30px; }
    .heading { font-size: 24px; font-weight: 700; line-height: 36px; font-family: $F37; color: #171717; }
    .subtext { font-size: 18px; font-weight: 300; line-height: 28px; color: #171717; font-family: "Poppins"; margin: 6px 0 20px; }
    .description { line-height: 28px; color: #171717; font-family: "Poppins"; font-weight: 300; font-size: 16px; }
  }
  .toolscontainer { & { display: flex; gap: 15px; flex-wrap: wrap; }
    .toolscard { & { box-shadow: 0px 12px 24px -6px #0000003D; border: 1px solid #EEEEEE; border-radius: 8px; padding: 18px; background-color: #FAFAFA; max-width: 518px; min-height: 312px; display: flex; flex-direction: column; justify-content: space-between; }
      .top { 
        .logo { width: 68px; margin-bottom: 20px; }
        .headertag { & { display: flex; gap: 10px; align-items: center; }
          .name { font-size: 18px; font-weight: 700; line-height: 28px; font-family: $F37; color: #000000; }
          .subtitle { font-size: 12px; font-weight: 700; font-family: "Poppins"; line-height: 18px; border-radius: 18px; border: 1px solid var(--Charcoal-300, #D4D4D4); color: #262626; padding: 2px 8px; }
        }
        .description { font-family: "Poppins"; font-weight: 300; font-size: 16px; line-height: 24px; color: #000; max-width: 60%; margin: 8px 0 10px; }
      }
      .bottom { & { display: flex; justify-content: space-between; align-items: center; }
        .time-question { color: #737373; font-weight: 300; font-size: 14px; line-height: 21px; }
        .respondents { color: #5F6368; font-weight: 700; font-size: 12px; line-height: 18px; }
      }
    }
  }
}