.dimensiondetail-container {
  & {  max-width: 1256px; margin:auto; background-color: #FFFFFF; }
  .heading {
    & { display: flex; align-items: center; justify-content: flex-start; gap: 12px; margin: 24px 0;padding: 0 24px; }
    h1 {
      & { font-size: 36px; font-weight: 400; line-height: 44px; color: #171717; text-transform: capitalize; font-family: $F37; }
    }
  }
  .paragraph-container {
    & { margin: 34px 24px; padding: 32px; background-color: #ffffff; box-shadow: 0px 0px 15px -3px #0000001A; border-radius: 16px; }
    .para-heading {
      & { display: flex; gap: 4px; font-size: 20px; font-weight: 400; line-height: 24px; color: #171717; margin-bottom: 16px; font-family: $F37; }
    }
    .paragraph {
      & { font-size: 16px; font-weight: 300; line-height: 24px; color: #171717; font-family: $Poppins; }
    }
  }
  .right-container-heading { display: none; }
  .question-buttons-container {
    & { display: flex; align-items: center; justify-content: flex-start; gap: 4px; padding: 24px 24px; overflow-x: scroll; }
    .question {
      & { cursor: pointer; background-color: #ffffff; border: 1px solid  #D4D4D4; outline: none; color: #262626; padding: 12px 24px; border-radius: 40px; font-size: 14px; font-weight: 700; line-height: 21px; text-transform: capitalize; text-align: center; white-space: nowrap; font-family: $Poppins; transition: padding 200ms ease, background 0.3s ease,transform 80ms ease;}
      &:hover {
        background: #E5E5E5;
        padding: 12px 34px; 
      }
      &:active {
        background: #dbdbdb;
        transform: translateY(5px);
      }
    }
    .active { background-color: #171717; color: #F2FBF8;transition: padding 200ms ease, background 0.3s ease,transform 80ms ease;
      &:hover {
        background: #424142;
        padding: 12px 34px; 
      }
      &:active {
        background: #dbdbdb;
        transform: translateY(5px);
      }
    }
  }
  .question-buttons-container::-webkit-scrollbar{
    display: none;
  }
  .chart-container {
    & { min-height: 600px; display: flex; gap: 25px; padding: 24px; }
    .left-container {
      & { width: 30%; padding: 44px 30px; border-radius: 16px; background-color: #F6FEF9; display: flex; flex-direction: column; justify-content: space-between; border: 0.25px solid #C581FF; }
      .img { 
        .container-imageBeta { display: flex; justify-content: space-between;  align-items: center;
          .beta-container { 
            width: 45px; height: 24px;  padding: 8px ; background: var(--Charcoal-200, #E5E5E5); font-family: $Poppins;
            font-weight: 700; font-size: 12px; line-height: 18px;   color: var(--charcoal-700-brand, #424142); display: flex; align-items: center; text-align: center; border-radius: 9999px;
          }
        }
      }
      .paragraph {
        & { font-size: 28px; line-height: 42px; font-weight: 500; color: #AF51FB; font-family: $Poppins; }
        .highlight {
          & { color: #851DD3; }
        }
      }
    }
    .right-container {
      & { width: 70%; padding: 32px; border-radius: 16px; background-color: #FFFFFF; display: flex; flex-direction: column; justify-content: space-between; box-shadow: 0px 0px 15px 0px #0000001A; }
      .right-container-heading {
        & { display: flex; flex-direction: column; gap: 12px; }
        .questions-number {
          & { font-size: 16px; font-weight: 700; text-transform: capitalize; line-height: 20px; color: #171717; font-family: $F37; }
        }  
        .question-quote {
          & { font-size: 20px; font-weight: 400; line-height: 24px; color: #171717; margin: 0px 0 14px 0; font-family: $Poppins; }
        }
      }
      .right-container-footer {
        & { display: flex; align-items: flex-start; justify-content: space-between; margin-top: 30px ; }
        p { font-size: 14px; font-weight: 300; text-transform: capitalize; color: #171717; font-family: $Poppins; }
      }
      .chartbar-container { & { display: flex; justify-content: space-between; width: 100%; gap: 10px; }
        .chart { & { width: 100%; margin: 2px 0px 0px 0px; max-width: 140px; }
          .chart-bar {  display: flex; gap: 6px; align-items: center; justify-content: center; transition: all 0.3s ease;
              .databar-one { display: flex; flex-direction: column; align-items: center; gap: 8px; cursor: pointer;
                  .databar-one-percentage { font-family: "F37 Jan"; font-weight: 700; font-size: 16px; line-height: 19.2px;
                  }
                  .dataheightone-container { background-color: #F5F5F5; height: 220px; width: 20px; position: relative; border-top-left-radius: 8px; border-top-right-radius: 8px; 
                      .dataheightone { width: 20px; position: absolute; bottom: 0; border-radius: 8px ; transition: height 0.5s linear; cursor: pointer; }
                      .tooltip-container { position: absolute; left: -85px;  width: 200px; height: 120px; background: var(--White-White, #FFFFFF);
                        z-index: 50;   padding: 12px; border-radius: 12px; box-shadow: 0px 0px 5px #b2b2b2; display: flex; flex-direction: column; gap: 17px;
                        p { width: 168px; height: 44px; font-family: "Poppins"; font-weight: 300; font-size: 12px; line-height: 18px; color: var(--Charcoal-800, #262626);
                        }
                        .btn-box { display: flex; justify-content: center; width: 100%;
                          .tool-tip-btn { display: flex; align-items: center; justify-content: center; width: 168px; height: 32px; border-radius: 8px; gap: 8px;  color: #FFF; font-weight: 700; border: none; background: var(--charcoal-900-black, #171717); cursor: pointer; font-size: 12px; transition: background 0.3s ease, width 200ms ease, transform 150ms ease; box-sizing: border-box; max-width: 180px;
                            &:hover { background: #424142; width: 210px; }
                            &:active { background: #262626; transform: translateY(5px); }
                            img { height: 11px; transition: transform 0.3s ease;
                              &:hover { transform: translateX(3px); }
                            }
                          }
                        }
                      }
                        .tooltip-container:after { content: ""; position: absolute; left: 85px; top: 120px; margin: 0 auto; width: 0; height: 0; border-top: 8px solid #fff; border-left: 8px solid transparent; border-right: 8px solid transparent; }
                  }
                  .comparison-inactive { width:43px ; 
                    .dataheightone {  width:43px ;  }
                }
              }
              .databar-two { display: flex; flex-direction: column; align-items: center; gap: 8px; cursor: pointer;
                  .databar-two-percentage { color: #00C980; font-family: "F37 Jan"; font-weight: 700; font-size: 16px; line-height: 19.2px; max-width: 20px; }
                  .dataheighttwo-container { background-color: #F5F5F5; height: 220px; width: 20px; position: relative; border-top-left-radius: 8px; border-top-right-radius: 8px;
                      .dataheighttwo { background-color: #00C980; width: 20px; position: absolute; bottom: 0; border-radius: 8px; transition: height 0.5s linear; cursor: pointer; }
                      }
                }

          }
          .chartlabel { margin-top: 8px; max-width: 140px; color: #171717; font-family: $Poppins; font-size: 14px; font-weight: 300; line-height: 21px; text-align: center; }
        }
      }
      .mobile-label { font-size: 14px; line-height: 21px; color: #171717; font-weight: 300; font-family: $Poppins; display: none; }
      .chart-mobile { & { margin: 2px 0px 0px 0px; display: none; flex-direction: column; justify-content: center; align-items: center; gap: 12px; }
        .chart-bar { display: flex; justify-content: center; align-items: center; flex-direction: row-reverse; gap: 20px; width: 100%;
          .data-value { display: flex; align-items: center; justify-content: center; gap: 0px; flex-direction: column; width: fit-content; width: 16px; height: 43px;
            .data-one { color: #C581FF; font-family: $F37; font-size: 16px; line-height: 21px; font-weight: 700; text-align: center; width: fit-content; }
            .data-two { color: #00C980; font-family: $F37; font-size: 16px; line-height: 21px; font-weight: 700; text-align: center; width: fit-content; }
          }
      }
        .databar { background-color: #E5E5E5; height: 43px; display: flex; align-items: flex-start; border-radius: 8px; flex-direction: column; width: 100%; flex-shrink: 1; }
        .dataheightone { border-radius: 8px 8px 8px 0px; background-color: #C581FF; width: 50%; }
        .dataheighttwo { border-radius: 0px 8px 8px 8px; background-color: #00C980; width: 50%; }
        .customtootip-container { position: relative;
          .customtootip { position: absolute; background-color: #FFFFFF; padding: 12px; width: 204px; top: -270px; right: -65px; border-radius: 8px; box-shadow: 0px 1px 2px -1px #0000001A, 0px 1px 3px 0px #0000001A;            
            p { margin: 0 0 12px 0; color: #262626; font-family: $Poppins; font-size: 12px; font-weight: 300; line-height: 18px; }
            button { color: #ffffff; font-family: $Poppins; font-size: 12px; font-weight: 700; line-height: 18px; background-color: #171717; border-radius: 8px; display: flex; justify-content: center; align-items: center; gap: 6px; padding: 5px 15px;
              img { width: 16px; height: 16px; }
            }
          }
        }
      }
      .lableaboutdata { display: flex; align-items: center; gap: 16px; justify-content: center; margin-top: 36px;
        .mydata { display: flex; align-items: center; gap: 6px;
          .circle { background-color: #C581FF; }
        }
        .nationalaverage { display: flex; align-items: center; gap: 6px;
          .circle { background-color: #00C980; }
        }
        .circle { width: 8px; height: 8px; border-radius: 8px; }
        .dataname { color: #171717; font-family: $Poppins; font-size: 12px; font-weight: 300; line-height: 18px; }
      }
    }
  }

  
@media only screen and (max-width: 880px) {
  .card-container {
    .card-title { font-size: 18px; text-align: center; }
    .card-description { font-size: 12px; justify-content: center; line-height: 14px; p { text-align: center; } }
    .card-wrapper { justify-content: center; .card-item { flex: 0 0 calc(100% - 24px); max-width: calc(100% - 24px); } }
  }
}

  
}

@media only screen and (max-width: 880px) {
  .spiritual-container { padding: 6px; width: 100%; }
  .spiritual-container .chart-container { display: block; max-height: 830px; }
  .spiritual-container .chart-container .left-container, .spiritual-container .chart-container .right-container { margin-bottom: 26px; width: 100%; }
  .spiritual-container .question-buttons-container { gap: 10px; }
  .spiritual-container .chart-container .right-container .chart .databar { height: 180px; }
  .spiritual-container .chart-container .left-container .paragraph { margin-top: 32px; }
  .spiritual-container .question-buttons-container .question { padding: 5px 14px; font-size: 10px; }
  .spiritual-container .paragraph-container .paragraph { font-size: 12px; }
}

@media only screen and (max-width: 560px) {
  .dimensiondetail-container .heading h1 {font-size: 28px;}
  .dimensiondetail-container .paragraph-container { margin: 24px; padding: 32px; }
  .dimensiondetail-container .chart-container .right-container { padding: 24px; }
  .dimensiondetail-container .chart-container .right-container .chart { display: none; }
  .dimensiondetail-container .chart-container { padding: 0; margin: 24px; flex-direction: column; }
  .dimensiondetail-container .chart-container .left-container { width: 100%; }
  .dimensiondetail-container .chart-container .right-container .chart-mobile { display: flex; }
  .dimensiondetail-container .chart-container .right-container .mobile-label { display: block; }

  .dimensiondetail-container .right-container-heading {
    & { display: flex; flex-direction: column; gap: 12px; margin: 24px; }
    .questions-number {
      & { font-size: 16px; font-weight: 700; text-transform: capitalize; line-height: 20px; color: #171717; font-family: $F37; }
    }  
    .question-quote {
      & { font-size: 20px; font-weight: 400; line-height: 24px; color: #171717; font-family: $Poppins; }
    }
  }
  .dimensiondetail-container .chart-container .right-container .right-container-heading { margin: 0px 0px 32px 0px; width: 95%; }
  .dimensiondetail-container .chart-container .right-container .right-container-footer { display: none; }
  .dimensiondetail-container .chart-container .right-container .chart-mobile .customtootip-container .customtootip { bottom: 45px; top:auto; right: auto; }
  .dimensiondetail-container .chart-container .right-container .lableaboutdata { margin-top: 32px; }
  // .dimensiondetail-container .recommended-section { display: none; }
  .dimensiondetail-container .question-buttons-container { padding: 0; margin: 24px; flex-wrap: wrap; }
}
