.demographics-main { padding: 0 24px;
  .demographics-title { display: flex; justify-content: space-between; align-items: center; padding: 24px 0; 
    .left {
      .heading { color: #000000; font-weight: 400; font-size: 32px; line-height: 44px; font-family: $F37; }
      .subheading { color: #737373; font-family: $Poppins; font-weight: 400; font-size: 18px; line-height: 28px; }
    } 
    .right {
      .cta-viewall { background-color: #FFFFFF; border: 1px solid var(--Charcoal-200, #E5E5E5); display: flex; align-items: center; gap: 8px; font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 21px; color: #262626; padding: 12px 24px; border-radius: 8px; cursor: pointer; }
    }
  }
  .cards {
    & { width: 100%; display: flex; align-items: center; justify-content:space-between; gap: 15px; }
    .card {
      & { height: 300px; max-height: 100%; width: 33%; background-color: #FAFAFA; border-radius: 18px; padding: 16px 24px 44px 24px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; margin-bottom: 15px; }
      .dimension-title { color: #171717; font-weight: 400; font-size: 21px; line-height: 25px; font-family: $F37; } 
      .icon { height: 40px; }
      .statement-box { & {width: 100%; height: 100%; justify-content: space-between;}
        .chart-container { display: flex; gap: 16px; margin-top: 16px; }
      }
      .statement {
        & { display: flex; flex-direction: column; gap: 24px; }
        .col-statement { 
          & { font-size: 25px; font-weight: 700; line-height: 40px; text-align: left; color: rgba(0, 0, 0, 0.44); width: 280px; font-family: $F37;}
        }
        .percentages { font-size: 56px; font-weight: 700; line-height: 36px; color: #1D846F; font-family: $F37; }
      }
    }
    .highlight-green { color: #2AAD8F; }
  }
  .demographics-content {
    background-color: #FAFAFA; border-radius: 17.24px; display: flex ; align-items: center; justify-content: center; padding: 32px ;
  .eyeIcon { & { width: 40px; height: 40px; padding: 9px; border-radius: 50px; background: #D2F5EA; display: flex; margin: auto; }
    .img { width: 100%; height: 100%; text-align : center; }   
  }
  .deploy-card-title { & { width: 390px; margin: 0 auto; text-align: center; }
    p {  margin: 10px 0px !important;  color: #737373; font-weight: 500; font-size: 28px; line-height: 40px; }
  }
  .deploy-button-container { & { display: flex; justify-content: center; padding: 10px ; }
    .deploy-button { & { background-color: #FFFFFF; border: 1px solid #E5E5E5; width: 200px; padding: 16px 15px; border-radius: 10px; display: flex ;  align-items: center; gap: 10px; cursor: pointer; }
      .deploy-button-btn-text { color: #262626; font-weight: 700; font-size: 14px; line-height: 21px; }
    }
  }
}

.pieTooltiptext { max-width: 250px; padding: 10px; box-shadow: 0px 0px 10px #b2b2b2; background: #fff; font-size: 6.78px; font-weight: 300; line-height: 21px; color: #171717; font-family: Poppins; line-height: 10.17px; border-radius: 4px;}
.pieTooltip-arrow:after { content: ""; position: absolute; left: 0; right: 0; margin: 0 auto; width: 0; height: 0; border-top: 5px solid #fff; border-left: 5px solid transparent; border-right: 5px solid transparent; }
} 