@import '../../Assests/Styles/Fonts.scss';

.demographics-container {
    & { background-color: #ffff; }
    .demographics-header { 
        & { display: flex; justify-content: left; align-items: center; padding: 24px; gap: 12px; }
        .pagename { font-family: $F37; font-weight: 400; font-size: 36px; color: #171717; line-height: 44px; }
    }
    .demographics-first-container {
        & { width: 100%; height: 100%; padding: 0px 32px 0px 32px; gap: 16px; }
        .first-inner-container { & { width: 100%; max-height: 330px; margin-bottom: 15px; }
            .cards { 
                & { width: 100%; background-color:  #FFFFFF; }
                .card { 
                    & { height: 250px; max-height: 100%; background-color: #FAFAFA; border-radius: 16px; padding: 32px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; margin-bottom: 10px; box-shadow: 0px 0px 6px 0px #0000000D; box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.2); gap: 12px;}
                    .dimension-title { height: 24px; display: flex; color: #1D846F; font-weight: 400px; font-size: 20px; line-height: 24px; font-family: $F37; text-align: left; gap: 4px;font-family: F37 Jan ; }
                    .icon { height: 40px; }
                    .statement-box { 
                        & { font-family: Poppins; font-size: 16px; font-weight: 300; line-height: 24px; text-align: left; color: #171717; height: 95px; width: 100%; height: 100%; justify-content: space-between; }
                        .chart-container { 
                            & { display: flex; gap: 16px; align-items: center; justify-content: center; margin-top: 20px; }
                            .legend{
                                & { width: 100px; height: 160px; display: flex; justify-content: center; flex-direction: column; gap: 4px; }
                                .legend-item{
                                    & { width: 100px; gap: 8px; font-family: $Poppins; font-size: 10px; font-weight: 300; line-height: 15px; text-align: left;  }
                                } 
                            }
                        }
                    }
                    .statement { 
                        & { display: flex; flex-direction: column; gap: 24px; }
                        .col-statement { 
                            & { font-size: 25px; font-weight: 700; line-height: 40px; text-align: left; color: rgba(0, 0, 0, 0.44); width: 280px; }
                        }
                        .percentages { font-size: 56px; font-weight: 700; line-height: 36px; color: #1D846F; }
                    }
                }
                .highlight-green { color: #2AAD8F; }
            }
        }

        .third-inner-container {
            & { width: 100%; height: 100%; }
            .cards {
                & { width: 100%; align-items: center; justify-content: space-between; gap: 16px }
                .card {
                    & { width: 100%; height: 552px; padding: 24px 0px 24px 0px; gap: 24px; }
                    .tab-container { 
                        & {width: 100%; height: 46px; padding: 0px 0px 12px 0px; margin-bottom: 20px; gap: 8px; }
                        .tabs{
                            & { display: flex; height: 46px; padding: 0px 0px 12px 0px; gap: 8px; }
                            .tab-button{background-color: #FFFFFF; width: 120px; display: flex; white-space: nowrap; color: #262626; font-family: Poppins; font-size: 14px; font-weight: 700; line-height: 21px; text-align: center; height: 34px; padding: 12px 24px 12px 24px; gap: 4px; border-radius: 18px ; border: 1px ; justify-content: center; align-items: center; border: 1px solid  #D4D4D4 }
                            .tab-button.active { background-color: #1D846F; color: #F2FBF8; cursor: pointer; }
                        }
                    }
                    .question-container{ 
                        & { width: 100%; height: 420px; gap: 24px; max-height: 560px; max-height: 100%; background-color: #FAFAFA; border-radius: 16px; padding: 24px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; margin-bottom: 10px; box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.2); gap: 24px; min-width: 1278px; }
                        .dimension-title { 
                            & { height: 47px; gap: 12px; color: #171717; font-weight: 400px; font-size: 21px; line-height: 25px; font-family: $F37; text-align: left; margin-bottom: 20px; }
                            p { margin-bottom: 12px;height: 11px; font-family: F37 Jan; font-size: 16px; font-weight: 700; line-height: 19.2px; text-align: left; color: #1D846F; }
                            span { height: 24px; font-family: Poppins; font-size: 20px; font-weight: 400; line-height: 24px; text-align: left; color: #171717; }
                        }
                        .chart-container{
                            & { width: 100%; height: 279px; gap: 12px; justify-content: space-between; display: flex; flex-direction: column; align-items: center; }
                            .labels-container { justify-content: space-around; width: 100%; margin-top: 10px; }
                            .label { text-align: center; width: 80px; font-size: 12px; color: #333; overflow-wrap: break-word; }
                        }   
                        .right-container-footer {
                          & { display: flex; align-items: flex-start; justify-content: space-between; margin-top: -50px; height: 42px; width: 100%; }
                          p { font-size: 14px; font-weight: 300; text-transform: capitalize; color: #171717; font-family: $Poppins; }
                        }
                    }
                    .icon { height: 40px; }
                    .statement-box {
                        & { width: 100%; height: 100%; justify-content: space-between; }
                        .chart-container { display: flex; gap: 16px; }
                    }
                    .statement {
                        & { display: flex; flex-direction: column; gap: 24px; }
                        .col-statement {
                            & { font-size: 25px; font-weight: 700; line-height: 40px; text-align: left; color: rgba(0, 0, 0, 0.44); width: 280px; }
                        }
                        .percentages { font-size: 56px; font-weight: 700; line-height: 36px; color: #1D846F; }
                    }
                }
                .highlight-green { color: #2AAD8F; }
            }
        }
    }
}