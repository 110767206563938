.ThrivingDimensions-main { padding: 0 24px;
  .ThrivingDimensions-header { padding: 24px 0;
    .ThrivingDimensions-title { color: #000000; font-weight: 400; font-size: 32px; line-height: 44px; font-family: $F37;}
    .ThrivingDimensions-subtitle { color: #737373; font-weight: 400; font-size: 18px; line-height: 28px; font-family: $Poppins; max-width: 46ch; height: 48px; margin-top: 4px;
    }
  }
  .ThrivingDimensions-boxes { display: flex; gap: 20px;
    .box-1 { width: 100%; height: 300px; background-color: #FFFFFF; border-radius: 20px; padding: 22px 24px; box-shadow: 0px 0px 6px 0px #0000000D, 0px 0px 15px -3px #0000001A; display: flex; flex-direction: column; }
    .box1-title { color: #171717; font-weight: 400; font-size: 24px; line-height: 28.8px; font-family: $F37;}
    .box1-subtitle {
      & { color: #737373; font-weight: 400; font-size: 14px; line-height: 20px; margin-top: 20px; display: flex; flex-direction: column; gap: 16px; }
      .chart-item {
        & { display: flex; align-items: center; justify-content: space-between; }
        .item-heading {
          & { display: flex; align-items: center; gap: 16px; }
          .img-container { & { width: 32.59px; height: 32.59px; display: flex; justify-content: center; align-items: center; background-color: #F2FBF8; border-radius: 7px; }
            img { height: 11px; width: auto; }
          }
          h3 { font-size: 14px; font-weight: 400; text-transform: capitalize; line-height: 21px; color: #171717; font-family: $Poppins;}
        }
        .item-score {
          & { display: flex; align-items: center; gap: 24px; }
          p { font-size: 14px; font-weight: 400; text-transform: capitalize; line-height: 18px; color: #737373; font-family: $Poppins; }
        }
      }
    }
    .box1-button-container { margin-top: auto; display: flex; justify-content: flex-start; }
    .box1-button { background-color: #FFFFFF; border: 1px solid #E5E5E5; width: 200px; padding: 16px 15px; border-radius: 10px; display: flex; align-items: center; gap: 10px; }
    .box1-button-btn-text { color: #262626; font-weight: 700; font-size: 14px; line-height: 21px; }
    .box-2 { width: 100%; height: 300px; background-color: #FFFFFF; border-radius: 20px; padding: 22px 24px; box-shadow: 0px 0px 6px 0px #0000000D,0px 0px 15px -3px #0000001A; display: flex; flex-direction: column; }
    .box2-title { color: #171717; font-weight: 400; font-size: 24px; line-height: 28.8px; font-family: $F37; }
    .box2-subtitle {
      & { color: #737373; font-weight: 400; font-size: 14px; line-height: 20px; margin-top: 20px; display: flex; flex-direction: column; gap: 16px; }
      .chart-item {
        & { display: flex; align-items: center; justify-content: space-between; }
        .item-heading {
          & { display: flex; align-items: center; gap: 16px; }
          .img-container { & { width: 32.59px; height: 32.59px; display: flex; justify-content: center; align-items: center; background-color: #F2FBF8; border-radius: 7px; }
            img { height: 11px; width: auto; }
          }
          h3 { font-size: 14px; font-weight: 400; text-transform: capitalize; line-height: 21px; color: #171717; font-family: $Poppins;}
        }
        .item-score {
          & { display: flex; align-items: center; gap: 24px; }
          p { font-size: 14px; font-weight: 400; text-transform: capitalize; line-height: 18px; color: #737373; font-family: $Poppins;}
        }
      }
    }
    .box2-button-container { margin-top: auto; display: flex; justify-content: flex-start; }
    .box2-button { background-color: #FFFFFF; border: 1px solid #E5E5E5; width: 200px; padding: 16px 15px; border-radius: 10px; display: flex; align-items: center; gap: 10px; }
    .box2-button-btn-text { color: #262626; font-weight: 700; font-size: 14px; line-height: 21px; }
  } 
  .ThrivingDimensions-grid { display: grid; grid-template-columns: repeat(3, 1fr); gap: 16px; margin: 16px 0;
    .dimension-card { background-color: #FFFFFF;border-radius: 12px;padding: 20px;box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 15px -3px rgba(0, 0, 0, 0.1);
      .dimension-title { display: flex; align-items: center; margin-bottom: 10px; gap :10px; font-family: $Poppins;
        p { color: #171717; font-weight: 500; font-size: 20px; line-height: 30px; }
      }
      .dimension-description { font-size: 14px; color: #737373; font-weight: 400; line-height: 18px; margin-bottom: 40px; font-family: $Poppins; height: 56px; }
      .dimension-items { display: grid; grid-template-columns: repeat(2, 1fr); gap: 10px;
        .item { background-color: #F3F4F6; border-radius: 6px; padding:  12px; font-size: 12px; color: #4B5563; height: 90px; display: flex;  flex-direction: column; justify-content: space-between;
        }
        .item > div { display: flex; flex-direction: column; height: 100%; }
        .bottom-items { display: flex; justify-content: space-between; align-items: flex-end; margin-top: auto;
          .bottom-items-name { width: 40px; color: #D4D4D4; font-weight: 400; font-size: 12px; line-height: 16px; font-family: $F37; }
        }
      }
    }
  }
}