.library-container {
  & { width: 100%; background-color: #FFFFFF; max-width: 1256px; height: fit-content; margin: auto; }
  .library-header {
    & { display: flex; align-items: center; justify-content: flex-start; padding: 32px 24px; gap: 32px; cursor: pointer; }
    .nav-heading-content {
      & { display: flex; align-items: center; justify-content: center; gap: 8px; }
      .library {
        & { font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 700; line-height: 21px; color: #737373; }
      }
      .church-name {
        & { font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 600; line-height: 21px; color: #171717; }
      }
    }
  }
  .church-poster-container {
    & { height: 325px; background-image:url('../../Assests/Images/Circle.png'), linear-gradient(180deg, #0A1B27 -1.46%, #223D51 191.51%); border-radius: 24px; padding: 32px; display: flex; flex-direction: column; justify-content: space-between; background-size: contain; background-repeat: no-repeat; background-position: bottom; margin-top:50px }
    .poster-heading {
      & {  display: flex; justify-content: space-between; color: #FFFFFF; font-family: 'Poppins', sans-serif; }
      .left-side {
        & { font-size: 34px; text-transform: uppercase; }

        .top-container { display: flex ; align-items: center; gap: 5px; ;
          .top-border { width: 205px; height: 2.60px ; background-color: #FFFFFF; } 
        }
        .corner-heading {
          & { font-family: fantasy; padding: 7px 0;  }
        }
        .bottom-container { display: flex ; align-items: center; gap: 5px; ;
          .bottom-border { width: 205px; height: 2.55px ; background-color: #FFFFFF; } 
        }
      }
      .right-side {
        & { color: #FFFFFF; font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 700; line-height: 21px; }
        .respondent-container {
          & { padding: 12px 24px; display: flex; align-items: center; justify-content: center; gap: 4px; background-color: rgba(255, 255, 255, 0.1); border-radius: 40px; width: 252px; height: 34px;  }
        }
      }
    }
    .poster-bottom {
      & { display: flex; gap: 24px; }
      .bottom-img-container {
        & { height: 80.28px; width: 82.42px;display: flex;background: #D03138;border-radius: 8.56px; }
      }
      .bottom-church-data {
        & { color: #FFFFFF; display: flex; flex-direction: column; gap: 16px; }
        .church-heading {
          & { font-family: $F37; font-size: 24px; font-weight: 400; line-height: 28px; }
        }
        .church-assessment-info {
          & { display: flex; gap: 12px; }
          .rounded-container {
            & { padding: 8px 12px; background-color: rgba(255, 255, 255, 0.1); border-radius: 18px; }
            .duration-column {
              & { font-family: 'Poppins', sans-serif; font-size: 12px; font-weight: 700; line-height: 18px; color: #FFFFFF; }
            }
            .questions-column {
              & { font-family: 'Poppins', sans-serif; font-size: 12px; font-weight: 700; line-height: 18px; color: #FFFFFF; }
            }
          }
        }
      }
    }
  }
  .description-container {
    & { padding: 32px; }
    .section-headings-container {
      & { border-bottom: 0.5px solid #E5E5E5; display: flex; align-items: center; justify-content: flex-start; gap: 24px; margin-bottom: 24px; cursor: pointer; }
      .details-heading {
        & { display: flex; gap: 4px; }
        .details {
          & { font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 700; line-height: 21px; }
        }
      }
      .assessment-questions-heading {
        & { display: flex; padding: 16px 0; gap: 4px;  }
        .assessment-questions {
          & { font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 700; line-height: 21px; }
        }
      }
      .active {
        & { display: flex; padding: 16px 0; gap: 4px; border-bottom: 4px solid #262626; }
      }
    }
    .about-container {
      & { display: flex; justify-content: space-between; gap: 60px; }
      .about-section-container {
        & { width: 65%; }
        .about-assessment-para {
          & { display: flex; flex-direction: column; gap: 16px; }
          .about-assessment-heading {
            .about-heading { font-size: 28px; font-weight: 400; line-height: 33.6px; color: #171717; font-family: "F37 Jan"; }
          }
          .about-assessment-info {
            .description { & { font-weight: 300; font-size: 14px; line-height: 22.4px; color: #171717; font-family: "Poppins"; }
              .privacypolicysubheading { font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 22.4px; }
            }
          }
          .subheading { color: #171717; font-family: $F37; font-size: 21px; font-weight: 400; line-height: 25.2px; margin-top: 6px; }
        }
        .what-people-saying-para { & { display: flex; align-items: center; justify-content: space-between; margin: 24px 0; }
          .people-heading { font-size: 28px; font-weight: 400; line-height: 33.6px; color: #171717; font-family: "F37 Jan"; }
          .users-counts { display: flex; align-items: center; gap: 6px; margin-right: 8px;
            .count { display: flex; align-items: center; gap: 3px; font-size: 12px; font-weight: 700; line-height: 18px; color: #171717; border: 1px solid var(--Charcoal-300, #D4D4D4); padding: 0 10px; border-radius: 16px; font-family: "Poppins"; }
          }
        }
        .quotes-boxes { & { display: flex; gap: 6px; }
          .box-container { & { width: 49%; padding: 32px; box-shadow: 0px 4px 6px 0px #0000000D; border: 1px solid #E5E5E5; border-radius: 18px; display: flex; justify-content: space-between; flex-direction: column; } 
            .quote { font-size: 18px; font-weight: 400; color: #171717; line-height: 30px; font-family: "Poppins"; margin-bottom: 10px; }
            .position { font-size: 12px; font-weight: 300; font-family: "Poppins"; color: #A3A4A3; line-height: 18px; }
            .author { font-size: 16px; font-weight: 400; line-height: 19.2px; font-family: "F37 Jan"; color: #171717; }
          }
        }
        .about-barna-container { margin: 24px 0; background-color: #FAFAFA; border-radius: 18px; padding: 24px;
          .barna-heading { display: flex; gap: 6px; align-items: center; margin-bottom: 10px;
            img { width: 38px; border-radius: 25px; }
            span { font-size: 21px; font-family: "F37 Jan"; font-weight: 400; line-height: 25.2px; color: #171717; }
          }
          .barna-info { font-size: 14px; font-family: "Poppins"; font-weight: 300; line-height: 21px; color: #171717; }
        }
      }
      .key-feature-section-container { & { border: 1px solid #E5E5E5; box-shadow: 0px 4px 6px 0px #0000000D; box-shadow: 0px 10px 15px -3px #0000001A; padding: 20px; border-radius: 10px; max-height: 300px; width: 45%; max-width: 334px; min-width: 334px; }
        .feature-heading { font-size: 21px; font-weight: 400; font-family: "F37 Jan"; color: #171717; line-height: 25.2px; margin: 20px 0; }
        .key-feature-bullet-points { margin: 28px 0;
          ul { padding-left: 24px; }
          li { font-size: 14px; line-height: 20px; font-family: "Poppins"; line-height: 20px; color: #171717; font-weight: 300; 
            p { margin : 0px 0px 15px 0px }
          }
        }
        .configure-btn { background: var(--charcoal-900-black, #171717); color: #FFFFFF; font-size: 12px; font-weight: 700; line-height: 18px; display: flex; align-items: center; padding: 11px 46px; margin: 0 auto; border-radius: 6px; gap: 7px; font-family: 'Poppins'; cursor: pointer; border: none; }
      }
      .assessment-questions-section {
        & { display: flex; flex-direction: column; gap: 24px; }
        .seven-dimension-heading {
          & { padding-bottom: 24px; border-bottom: 1px solid #E5E5E5; font-family: $F37; font-size: 28px; font-weight: 400; line-height: 33.6px; color: #171717; }
        }
        .seven-dim-que-container {
          & { display: flex; flex-direction: column; gap: 24px; margin-bottom: 66px; }
          .assessment-question {
            &  { display: flex; flex-direction: column; border: 1px solid #E5E5E5; border-radius: 16px; box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05); }
            .dimension-heading {
              & { display: flex; align-items: center; gap: 6px; padding: 16px 24px; border-bottom: 1px solid #E5E5E5; }
              .img-background {
                & { height: 36px; width: 36px; background-color: #F2FBF8; position: relative; border-radius: 7.2px; }
                img { display: flex; align-items: center; justify-content: center; position: absolute; top: 30%; left: 30%; height: 35%; }
              }
              .heading-text { color: #171717; font-size: 21px; font-family: $F37; font-weight: 400; line-height: 25.2px; }
            }
            .descriptive-lines {
              & { padding: 0 24px; }
              p {
                & { padding: 25.5px 0; font-family: 'Poppins', sans-serif; font-size: 16px; font-weight: 300; color: #171717; }
              }
              .border-bottom {
                & { border-bottom: 1px solid #E5E5E5; }
              }
            }
          }
          .fifteen-dim-category-heading {
            & { display: flex; gap: 10px; }
            .dimension-section-title { font-family: $F37; font-size: 21px; font-weight: 400; line-height: 25.2px; text-align: left; }
          }
          .dimension-section {
            & { display: flex; flex-direction: column; gap: 24px; margin-bottom: 40px; }
          }
        }
      }
    }
    .insights-section {
      & { display: flex; flex-direction: column; gap: 24px; }
      .head-insights {
        & { padding-bottom: 24px; border-bottom: 1px solid #E5E5E5; font-family: $F37; font-size: 28px; font-weight: 400; line-height: 33.6px; color: #171717; margin-top: 34px; }
      }
      .data-insights {
        & { display: flex; flex-direction: column; border: 1px solid #E5E5E5; border-radius: 16px;  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05); padding: 0 24px; }
        .insight-item {
          & { padding: 26px 0; border-bottom: 1px solid #E5E5E5; font-family: 'Poppins', sans-serif; font-size: 16px; font-weight: 300; line-height: 24px; color: #171717; }
        }
      }
    }
  }

  .section-headings-container .svg-icon {
    filter: brightness(1); 
  }
  
  .section-headings-container .svg-icon.active-img {
    filter: brightness(0.2);
  }
}