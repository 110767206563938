.dimensions-outer-wrapper { display: flex; flex-direction: column; align-items: center; width: 100%; }
    .scroll-indicators { display: flex; gap: 3px; padding: 0; justify-content: center; }
    .scroll-dot { width: 8px; height: 8px; border-radius: 50%; background-color: #D4D4D4; transition: all 0.3s ease;}
    .scroll-dot.active { width: 24px; border-radius: 4px; background-color: #171717;}
    .seven-dimensions-container {  padding: 16px 24px; display: flex; gap: 16px; overflow-x: auto; -ms-overflow-style: none; scrollbar-width: none; -webkit-overflow-scrolling: touch; scroll-behavior: smooth; width: 100%; position: relative; scroll-snap-type: x mandatory;
        .seven-dimensions-box { width: 288px; max-height: 410px; background: var(--White-White, #FFFFFF);  border-radius: 16px; padding: 32px; display: flex; flex-direction: column; min-width: 288px; scroll-snap-align: center;
            .dimensions-description { width: 224px; display: flex; flex-direction: column; gap: 13px; margin-bottom: 14px;
                .dimensions-description-title { display: flex;  height: 25px; align-items: center; gap: 13px;
                    // img { width: 39px; height: 25px; } 
                    p{ font-family: "F37 Jan" ; font-weight: 700; font-size: 18px; line-height: 21.6px; color: var(--charcoal-900-black, #171717);}
                }
                .dimensions-description-subtitle { width: 224px; height: 52px; font-family: "Poppins"; font-weight: 400; font-size: 14px; line-height: 21px; color: var(--Charcoal-500, #737373); color: #525252; }
            }
            .dimensions-description-subtitle { height: 80px; font-family: $Poppins; font-size: 14px; font-weight: 400; line-height: 21px; letter-spacing: -0.02em; color: #525252; }
            .ch-card-bar-container {
                width: 224px; height: 195px;  display: flex; gap: 20px; position: relative; margin-top: 20px;
                .blur-card-container { width: 100px; height: 195px; 
                    .blur-card-box { position: relative; height: 170px; width: 100px; background: #F5F5F5; border-top-left-radius: 8px; border-top-right-radius: 8px; overflow: hidden;
                        .percentage-bar {  position: absolute; bottom: 0; left: 0; right: 0; background-color: #D4D4D4; border-top-left-radius: 8px; border-top-right-radius: 8px; transition: height 300ms ease-in-out;
                        }
                    }
                .blur-bar-percentage { font-family: "F37 Jan"; font-weight: 700; font-size: 22px; line-height: 26.4px; color: var(--Charcoal-400, #A3A3A3); margin-top: 10px; text-align: center;
                }
                }
                .tooltip-container { position: absolute; top : -11% ; width: 192px; height: 120px; background: var(--White-White, #FFFFFF);
                z-index: 50; left: -40px;  padding: 12px; border-radius: 12px; box-shadow: 0px 0px 5px #b2b2b2; display: flex; flex-direction: column; gap: 19px;
                p { width: 168px; height: 44px; font-family: "Poppins"; font-weight: 300; font-size: 12px; line-height: 18px; color: var(--Charcoal-800, #262626);
                }
                button { display: flex; align-items: center; justify-content: center; width: 168px; height: 32px; border-radius: 8px; gap: 8px;  color: #FFF; font-weight: 700; border: none; background: var(--charcoal-900-black, #171717); cursor: pointer; font-size: 12px;
                }
                }
                .tooltip-container:after { content: ""; position: absolute; left: 85px; top: 120px; margin: 0 auto; width: 0; height: 0; border-top: 8px solid #fff; border-left: 8px solid transparent; border-right: 8px solid transparent; }
                .national-avg-card { width: 100px; height: 195px;
                    .national-avg-card-box { position: relative; height: 170px; width: 100px; background: #F5F5F5; border-top-left-radius: 8px; border-top-right-radius: 8px; overflow: hidden;
                        .national-percentage-bar { position: absolute; bottom: 0; left: 0; right: 0; background-color: #00C980; border-top-left-radius: 8px; border-top-right-radius: 8px; transition: height 300ms ease-in-out;
                        }
                        .percentage-bar {  position: absolute; bottom: 0; left: 0; right: 0; background-color: #D4D4D4; border-top-left-radius: 8px; border-top-right-radius: 8px; transition: height 300ms ease-in-out;
                        }
                    } 
                    .bar-percentage { font-family: "F37 Jan"; font-weight: 700; font-size: 22px; line-height: 26.4px;  margin-top: 10px; text-align: center; color: var(--GlooGreen-800, #006747);
                }
                }
            }
            .lableaboutdata { display: flex; align-items: center; gap: 16px; justify-content: center; margin-top: 20px;
                .mydata { display: flex; align-items: center; gap: 6px; white-space: nowrap;
                  .circle { background-color: #C581FF; }
                }
                .nationalaverage { display: flex; align-items: center; gap: 6px;
                  .circle { background-color: #00C980; }
                }
                .circle { width: 8px; height: 8px; border-radius: 8px; }
                .dataname { color: #171717; font-family: $Poppins; font-size: 12px; font-weight: 300; line-height: 18px; white-space: nowrap; }
            }
            .ch-card3-detail-container { height: 302px; width: auto; display: flex; align-items: end;
							.ch-card3-description { font-family: $F37; font-weight: 400 ; font-size: 18px; line-height: 22px; color: #171717; margin-bottom: 22px;
							}
							.ch-card3-cta { background: var(--White-White, #FFFFFF); width: 222px; height: 48px; border-radius: 8px; padding: 12px 24px; border: 1px solid var(--Charcoal-200, #E5E5E5) ; display: flex; justify-content: space-between; align-items: center; cursor: pointer;
									.ch-card3-cta-title { font-family: "Poppins"; font-weight: 700; font-size: 14px; line-height: 21px; color: var(--Charcoal-800, #262626); 
									}
									.img { width: 24px; height: 24px; }
							}
						}
        }
        .activecard { border: 1px solid var(--charcoal-700-brand, #424142) ; } 
    }
    .seven-dimensions-container::-webkit-scrollbar { display: none;}
    .scroll-padding { min-width: calc(50vw - 144px); }