.header { display: flex; justify-content: space-between; width: 100%; padding: 32px 24px; background-color: #ffffff; z-index: 9;
    .header-section1 { width: 50%;
        .header-text1 { 
          & { color: #000000; font-size: 36px; line-height: 44px; font-weight: 400; }
          p { font-family: $F37; }
        }
    }
    .header-section2 { display: flex; gap: 20px; align-items: center;
       .images-container { display: flex; gap:  6.02px;
            .Image-1 { background-color: #D03138;  padding: 6.02px; border-radius: 4.81px; width: 36.09px; height: 36.09px;
                img { width: 100%; height: 100%; }
            }
            .Image-2 { background-color: #ffffff;  padding: 6.02px; border-radius: 4.81px; width: 36.09px; height: 36.09px; border: solid 0.6px #E5E5E5;
                img { width: 100%; height: 100%; }
            }
    }
    .header-text2 {
            p { font-family:$Poppins; font-weight: 400; font-size: 12px; line-height: 18px; color: #737373; width: 260px; }
    }
}
}
@media only screen and (max-width: 830px) {

    .header { flex-direction: column; position: relative;}
    .header .header-section1 .header-text1 p {
        width: 430px;
        padding-bottom: 20px;
    }
}