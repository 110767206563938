.card-container { width: 100%; height: 114px; border: 1px solid var(--Charcoal-200, #e5e5e5); box-shadow: 0px 1px 2px -1px #0000001a, 0px 1px 3px 0px #0000001a; background: var(--White-White, #ffffff); border-radius: 16px; padding: 16px; display: flex; gap: 24px; flex-direction: column; position: relative; max-width: 584px;
 .card-header { display: flex; align-items: center; justify-content: space-between; width: 100%;
 }
 .card-actions { display: flex; gap: 8px; align-items: center;
   .dropdown-timeline { position: relative;
     .filter-dropdown { width: auto; height: 34px; background: var(--charcoal-900-black, #171717); display: flex; gap: 6px; align-items: center; border-radius: 20px; padding: 12px 16px; cursor: pointer; transition: padding 200ms ease, background 0.3s ease,transform 80ms ease; border: none;
      &:hover {
        background: #424142; // Change background on hover
        padding: 12px 26px; // Increase padding to add width on both sides
      }
      &.active {
        background: #444444; // Darker background on mousedown
        transform: translateY(5px); // Move element down
      }
       .drop-heading { font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 21px; text-align: center; text-underline-position: from-font; text-decoration-skip-ink: none; color: #ffffff; }
     }
     .dropdown_menu { width: 250px; position: absolute; height: 176px; display: flex; flex-direction: column; align-items: center; padding: 1rem; margin-top: 0.5rem; background: #ffffff; border-radius: 10px; box-shadow: 0px 4px 6px -1px #0000001a; opacity: 0;
       li { height: 42px; list-style-type: none; padding: 8px; font-family: $F37; font-size: 14px; font-weight: 700; line-height: 16.8px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: #262626; cursor: pointer; width: 100%; display: flex; justify-content: space-between; align-items: center; 
        &:hover { transition: padding 0.3s ease; padding-left: 20px; background-color: #D4D4D4; }
      }
     }
     .content-open { opacity: 1; }
   }
   .vertical-line { height: 34px; width: 1px; background-color: #e0e0e0; }
  }
   .additional-dropdown{
     .filter-selection{display: flex; gap: 8px; 
      .filter-tag { display: flex; min-width: 118px; max-width: 175px; height: 34px; font-family: $Poppins; font-size: 14px; font-weight: 700; padding: 12px 16px 12px 16px; gap: 4px; border-radius: 20px; border: 1px solid #D4D4D4; align-items: center; justify-content: center;}
      .filter-value { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
      .tune-container { width: 34px; height: 34px; border-radius: 100%; border: 1px solid var(--Charcoal-300, #d4d4d4); display: flex; justify-content: center; align-items: center; cursor: pointer;transition: width 200ms ease, background 0.3s ease,transform 80ms ease;
        &:hover{width: 44px;background: #E5E5E5;}
        &:active{transform:translateY(5px);background: #E5E5E5;}
       }    
     }
     .additional-content { position: absolute; width: 592px; display: flex; flex-direction: column; align-items: center; margin-top: 0.5rem; background-color: white; border-radius: 0.5rem; box-shadow: 0px 10px 15px -3px #0000001a; opacity: 0;z-index:9; right: 0;
         .dataset-heading { display: flex; justify-content: space-between; align-items: center; height: 72px; width: 100%; padding: 24px;
           span { font-family: $F37; font-size: 22px; font-weight: 700; line-height: 26.4px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: var(--charcoal-900-black, #171717); }
         }
         .dataset-options { display: flex; gap: 24px; width: 100%; justify-content: space-between; align-items: center; border-top: 1px solid #e5e5e5; border-bottom: 1px solid #e5e5e5; padding: 24px; flex-direction: column;
             .circles-heading { display: flex; justify-content: space-between;width:100%;
                 .my-circles { display: flex; gap: 8px;
                   p { font-family: $F37; font-size: 18px; font-weight: 700; line-height: 21.6px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: #171717; }
                 }
               }  
           .options-container { display: flex; justify-content: space-between; width: 100%;
             button { height: 34px; gap: 4px; border-radius: 20px; border: 1px solid var(--Charcoal-300, #D4D4D4); font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 21px; text-align: center; text-underline-position: from-font; text-decoration-skip-ink: none; background: #FFFFFF; cursor: pointer;padding: 12px 16px 12px 16px;display: flex;justify-content: center;align-items: center;transition: padding 200ms ease, background 0.3s ease,transform 80ms ease;
              &:hover{padding: 12px 34px;background: #E5E5E5;}
              &:active{background: #E5E5E5;
                transform: translateY(5px);}
             }
               .selected { color: #FFFFFF; background: #171717;
                &:hover{background: #424142;}
                &:active{background: #424142;}
               }
             .selected-option { border: 2px solid var(--charcoal-900-black, #171717); }
           }
           .circles-container { display: flex; flex-direction: column; width: 100%; gap: 24px; border-top: 1px solid #e5e5e5; padding-top: 20px;
             .circles-heading { display: flex; justify-content: space-between;
               .my-circles { display: flex; gap: 8px;
                 p { font-family: $F37; font-size: 18px; font-weight: 700; line-height: 21.6px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: #171717; }
               }
             }
             .circles-btns { display: flex; gap: 12px;
               button { width: auto; height: 34px; gap: 4px; border-radius: 20px; border: 1px solid var(--Charcoal-300, #D4D4D4); font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 21px; text-align: center; text-underline-position: from-font; text-decoration-skip-ink: none; background: #FFFFFF; cursor: pointer;padding: 0px 16px;transition: padding 200ms ease, background 0.3s ease,transform 80ms ease;
                &:hover{padding: 0px 20px;background: #E5E5E5;}
                &:active{background: #E5E5E5;
                  transform: translateY(5px);}
               }
               .selected { color: #FFFFFF; background: #171717;
                &:hover{background: #424142;}
                &:active{background: #424142;}
               }
             }
           }
           .close-circle { display: none; }
         }
         .dataset-cta { display: flex; align-items: center; justify-content: space-between; width: 100%; height: 96px; padding: 24px;
           .clear-cta{width:108px;height:48px;cursor: pointer;border: none;background: #FFFFFF;font-family: $Poppins;font-size: 14px;font-weight: 700;color: #171717;}
           .btn-div{&{display: flex;gap:12px}
           .cancel-cta { padding: 12px 24px; height: 48px; gap: var(--PaddingGapsMargins8px); border-radius: 10px; background: var(--White-White, #ffffff); border: 1px solid var(--Charcoal-200, #e5e5e5); font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 21px; text-underline-position: from-font; text-decoration-skip-ink: none; color: var(--Charcoal-800, #262626); cursor: pointer;transition: padding 200ms ease, background 0.3s ease,transform 80ms ease;
            &:hover{padding: 12px 34px;background: #E5E5E5;}
            &:active{transform: translateY(5px);background: #E5E5E5;}
           }
           .apply-cta { height: 48px; padding: 12px 24px; gap: var(--PaddingGapsMargins8px); border-radius: 10px; background: var(--charcoal-900-black, #171717); color: var(--White-White, #ffffff); font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 21px; text-underline-position: from-font; text-decoration-skip-ink: none; cursor: pointer; border: none; width: 91px; transition: width 0.3s ease ; position: relative; }
          .apply-cta:hover { background-color: #424142; width: 127px; }
          .apply-cta:active { transform: translateY(5px); }
         }    
           
         }
       }
       .dataset-open { opacity: 1; z-index: 99;  }
      .dataset-open-left { left: 0px; transition: left 2s cubic-bezier(0.25, 1, 0.5, 1); }
   }
}

.dropdown-dataset { position: relative;
  .icon-and-title { display: flex; gap: 12px; align-items: center; justify-content: space-between; cursor: pointer;
    .status-icon { width: 18px; height: 18px; border-radius: 100%; background: #00c980; }
    .card-title { font-family: $F37; font-size: 20px; font-weight: 700; line-height: 24px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: #171717; }
  }
  .dataset-content { position: absolute; width: 592px; display: flex; flex-direction: column; align-items: center; margin-top: 0.5rem; background-color: white; border-radius: 0.5rem; box-shadow: 0px 10px 15px -3px #0000001a; opacity: 0;
    .dataset-heading { display: flex; justify-content: space-between; align-items: center; height: 72px; width: 100%; padding: 24px;
      span { font-family: $F37; font-size: 22px; font-weight: 700; line-height: 26.4px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: var(--charcoal-900-black, #171717); }
    }
    .dataset-options { display: flex; gap: 24px; width: 100%; justify-content: space-between; align-items: center; border-top: 1px solid #e5e5e5; border-bottom: 1px solid #e5e5e5; padding: 24px; flex-direction: column; 
      .options-container { display: flex; justify-content: space-between; width: 100%;
        .options { width: 170.67px; height: 104px; padding: 20px; border-radius: 8px; border: 1px solid var(--Charcoal-300, #d4d4d4); cursor: pointer;
          p { font-family: $F37; font-size: 15.3px; font-weight: 700; line-height: 18.76px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: var(--charcoal-900-black, #171717); margin-top: 20px; }
        }
        .selected-option { border: 2px solid var(--charcoal-900-black, #171717); }
      }
      .circles-container { display: flex; flex-direction: column; width: 100%; gap: 24px; border-top: 1px solid #e5e5e5; padding-top: 20px;
        .circles-heading { display: flex; justify-content: space-between;
          .my-circles { display: flex; gap: 8px;
            p { font-family: $F37; font-size: 18px; font-weight: 700; line-height: 21.6px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: #171717; }
          }
        }
        .circles-btns { display: flex; gap: 12px; flex-wrap: wrap;
          button { width: auto; height: 34px; gap: 4px; border-radius: 20px; border: 1px solid var(--Charcoal-300, #D4D4D4); font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 21px; text-align: center; text-underline-position: from-font; text-decoration-skip-ink: none; background: #FFFFFF; cursor: pointer; transition: width 200ms ease, background 0.3s ease, transform 80ms ease;padding:0px 16px;
            &:hover {  background: #E5E5E5; padding: 0px 20px;}
            &:active {  background: #E5E5E5;  transform: translateY(5px);  }
          }
          .selected { color: #FFFFFF; background: #171717;transition: width 200ms ease, background 0.3s ease, transform 80ms ease;
            &:hover {  background: #424142; }
            &:active {  background: #424142;  transform: translateY(5px);  }
          }
        }
      }
      .close-circle { display: none; }
    }
    .dataset-cta { display: flex; align-items: center; justify-content: flex-end; width: 100%; gap: 12px; height: 96px; padding: 24px;
      .cancel-cta { width: 99px; height: 48px; padding: var(--PaddingGapsMargins12px) var(--PaddingGapsMargins24px); gap: var(--PaddingGapsMargins8px); border-radius: 10px; background: var(--White-White, #ffffff); border: 1px solid var(--Charcoal-200, #e5e5e5); font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 21px; text-underline-position: from-font; text-decoration-skip-ink: none; color: var(--Charcoal-800, #262626); cursor: pointer; transition: width 200ms ease, background 0.3s ease, transform 80ms ease;
        &:hover {  background: #E5E5E5; width: 119px;}
        &:active {  background: #E5E5E5;  transform: translateY(5px);  }
      }
      .apply-cta { width: 91px; height: 48px; padding: var(--PaddingGapsMargins12px) var(--PaddingGapsMargins24px); gap: var(--PaddingGapsMargins8px); border-radius: 10px; background: var(--charcoal-900-black, #171717); color: var(--White-White, #ffffff); font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 21px; text-underline-position: from-font; text-decoration-skip-ink: none; cursor: pointer; transition: width 200ms ease, background 0.3s ease, transform 80ms ease;
        &:hover {  background: #424142; width: 119px;}
        &:active {  background: #424142;  transform: translateY(5px);  }
      }
    }
  }

  .dataset-open { opacity: 1; z-index: 99;  top: 70%;  width: 592px; } 
  .dataset-open-right { right: 0px; transition: right 2s cubic-bezier(0.25, 1, 0.5, 1);}
}

@media screen and (max-width: 768px) {
  .card-container  .additional-content {
    left: 0;
  }
}

@media screen and (max-width: 768px) {
  .dataset-open {
    left: 0;
  }
}






