.deployment-container {
  & { background-color: #fff; width: 100%; height: max-content; max-width: 1256px; height: fit-content; margin: auto; }
  .search-deployments {
    & { padding: 16px 0px; display: flex; justify-content: space-between; }
    .search { border: 1px solid var(--Charcoal-200, #E5E5E5);  padding: 12px 16px; font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 300; line-height: 20px; text-align: left; color: #737373; border-radius: 8px; background-color: #FAFAFA; width: 250px; }
    .deploy-btn { background-color: #171717; border-radius: 8px; font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 700; line-height: 21px; text-align: left; text-transform: none; padding: 12px 24px; } 
    .assessment-url-container { display: flex;  align-items: center; gap: 10px;
      .assesment-url-title { font-family:  "F37 Jan";  font-weight: 700; font-size: 14px; color: var(--charcoal-900-black, #171717);
      }
      .edit-url-input-container { background: #FAFAFA;  min-width: 294px ; height: 48px; border-radius: 8px; padding: 8px 8px 8px 16px; border: 1px solid #D4D4D4 ;  display: flex; align-items: center;
        .edit-input-container { display: flex; align-items: center; gap: 8px;
          .edit-url { color: var(--Charcoal-500, #737373); font-family: "Poppins"; font-weight: 300; font-size: 14px; line-height: 21px;}
          .edit-ctn { background: var(--White-White, #FFFFFF); border: 1px solid var(--Charcoal-200, #E5E5E5) ; width: 72px; height: 32px; border-radius: 8px; padding: 12px 24px ; display: flex; justify-content: center; align-items: center; text-align: center; font-family: "Poppins"; font-weight: 700; font-size: 12px; line-height: 18px; color: var(--Charcoal-800, #262626); cursor: pointer;
          }
        }
      }
      .assesment-url-input-container { font-family: "Poppins"; 
        input { font-family: 'Poppins'; font-size: 14px; font-weight: 300; color: #171717; }
        .urltext { font-family: $Poppins; font-weight: 600; size: 14px; line-height: 21px; color:  var(--charcoal-900-black, #171717); margin-bottom: 10px; }
        .url-input-container { width: 365px;  border-radius: 8px;  background-color:  var(--Charcoal-50, #FAFAFA);
          border: 1px solid #D4D4D4 ; display: flex; align-items: center;   width: 505.29px;
          .https-text { font-weight: 300; font-size: 14px; line-height: 21px; color: var(--Charcoal-500, #737373);
            padding-right: 20px; border-right: 1px solid #E5E5E5; padding: 8px 16px; height: 48px; display: flex; align-items: center; background: #FAFAFA; border-top-left-radius: 8px; border-bottom-left-radius: 8px; width: 258px;
          }
          .url-input-box { height: 48px; width: 251.29px; border-top-right-radius: 8px; border-bottom-right-radius: 8px; background: var(--White-White, #FFFFFF); border: none; padding-left: 20px;  font-size: 14px;
          display: flex; align-items: center; gap: 10px; 
          .url-input { &:focus { outline: none; } border: none; color: var(--charcoal-900-black, #171717); width:132.29px ;  }
          .assessment-save-ctn { background: var(--charcoal-900-black, #171717); color: var(--White-White, #FFFFFF); width: 79px;
            height: 32px; border-radius: 8px; padding: 12px 24px;  font-family: "Poppins"; font-weight:700;  font-size: 12px; line-height: 18px; display: flex; align-items: center; text-align: center; cursor: pointer;
          }
          }
        }
      }
    }
  }
  .main-content {
    & { display: flex; flex-direction: column; gap: 32px; }
    .chart-container { margin: 30px 1px; padding: 26px; box-shadow: 0px 1px 3px 0px #0000001A; border-radius: 18px; 
      .totalrespondhead { display: flex; justify-content: space-between; align-items: center; 
        .totalrespond { font-weight: 800; font-size: 18px; line-height: 28px; color: #171717; }
        .right { color: #171717; font-size: 12px; font-weight: 700; display: flex; justify-content: center; align-items: center; gap: 4px; cursor: pointer; }
      }  
      .interval { margin: 20px 0 30px;
        .intervalbutton { background-color: transparent; border: 1px solid var(--Charcoal-300, #D4D4D4); border-radius: 12px; font-size: 12px; line-height: 18px; align-items: center; font-weight: 700; padding: 2px 10px; margin-right: 8px; }
        .active { color: #ffffff; background-color: #171717; }
      }
      .toalchart { margin-left: -50px; padding: 20px; }
    }
    .table-container {
      & { border: 1px solid var(--Charcoal-200, #E5E5E5); border-radius: 16px; box-shadow: 0px 1px 2px 0px #1018280F; box-shadow: 0px 1px 3px 0px #1018281A; }
  
      .table-header {
        & { display: flex; justify-content: space-between; align-items: center; margin: 25px; min-height: 40px; }
        .filter-dropdowns {
          & { display: flex; gap: 8px;  }
          .dropdown-container {
            & { position: relative; }
            .filter-dropdown {
              & { appearance: none; border: none; padding: 6px 18px 6px 24px; font-size: 14px; font-weight: 700; line-height: 21px; border: 1px solid var(--Charcoal-200, #E5E5E5); border-radius: 18px; background-color: white; cursor: pointer; min-width: 120px; display: flex; align-items: center; background: none; cursor: pointer;}
              &:hover { border-color: #9ca3af; }
              &:focus { outline: none; }
            }
            .dropdown-menu{ 
              & { position: absolute; width: 250px; height: 100px; padding: 12px; gap: 12px; border-radius: 10px; border: 1px ; background:  #FFFFFF; border: 1px solid #E5E5E5; box-shadow: 0px 1px 2px 0px #00000014; z-index: 0.5px; display: grid; margin-top: 10px; }
              .label{ width: 226px; height: 32px; padding: 8px 0px 8px 0px; gap: 0px; display: flex; justify-content: space-between; font-family: 'Poppins'; font-size: 14px; font-weight: 700; line-height: 21px; text-align: left; color: #737373; }
            }
            .dropdown-icon { position: absolute; right: 0.75rem; top: 50%; transform: translateY(-50%); pointer-events: none; color: #171717; }
          }
        }
        .delete-container{
          & {justify-content: end; }
          .delete-button {cursor: pointer; display: flex; width: 108px ; height: 40px; border: 1px 0px 0px 0px; border: 1px solid  #991B1B; background: #FFFFFF; border-radius: 8px; padding: 12px 24px 12px 24px; gap: 4px; justify-content: center; align-items: center; }
            .delete-icon {width: 10px; height: 11px; gap: 0px; font-family: Font Awesome 6 Pro; font-size: 11.12px; font-weight: 400; line-height: 11.12px; text-align: center; }
            p{ width: 40px; align-items: center; font-family: Poppins; font-size: 12px; font-weight: 700; line-height: 18px; text-align: left; color: #991B1B; }
        }
      }
      .deployments-list {
        & { display: flex; flex-direction: column; }
        .header-row, .row { display: flex; width: 100%; }
        .header-row { border-top: 1px solid #E5E5E5; font-weight: 700; background-color: #FAFAFA; font-family: 'Poppins', sans-serif; font-size: 12px; font-weight: 700; line-height: 18px; text-align: left; height: 44px;}
        .row { border-top: 1px solid #E5E5E5; padding: 14px 0; box-sizing: border-box; font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 300; line-height: 20px; text-align: left; color: #737373; height: 72px; }
        .cell { display: flex; align-items: center; padding: 0 20px; gap: 2px; }
        .checkbox-column {
          & {width: 64px;}
          input[type="checkbox"] { transform: scale(1.3); margin: 0; cursor: pointer; }
          img { cursor: pointer; }
        }
        .no-data-message { text-align: center; margin: 20px 0; width: 100%; }
        .name-column { width: 304px; font-weight: 700; color: #171717; cursor: pointer; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; display: block; vertical-align: middle; line-height: 45px}
        .status-column { width: 150px; font-size: 12px; }
        .type-column { width: 150px; padding: 0; }
        .assessments-column { width: 150px; font-family: 'Poppins'; font-size: 14px; }
        .respondents-column { width: 150px; padding:  0px 2px 0px 24px; }
        .interaction-column { width: 150px; padding: 0px 2px 0px 24px; }
        .actions-column { width: 100px; justify-content: center; }
        .delete-icon {cursor: pointer; width: 10px; height: 11px; gap: 0px; font-family: Font Awesome 6 Pro; font-size: 11.12px; font-weight: 400; line-height: 11.12px; text-align: center; }
        .on { background-color: #10b981; }
        .off { background-color: #D4D4D4; }
        .archived { background-color: #9ca3af; }
        .status-indicator { display: inline-block; width: 10px; height: 10px; border-radius: 50%; margin-right: 4px; }
        .row:hover { background-color: #F9FAFB; }
        .pagination {
          & { display: flex; justify-content: space-between; padding: 16px 24px; border-top: 1px solid #E5E5E5; }
          .items-perpage {
            & { display: flex; gap: 12px; align-items: center; }
            p { font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 300; line-height: 20px; text-align: left; color: #171717 }
            .dropdown-container {
              & { position: relative; }
              .rows-dropdown {
              & { appearance: none; padding: 8px; font-size: 14px; font-weight: 300; line-height: 20px; border: 1px solid #D4D4D4; border-radius: 8px; background-color: white; cursor: pointer; min-width: 82px }
              &:hover { border-color: #9ca3af; }
              &:focus { outline: none; border-color: #171717; box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1); }
            }
            .dropdown-icon { position: absolute; right: 0.75rem; top: 50%; transform: translateY(-50%); pointer-events: none; color: #171717; }}
          }
          .right-btns {
            & { display: flex; align-items: center; }
            .btn { border: 1px solid #D4D4D4; padding: 6px 8px; height: 30px; }
            .previous { border-radius: 8px 0 0 8px; }
            .next { border-radius: 0 8px 8px 0; }
            span { font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 700; line-height: 21px; text-align: center; color: #737373; padding: 0 10px; border: 1px solid #D4D4D4; min-width: 30px; min-height: 30px; display: flex; align-items: center; justify-content: center; }
            .current { background-color: #262626; color: #fff; }
          }
        }
      }
    }
  }
  .loading { flex-direction: column; font-family: "Inter", sans-serif; font-weight: bold; }
  .modal { position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: #00000033; display: flex; justify-content: center; align-items: center; backdrop-filter: blur(8.899999618530273px); z-index: 10; background: rgba(255, 251, 251, 0.10); backdrop-filter: blur(5px); }
  .modal-content {
    & { background: #FFFFFF; border-radius: 16px; text-align: center; max-width: 500px; width: 100%; box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }
    .heading-container {
      & { display: flex; align-items: center; justify-content: center; gap: 12px; padding: 24px; border-bottom: 1px solid #E5E5E5; }
      .heading {
        & { display: flex; align-items: center; justify-content: space-between; width: 100% }
        .delete {
          & { font-family: 'Montserrat', sans-serif; font-size: 18px; font-weight: 800; line-height: 28px; color: #171717; }
        }
        .close-icon {
          & { cursor: pointer; }
        }
      }
    }
    .mid-container {
      & { display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 10px; padding: 33px 24px; }
      .confirmation-msg {
        & { display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 12px; }
        .message {
          & { font-family: 'Poppins', sans-serif; font-size: 16px; font-weight: 700; line-height: 24px; color: #171717; max-width: 34ch; }
        }
        .delete-info {
          & { font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 300; line-height: 21px; color: #000000; max-width: 34ch; }
        }
      }
    }
    .buttons-container {
      & { display: flex; padding: 24px; align-items: center; justify-content: flex-end; border-top: 1px solid #E5E5E5; gap: 12px; }
      .cancel {
        & { padding: 12px 24px; border-radius: 8px; border: 1px solid #E5E5E5; font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 700; line-height: 21px; color: #262626; }
      }
      .delete-btn {
        & { padding: 12px 24px; border-radius: 8px; border: 1px solid #991B1B; font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 700; line-height: 21px; color: #991B1B; }
      }
    }
  }
  .modal-actions { margin-top: 20px; }
  .confirm-button { background: #171717; color: #FFFFFF; border: none; padding: 10px 20px; cursor: pointer; border-radius: 6px; }
  
  .cancel-button { background: #D4D4D4; color: white; border: none; padding: 10px 20px; cursor: pointer; margin-left: 10px ; border-radius: 6px; }
}
@media screen and (max-width: 500px) {
  .deployment-container .main-content { width: 490px; }
  .deployment-container .main-content .table-container .deployments-list .header-row { font-size: 11px; }
  .deployment-container .main-content .table-container .deployments-list .row { font-size: 11px; }
  .deployment-container .main-content .table-container .deployments-list .checkbox-column { width: 58px; }
  .deployment-container .main-content .table-container .deployments-list .cell { padding: 0 3px; }
  .deployment-container .main-content .table-container .deployments-list .name-column { width: 72px; }
  .deployment-container .main-content .table-container .deployments-list .actions-column { width: 30px; }
  .deployment-container .main-content .table-container .deployments-list .status-column { width:  60px; font-size: 11px; }
  .deployment-container .main-content .table-container .deployments-list .type-column { width: 98px; }
  .deployment-container .main-content .table-container .deployments-list .respondents-column { width: 97px; padding: 0 2px 0 11px; }
  .deployment-container .main-content .table-container .deployments-list .interaction-column { width: 78px; padding: 0 2px 0 11px; }
  }
  @media screen and (max-width: 900px) {
  }
