.recommended { & { background-color: #FFFFFF; padding: 24px; border-radius: 16px; }
  .recommended-h3 { color: #171717; font-size: 28px; font-weight: 400; line-height: 44px; }
  .recommended-p { font-size: 16px; line-height: 24px; font-weight: 300; color: #737373; display: flex; margin-bottom: 24px; }
  .recommended-box-space { display: flex; gap: 12px; flex-wrap: wrap; }
  .recommended-box { & { width: 238px; }
  .recommendation-title { & { display: flex; justify-content: space-between; }
    p { font-size: 14px; font-weight: 500; line-height: 16px; color: #171717; width: 171px; margin: 10px; }
    .recommended-tag-img { width: 22px; height: 22px; margin: 10px; }
  }
  .spritual-green { font-size: 12px; font-weight: 700; line-height: 18px; width: max-content; padding: 0px 5px; text-align: center; border-radius: 18px; margin: 15px 10px 0 10px; }
  }
}

@media only screen and (max-width: 880px) {
  .recommended .recommended-box-space { justify-content: center; margin: 34px 0; }
  .recommended .recommended-box { margin: 0; }
  .recommended .recommended-h3 { font-size: 18px; text-align: center; }
  .recommended .recommended-p { font-size: 12px; justify-content: center; line-height: 14px; }
  .recommended .recommended-p p { text-align: center; }
  .spiritual-container .chart-container .right-container .right-container-footer p { font-size: 10px; }
}