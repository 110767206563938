.range-react-datepicker { border: 1px solid var(--Charcoal-200, #E5E5E5) !important; border-radius: 12px !important; height: 270px; position: relative; width: 284px; padding: 10px 16px; }

.range-react-datepicker__header { background-color: white !important; border-bottom: none !important; border-radius: 12px !important; }

.range-react-datepicker__month-container { width: 100%; }

.range-monthText { color: var(--charcoal-900-black, #171717); font-weight: 700; font-size: 14px; line-height: 16.8px; font-family: F37 Jan; }

.range-react-datepicker__day-name { color: var(--Charcoal-500, #737373) !important; font-family: Poppins !important; font-weight: 700 !important; font-size: 12px !important; line-height: 18px !important; text-transform: capitalize !important; }

.range-react-datepicker__day--outside-month { color: white !important; }

.range-react-datepicker__day { color: var(--charcoal-900-black, #171717); font-family: Poppins !important; font-weight: 300 !important; font-size: 14px !important; line-height: 21px !important; }

.range-button-container { position: absolute; bottom: 20px; display: flex; gap: 12px; padding: 0px 16px; }

.range-today-button { width: 120px; height: 32px; border-radius: 8px; background: var(--charcoal-900-black, #171717); font-family: Poppins; font-weight: 700; font-size: 12px; line-height: 18px; color: var(--White-White, #FFFFFF); display: flex; justify-content: center; align-items: center; cursor: pointer; }

.range-clear-button { width: 120px; height: 32px; border-radius: 8px; background: var(--White-White, #FFFFFF); font-family: Poppins; font-weight: 700; font-size: 12px; line-height: 18px; color: var(--Charcoal-800, #262626); display: flex; justify-content: center; align-items: center; border: 1px solid var(--Charcoal-200, #E5E5E5); cursor: pointer; }

.range-calendar-container { width: inherit; background: white; border: 1px solid var(--Charcoal-200, #E5E5E5); border-radius: 12px; cursor: pointer; &.active { color: #ffffff !important; background-color: #171717; .range-calendar-text { color: #ffffff !important; } } }

.range-calendar-header { display: flex; justify-content: center; align-items: center; width: 100%; padding: 0 !important; }

.range-calendar-icon-text { display: flex; gap: 8px; align-items: center; }

.range-calendar-icon { margin-bottom: 3px; }

.range-date-picker-container { position: absolute; z-index: 1000; margin-top: 30px; right: 9px !important;}

.range-calender-container {  border: 1px solid var(--Charcoal-200, #E5E5E5) !important; background-color: white; cursor: pointer; border-radius: 12px; font-size: 12px; line-height: 18px; display: flex; align-items: center; justify-content: center; font-weight: bolder; margin-right: 8px; width: 220px; &.active { color: #ffffff !important; background-color: #171717; .range-calendar-text { color: #ffffff !important; } } }

.range-calendar-header { display: flex; justify-content: center; align-items: center; width: 100%; padding: 0 !important; }

.range-calendar-text { color: black; font-family: Poppins; font-weight: 700; font-size: 12px; line-height: 18px; }
.range-calendar-text1 { color: black; font-family: Poppins; font-size: 11px; line-height: 18px; }

.range-calendar-icon-text { display: flex; gap: 8px; align-items: center; }

.range-month-button { background: white; border: none; cursor: pointer; font-size: 14px; color: #737373; }

.react-datepicker__day { margin: 0 !important; padding: 0.25rem; line-height: 2rem; box-sizing: border-box; width: 2rem !important; }

.range-start-date { background-color: #171717 !important; color: white !important; border-radius: 0 !important; padding: 0.2rem; display: block; width: calc(100% - 0.4rem); height: calc(100% - 0.4rem); margin: auto; z-index: 2; border-top-left-radius: 12px !important; border-bottom-left-radius: 12px !important; }

.range-end-date { background-color: #171717 !important; color: white !important; border-radius: 0 !important; padding: 0.2rem; display: block; width: calc(100% - 0.4rem); height: calc(100% - 0.4rem); margin: auto; border-top-right-radius: 12px !important; border-bottom-right-radius: 12px !important; position: relative; z-index: 2; }

.range-in-between { background-color: #171717 !important; color: white !important; border-radius: 0 !important; padding: 0.2rem; display: block; width: calc(100% - 0.4rem); height: calc(100% - 0.4rem); margin: auto; z-index: 1; }

.range-start-row { border-top-left-radius: 12px !important; border-bottom-left-radius: 12px !important; }

.range-end-row { border-top-right-radius: 12px !important; border-bottom-right-radius: 12px !important; }

.range-start-date.range-new-month, .range-in-between.range-new-month { border-radius: 12px !important; }

.react-datepicker__day--selected, .react-datepicker__day--in-range { display: flex; justify-content: center; align-items: center; border-radius: 0;background-color: black !important;}

.react-datepicker__day:hover { background-color: #333333 !important; color: white !important; }

.react-datepicker__day--disabled { color: #c0c0c0 !important; background-color: transparent !important; cursor: not-allowed; }

.react-datepicker__week { margin: 2px 0 !important; }

.range-header-container{display: flex;align-items: center;justify-content: space-between;}

.range-middle-date{border-radius: 0 !important;}

.react-datepicker__day--in-selecting-range{
    background: black !important;
    // border-radius: 0 !important;
}