.church-health-deploy {
    & { min-height: 100vh; width: 100%; background-color: #F5F5F5 }
    .heading { font-size: 12px }
    .filters-container { display: flex; justify-content: center; }
    .church-health__banner { display: flex; justify-content: space-between; align-items: center; max-width: 1200px; padding: 14px 8px; margin: 0 auto; padding-top: 113px;
        @media (max-width: 768px) { flex-direction: column; padding:14px 8px; padding-top: 105px; }
    }
    .church-health__Filters-Section { display: flex; gap: 1rem; max-width: 1200px; margin: 0 auto; padding: 14px 8px; position: relative;
        @media (max-width: 768px) { flex-direction: column; padding:14px 8px }
    }

    .church-health__people-flourishing { display: flex; gap: 1rem; max-width: 1200px; margin: 0 auto; padding: 14px 8px; 
        @media (max-width: 768px) { flex-direction: column; padding:14px 8px }
    }
 
    .church-health__thriving { display: flex; gap: 1rem; max-width: 1200px; margin: 0 auto; padding: 14px 8px; 
     @media (max-width: 768px) { flex-direction: column; padding:14px 8px } }

     .church-health-deploy-sticky { position: fixed; z-index: 10; top: 88px; }

}
