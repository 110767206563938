.login-page { display: flex !important; justify-content: center !important; align-items: center; min-height: 100vh; background-color: #f3f4f6; }
  .login-container { display: flex; flex-direction: column; align-items: center; width: 100%; max-width: 36rem;  padding: 4rem 1rem; margin: 0 auto; }
  .login-card { background-color: white; width: 100%; padding: 3rem 4rem; border-radius: 0.75rem; box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); }
  .powered-by { display : flex ; justify-content: center; text-align: center; margin-top: 2.5rem;
    p { font-size: 0.875rem;  color: #474a52;  font-weight: 600; }
    .logo-wrapper { margin-top: 1rem; margin-bottom: 2rem; }
    .glooai-logo { width: 172px; height: 60px; object-fit: contain; }
  }
  .login-content {
    .logo-container { display: flex; justify-content: center; margin-bottom: 1.5rem;
      .logo { max-width: 250px; height: auto; }
    }
    .sign-in-text { text-align: center; font-size: 1.5rem; font-weight: 600; margin-bottom: 1.5rem; }
    .login-form {
        .form-group { margin-bottom: 1.5rem;
          label { display: block; font-size: 0.875rem; margin-bottom: 0.5rem;
            .required { color: #ef4444; margin-left: 0.25rem; }
          }
          input[type="email"],
          .password-input-container { width: 100%; padding: 0.75rem; font-size: 0.875rem; border: 1px solid #d1d5db; border-radius: 0.375rem; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            &:focus-within { outline: none; border-color: rgb(42, 173, 143); box-shadow: 0 0 0 3px rgba(114, 66, 249, 0.1); }
          }
          .password-input-container { display: flex; align-items: center; padding: 0;
            input { flex-grow: 1; border: none; background: transparent; padding: 0.75rem; outline: none; }
            .password-toggle { background: none; border: none; padding: 0.5rem; cursor: pointer;
              .password-icon { width: 20px; height: 20px; }
            }
          }
          .forgot-password { text-align: right; margin-top: 0.5rem;
            a { color: rgb(42, 173, 143); text-decoration: none; font-size: 0.875rem;
              &:hover { text-decoration: underline; }
            }
          }

          .error-message { color: #ef4444; font-size: 0.75rem; margin-top: 0.25rem; }
        
      }
      .submit-button { width: 100%; padding: 0.75rem; background-color: rgb(42, 173, 143); color: white; font-size: 1rem; font-weight: 600; border: none; border-radius: 0.375rem; cursor: pointer; transition: background-color 0.2s ease;
        &:hover { background-color: darken(rgb(42, 173, 143), 10%); }
      }
    }
  }
  @media (max-width: 640px) {
    .login-container { padding: 2rem 1rem;  max-width: 100%; }
    .login-card { padding: 1.5rem; }
  }
  @media (min-width: 641px) and (max-width: 1024px) {
    .login-container { max-width: 36rem; }
  }