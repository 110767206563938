.mobile-people-flourishing-insights { display: flex; overflow-x: auto; gap: 16px; scroll-snap-type: x mandatory; -ms-overflow-style: none; scrollbar-width: none;
    .mobile-people-flourishing-card { height: 235px; font-family: $F37; background: #FAFAFA; border-radius: 24px; padding: 24px; flex: 1; text-align: left; display: flex; flex-direction: column; justify-content: space-between; border: 1px solid var(--charcoal-900-black, #171717) ; background-color: white;  scroll-snap-align: center; min-width: 288px; scroll-snap-align: center;
      .mobile-people-flourishing-powered-by-wrapper {   display: flex; align-items: center; gap: 4px; margin-bottom: 16px; justify-content: space-between;
        .mobile-powered-container { display: flex; align-items: center; gap: 4px;
          .mobile-people-flourishing-sparkle {font-size: 16px; padding-right: 4px;
          }
          .mobile-people-flourishing-powered-text { color: #171717;  font-size: 16px;  font-weight: 700;}
          }
        }
        .mobile-beta-container { 
          width: 45px; height: 24px;  padding: 8px ; background: var(--Charcoal-200, #E5E5E5); font-family: $Poppins;
          font-weight: 700; font-size: 12px; line-height: 18px;   color: var(--charcoal-700-brand, #424142); display: flex; align-items: center;
          text-align: center; border-radius: 9999px;
        }
     .mobile-people-flourishing-insights-text { font-size: 18px; line-height: 26.4px; color:  #171717; font-weight: 700; width: fit-content;}
      .highlight-green { background: linear-gradient(90deg, #7C3AED 0%, #1F59FF 50%, #00C980 100%);
        -webkit-background-clip: text;
     -webkit-text-fill-color: transparent; background-clip: text; font-weight: 700;
      }
      .highlight-blue { background: linear-gradient(90deg, #1F59FF, #7C3AED); -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; background-clip: text; font-weight: 700;
         }
        .mobile-people-flourishing-button { background: var(--White-White, #FFFFFF); width: 222px; height: 48px; border-radius: 8px; padding: 12px 24px; border: 1px solid var(--Charcoal-200, #E5E5E5) ; display: flex; justify-content: space-between; align-items: center;  cursor: pointer; margin-top: 20px;
        p { font-family: "Poppins"; font-weight: 700; font-size: 14px; line-height: 21px; color: var(--Charcoal-800, #262626); 
        }
        .img { width: 24px; height: 24px; }
      }
    }
}
.mobile-people-flourishing-scroll-indicators { display: flex; justify-content: center; gap: 8px; margin-top: 16px;}
.mobile-people-flourishing-scroll-dot { width: 8px; height: 8px; border-radius: 50%; background-color: #E5E7EB; cursor: pointer; transition: background-color 0.3s ease;
}
.mobile-people-flourishing-scroll-dot { width: 8px; height: 8px; border-radius: 50%; background-color: #D4D4D4; transition: all 0.3s ease;}
.mobile-people-flourishing-scroll-dot .active { width: 24px; border-radius: 4px; background-color: #171717;}
.mobile-insights-container::-webkit-scrollbar { display: none;}