//components
@import "./layout/Layout.scss";
@import "./layout/Sidebar/Sidebar.scss";

//pages
// @import "./Pages/Temp/Temp.scss";
@import "./Assests/Styles/Fonts.scss";
// @import "./components/Home/Home.scss";
@import "./pages/Home/Home.scss";
@import './components/Home/Header/Header.scss';
@import "./pages/DimensionDetail/DimensionDetail.scss";
@import "./pages/Spiritual/Spiritual.scss";
@import "./pages/Resources/Resources.scss";
@import "./pages/Tools/Tools.scss";
@import "./components/Home/DeployCard/DeployCard.scss";
@import "./components/Home/FlourishingContent/FlourishingContent.scss";
@import "./components/Home/ThrivingDimensions/ThrivingDimensions.scss";
@import "./components/Home/Demographics/Demographics.scss";
@import "./components/Assessments/ChurchLeader/AssessmentsTools.scss";

@import "./components/CommonCard/CommonCard.scss";
@import "./pages/Assesments/Assessments.scss";
@import "./pages/Authenticate/Authenticate.scss";
@import "./components//Assessments/Share/Share.scss";
@import "./pages/Assesments/AssesmentsBarnaChurchPulse.scss";
@import "./components/Assessments/Result/Result.scss";
@import "./components/Assessments/Result/RThrivingOverview/RThrivingOverview.scss";
@import "./components/Assessments/Partners/Partners.scss";
@import "./pages/Deployments/Deployments.scss";
@import "./pages/Login/Login.scss";
@import "./pages//Register/Register.scss";
@import "./pages/Library/Library.scss";
@import "./pages/ChurchHealth/ChurchHealth.scss";
@import "./components/Assessments/Result/FlourishingOverview/FlourishingOverview.scss";
@import "./components/Home/GrowthGoalsContent/GrowthGoalsContent.scss";
@import "./components/Assessments/Calender/Calendar.scss";
@import "./components/Assessments/Time/Time.scss";
@import "./pages/Organizations/Organizations.scss";
@import "./pages/Deploy/Deploy.scss";
@import "./components/Assessments/RangeCalender/RangeCalender.scss";
@import "./components/ChurchHealth/BannerNetwork/BannerNetwork.scss";
@import "./components/ChurchHealth/Banner/Banner.scss";
@import "./components/ChurchHealth/CreateDeployAssessment/CreateDeployAssessment.scss";
@import "./components/ChurchHealth/PeopleFlourishing/PeopleFlourishing.scss";
@import "./components/ChurchHealth/ChurchThriving/ChurchThriving.scss";
@import "./components/ChurchHealth/Demographics/Demographics.scss";
@import "./components/ChurchHealth/ChruchHealthCards/ChruchHealthCards.scss";
@import "./components/ChurchHealth/ComparisonBarChart/ComparisonBarChart.scss";
@import "./components/FilterCard/FilterCard.scss";
@import "./components/ChurchHealth/Header/Header.scss";
@import "./components/ChurchHealthMobile/ChurchHealthMobile.scss";
@import "./components/ChurchHealthMobile/ChurchHealthMobileCards/ChurchHealthMobileCards.scss";
@import "./components/ChurchHealthMobile/Header/Header.scss";
@import "./components/ChurchHealthMobile/MobileAIInsights/MobileAIInsights.scss";
@import "./components/ChurchHealth/AIInsightsPeopleFlourishing/AIInsightsPeopleFlourishing.scss";
@import "./components/ChurchHealth/AIChurchThriving/AIChurchThriving.scss";
@import "./components/GrowthGoals/GrowthGoals.scss";
@import "./components//RecommendedSection/RecommendedSection.scss";
@import "./components/ChurchHealth/DemographicsDetails/DemographicsDetails.scss";
@import "./components/ChurchHealthMobileDeploy/ChurchHealthMobile.scss";
@import "./components/MobileFilterCard/MobileFilterCard.scss";
@import "./components//ChurchHealthMobile/DemographicsDetailsMobile/DemographicsDetailsMobile.scss";
@import "./components/ChurchHealthMobile/AIChurchThrivingMobile/AIChurchThrivingMobile.scss";
@import "./pages/ChurchHealthDeploy/ChurchHealthDeploy.scss";
@import "./components/ChurchHealth/LimitedTimeBanner/LimitedTimeBanner.scss";
/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
  2. Remove default margin
*/
* {
  margin: 0;
}

/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
  width: 100vw;
}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: $Poppins;
  font-weight: normal;
  font-style: normal;
  overflow: hidden;
}

/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

/* You can also apply the Montserrat font to specific elements if needed */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $Poppins;
  font-weight: 400 !important;
}

.App {
  & {
    height: 100vh;
    width: 100%;
  }
}

.recharts-surface {
  outline: none;
}

.recharts-layer {
  outline: none;
  cursor: pointer;
}

.recharts-wrapper {
  // max-width: 1250px !important;
  width: 100% !important;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  left: calc(0% - 30px);
  bottom: calc(100% + 10px);
  min-width: 180px;
  max-width: 250px;
  padding: 10px;
  position: absolute;
  box-shadow: 0px 0px 10px #b2b2b2;
  background: #fff;
  z-index: 1;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  color: #171717;
  border-radius: 4px;
  text-transform: initial;
  font-family: "Poppins"
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip:hover .tooltiptext:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  box-shadow: 0px 0px 10px #b2b2b2;
  bottom: -10px;
  left: calc(50% - 10px);
  background: #fff;
  transform: rotate(45deg);
  z-index: -2;
}

.tooltip:hover .tooltiptext:before {
  content: "";
  width: 40px;
  height: 20px;
  position: absolute;
  background: #fff;
  left: calc(50% - 20px);
  bottom: 0;
  z-index: -1;
}


.areaChartTooltip {
  position: relative;
  background: #171717;
  padding: 5px;
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(1, 1, 1, 0.1);
  font-family: $Poppins;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  min-height: 35px;
}

.areaChartTooltip::after {
  content: '';
  position: absolute;
  top: 97%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: #000000 transparent transparent transparent;
}