.GrowthGoalsContent-main-container { 
    .GrowthGoalsContent-header-container { display: flex; align-items: center; justify-content: space-between; padding: 24px 32px;
        .GrowthGoalsContent-header-title { color: #000000; font-family: F37 Jan; font-weight: 400; font-size: 32px; line-height: 44px; width: 194px; height: 44px; }
        .GrowthGoalsContent-header-subtitle{ color: #737373; font-family: Poppins; font-weight: 400; font-size: 18px; line-height: 28px; width: 546px; height: 56px; margin-top: 2px; }
        .GrowthGoalsContent-arrow-container { display: flex; align-items: center; gap: 8px;
            .GrowthGoalsContent-leftarrow-container { width: 48px; height: 48px; background-color: #FFFFFF; border: 1px solid  #E5E5E5; display: flex; justify-content: center; align-items: center; border-radius: 8px; padding: 12px;   cursor: pointer; }
            .GrowthGoalsContent-rigtharrow-container { width: 48px; height: 48px; background-color: #FFFFFF; border: 1px solid  #E5E5E5; display: flex; justify-content: center; align-items: center; border-radius: 8px; padding: 12px;  cursor: pointer; }
        }
    }
    .GrowthGoalsContent-cards-container { padding: 7px 12px; display: flex; gap: 16px; overflow-x: auto; scroll-behavior: smooth; margin: 0px 20px;
        .GrowthGoalsContent-cards-box { width: 345px; height: 126px; border-radius: 16px; padding: 24px; background-color: #FFFFFF; box-shadow: 0px 0px 12px -2px #0000000D;
           .GrowthGoalsContent-cards-header { width: 297px; height: 32.59px; display: flex; justify-content: space-between; margin-bottom: 20px;
            .GrowthGoalsContent-cards-titlebox { display: flex; gap: 12px; align-items: center;
                .GrowthGoalsContent-cards-icon { background-color: #F5F3FF; width: 32.59px; height: 32.59px; border-radius: 6.52px; display: flex; justify-content: center; align-items: center; }
                .GrowthGoalsContent-cards-title { color: #171717; font-family: F37 Jan; font-weight: 400; font-size: 21px; line-height: 25.2px; }
            }
            .GrowthGoalsContent-cards-percentage { color: #1D846F; font-family: F37 Jan; font-weight: 400; font-size: 21px; line-height: 25.2px; }
           }
           .GrowthGoalsContent-cards-text { color: #171717; font-family: Poppins; font-weight: 300; font-size: 14px; line-height: 21px; width: 297px; height: 31px; display: flex; align-items: end; }

        }


    }
    .GrowthGoalsContent-cards-container::-webkit-scrollbar {
        display: none;
    }

}

@media (max-width: 768px) {
    .GrowthGoalsContent-main-container .GrowthGoalsContent-header-container .GrowthGoalsContent-header-subtitle { display: flex; flex-direction: column; width: 300px; }
}
@media (max-width: 550px) {
    .GrowthGoalsContent-main-container .GrowthGoalsContent-header-container .GrowthGoalsContent-header-subtitle { font-size: 14px; }
}