.mobile-label { font-size: 14px; line-height: 21px; color: #171717; font-weight: 300; font-family: $Poppins; display: none; }
.chart-mobile { & { margin: 2px 0px 0px 0px; display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 8px; width: 100%; }
  .chart-mobile-container { width: 100%;
        .chart-label{width: 100%;text-align: left; font-family: $Poppins; font-size: 14px; font-weight: 300; line-height: 21px; text-underline-position: from-font; text-decoration-skip-ink: none;color: #171717; margin-bottom: 4px; }
        .chart-bar { display: flex; justify-content: center; align-items: center; flex-direction: row-reverse; gap: 20px; width: 100%;
          .data-value { display: flex; align-items: center; justify-content: center; gap: 0px; flex-direction: column; width: fit-content; width: 16px; height: 43px;
            .data-one { color: #C581FF; font-family: $F37; font-size: 16px; line-height: 21px; font-weight: 700; text-align: center; width: fit-content; }
            .data-two { color: #00C980; font-family: $F37; font-size: 16px; line-height: 21px; font-weight: 700; text-align: center; width: fit-content; }
          }
      }
        .databar { background-color: #E5E5E5; height: 43px; display: flex; align-items: flex-start; border-radius: 8px; flex-direction: column; width: 100%; flex-shrink: 1; position: relative; }
        .dataheightone { border-radius: 8px 8px 8px 0px; background-color: #C581FF; width: 50%; }
        .dataheighttwo { border-radius: 0px 8px 8px 8px; background-color: #00C980; width: 50%; }
        .customtootip-container {
          .customtootip { position: absolute; background-color: #FFFFFF; padding: 12px; width: 204px; top: -118px; left: -1px; border-radius: 8px; box-shadow: 0px 1px 2px -1px #0000001A, 0px 1px 3px 0px #0000001A;            
            p { margin: 0 0 12px 0; color: #262626; font-family: $Poppins; font-size: 12px; font-weight: 300; line-height: 18px; }
            button { color: #ffffff; font-family: $Poppins; font-size: 12px; font-weight: 700; line-height: 18px; background-color: #171717; border-radius: 8px; display: flex; justify-content: center; align-items: center; gap: 6px; padding: 5px 15px;
              img { width: 16px; height: 16px; }
            }
          }
        }
    }     
  }     
