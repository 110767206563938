@import '../../Assests/Styles/Fonts.scss';

.demographics-container {
    & { background-color: #ffff; }
    .demographics-header { & { display: flex; justify-content: left; align-items: center; padding: 24px; }
      .pagename { font-family: $F37; font-weight: 400; font-size: 24px; color: #171717; line-height: 44px; }
    }
    .demographics-second-container {
        & { width: 100%; height: 100%; padding: 0px 32px 0px 32px; gap: 16px; }
        .first-inner-container { & { width: 100%; max-height: 330px; margin-bottom: 15px; }
            .cards { 
                & { width: 100%; display: flex; align-items: center; justify-content: space-between; gap: 16px }
                .card { 
                    & { height: 300px; max-height: 100%; width: 32%; background-color: #FAFAFA; border-radius: 16px; padding: 16px 24px 44px 24px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; margin-bottom: 10px; box-shadow: 0px 0px 6px 0px #0000000D; box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.2); }
                    .dimension-title { color: #171717; font-weight: 400; font-size: 21px; line-height: 25.2px; font-family: $F37; text-align: left; }
                    .icon { height: 40px; }
                    .statement-box { 
                        & { width: 100%; height: 100%; justify-content: space-between; }
                        .chart-container { 
                            & { display: flex; gap: 16px; align-items: center; justify-content: center; margin-top: 20px; }
                            .legend{
                                & { width: 100px; height: 160px; display: flex; justify-content: center; flex-direction: column; gap: 4px; }
                                .legend-item{
                                    & { width: 100px; gap: 8px; font-family: $Poppins; font-size: 10px; font-weight: 300; line-height: 15px; text-align: left;  }
                                } 
                            }
                        }
                    }
                    .statement { 
                        & { display: flex; flex-direction: column; gap: 24px; }
                        .col-statement { 
                            & { font-size: 25px; font-weight: 700; line-height: 40px; text-align: left; color: rgba(0, 0, 0, 0.44); width: 280px; }
                        }
                        .percentages { font-size: 56px; font-weight: 700; line-height: 36px; color: #1D846F; }
                    }
                }
                .highlight-green { color: #2AAD8F; }
            }
        }

        .second-inner-container {
            & { width: 100%; height: 100%; margin-bottom: 15px; }
            .cards {
                & { width: 100%; display: flex; align-items: center; justify-content: space-between; gap: 16px }
                .card {
                    & { height: 420px; max-height: 100%; width: 32%; background-color: #FAFAFA; border-radius: 16px; padding: 16px 24px 44px 24px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; margin-bottom: 10px; box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.2); }
                    .dimension-title { color: #171717; font-weight: 400px; font-size: 21px; line-height: 25px; padding: 8px 0px 8px 0px; font-family: $F37; text-align: left; }
                    .icon { height: 40px; }
                    .statement-box {
                        & { width: 100%; height: 100%; justify-content: space-between; }
                        .chart-container { display: flex; gap: 16px; }
                    }
                    .statement {
                        & { display: flex; flex-direction: column; gap: 24px; }
                        .col-statement {
                            & { font-size: 25px; font-weight: 700; line-height: 40px; text-align: left; color: rgba(0, 0, 0, 0.44); width: 280px; }
                        }
                        .percentages { font-size: 56px; font-weight: 700; line-height: 36px; color: #1D846F; }
                    }
                }
                .highlight-green { color: #2AAD8F; }
            }
        }

        .third-inner-container {
            & { width: 100%; height: 100%; }
            .cards {
                & { width: 100%; align-items: center; justify-content: space-between; gap: 16px }
                .card {
                    & { max-height: 350px; max-height: 100%; background-color: #FAFAFA; border-radius: 16px; padding: 16px 24px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; margin-bottom: 10px; box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.2); }
                    .dimension-title { color: #171717; font-weight: 400px; font-size: 21px; line-height: 25px; font-family: $F37; text-align: left; margin-bottom: 20px; }
                    .icon { height: 40px; }
                    .statement-box {
                        & { width: 100%; height: 100%; justify-content: space-between; }
                        .chart-container { display: flex; gap: 16px; }
                    }
                    .statement {
                        & { display: flex; flex-direction: column; gap: 24px; }
                        .col-statement {
                            & { font-size: 25px; font-weight: 700; line-height: 40px; text-align: left; color: rgba(0, 0, 0, 0.44); width: 280px; }
                        }
                        .percentages { font-size: 56px; font-weight: 700; line-height: 36px; color: #1D846F; }
                    }
                }
                .highlight-green { color: #2AAD8F; }
            }
        }
    }
}