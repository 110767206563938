.forgot-page { display: flex !important; justify-content: center !important; align-items: center; min-height: 100vh; background-color: #f3f4f6; }
    .forgot-container { display: flex; flex-direction: column; align-items: center; width: 100%; max-width: 36rem;  padding: 4rem 1rem; margin: 0 auto; }
    .forgot-card { background-color: white; width: 100%; padding: 3rem 4rem; border-radius: 0.75rem; box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); }
    .powered-by { display : flex ; justify-content: center; text-align: center; margin-top: 2.5rem;
    p { font-size: 0.875rem;  color: #474a52;  font-weight: 600; }
    .logo-wrapper { margin-top: 1rem; margin-bottom: 2rem; }
    .glooai-logo { width: 172px; height: 60px; object-fit: contain; }
}
.forgot-content {
    .logo-container { display: flex; justify-content: center; margin-bottom: 1.5rem;
    .logo { max-width: 250px; height: auto; }
    }
    .forgot-password-text { text-align: center; font-size: 1.5rem; font-weight: 600;  }
    .forgot-text{ font-size: 14px; font-weight: 400; line-height: 24px; text-align: center; margin: 10px 0px 30px 0px;}

    .forgot-form {
        .form-group { margin-bottom: 1.5rem;
        label { display: block; font-size: 0.875rem; margin-bottom: 0.5rem;
            .required { color: #ef4444; margin-left: 0.25rem; }
        }
        input[type="email"],
        .password-input-container { width: 100%; padding: 0.75rem; font-size: 0.875rem; border: 1px solid #d1d5db; border-radius: 0.375rem; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            &:focus-within { outline: none; border-color: #7242F9; box-shadow: 0 0 0 3px rgba(114, 66, 249, 0.1); }
        }
        
        }
    .submit-button { width: 100%; padding: 0.75rem; background-color: #7242F9; color: white; font-size: 1rem; font-weight: 600; border: none; border-radius: 0.375rem; cursor: pointer; transition: background-color 0.2s ease;
        &:hover { background-color: darken(#7242F9, 10%); }
    }
    .back-to-login-wrapper { display: flex; align-items: center; gap: 8px; justify-content: center; align-items: center; margin: 30px 0px 0px 0px;
    .arrow { width: 16px; height: 16px; color: #7242F9; }
    .back-to-login { font-family: 'Inter', sans-serif; font-size: 14px; font-weight: 700; line-height: 16px; color: #7242F9; opacity: 1; text-align: left; text-decoration: none;  }
    &:hover { cursor: pointer; opacity: 0.8; }
    }
    }
}
    @media (max-width: 640px) {
    .forword-container { padding: 2rem 1rem;  max-width: 100%; }
    .forword-card { padding: 1.5rem; }
    }
    @media (min-width: 641px) and (max-width: 1024px) {
    .forgot-container { max-width: 36rem; }
    }