.deploy-card { 
  & { background: #FAFAFA; border-radius: 17.24px; padding: 60px 0px; height: auto; display: flex; justify-content: center; text-align : center; }
  .cards {
    & { width: 100%; display: flex; align-items: stretch; justify-content:space-between; background-color: #FFFFFF; }
    .relationships {
      & { min-height: 310px; height: 100%; width: 32%; background-color: #FAFAFA; border-radius: 18px; padding: 32px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; }
      .icon {
        & { display: flex; align-items: center; justify-content: center; height: 40px; width: 40px; background-color: #D2F5EA; border-radius: 50%; position: relative; }
        img { position: absolute; top: 30%; left: 28%; }
      }
      .relationship-statement { 
        & { font-size: 28px; font-weight: 500; line-height: 40px; text-align: left; color: #41C0A1; max-width: 15ch; font-family: $Poppins;}
        .highlight-green { color: #1D846F; }
        b { font-weight: 500; color: #1D846F; }
      }
      .relationship-statement-ThrivingDimensions { 
        & { font-size: 28px; font-weight: 500; line-height: 40px; text-align: left; color: #41C0A1; max-width: 16ch; font-family: $Poppins; }
        .highlight-green { color: #1D846F; }
        b { font-weight: 500; color: #1D846F; }
      }
    }
    .health {
      & { min-height: 310px; height: 100%; width: 32%; background-color: #FAFAFA; border-radius: 18px; padding: 32px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; }
      .icon {
        & { display: flex; align-items: center; justify-content: center; height: 40px; width: 40px; border-radius: 50%; position: relative; }
        img { height: 20px; width: 25px; position: absolute; top: 25%; left: 20%; }
      }
      .health-statement {
        & { font-size: 28px; font-weight: 500; line-height: 40px; text-align: left; color: #53CBFF ; max-width: 15ch; font-family: $Poppins; }
        .highlight-orange { color: #0D78E8 ; }
        b { font-weight: 500; color: #0D78E8; }
      }
      .health-statement-ThrivingDimensions {
        & { font-size: 28px; font-weight: 500; line-height: 40px; text-align: left; color: #FDBA74; max-width: 16ch; font-family: $Poppins; }
        .highlight-orange { color: #EA580C; }
        b { font-weight: 500; color: #EA580C; }
      }
      svg { width: 18px; }
    }
    .finance {
      & { min-height: 310px; height: 100%; width: 32%; background-color: #FAFAFA; border-radius: 18px; padding: 32px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; }
      .icon {
        & { display: flex; align-items: center; justify-content: center; height: 40px; width: 40px; background-color: #FBE7FF; border-radius: 50%; position: relative; }
        img {position: absolute; top: 25%; left: 25%; }
      }
      .contentment-statement {
        & { font-size: 28px; font-weight: 500; line-height: 40px; text-align: left; color: #06B6D4; max-width: 15ch; font-family: $Poppins; }
        .highlight-orange { color: #0891B2; }
        b { font-weight: 500; color: #0891B2; }
      }
      .finance-statement {
        & { font-size: 28px; font-weight: 500; line-height: 40px; text-align: left; color: #EB73FF; max-width: 15ch; font-family: $Poppins; }
        .highlight-purple { color: #A715B6; }
        b { font-weight: 500; color: #A715B6; }
      }
      .fade-in { opacity: 1; transition: opacity 1s ease-in; }
      .fade-out { opacity: 0; transition: opacity 1s ease-out; }
    }
  }
  .capitalize { text-transform: capitalize; border-bottom: 1px dashed; }
  .eyeIcon { & { width: 40px; height: 40px; padding: 9px; border-radius: 50px; background: #D2F5EA; display: flex; margin: auto; }
    .img { width: 100%; height: 100%; text-align : center; }   
  }
  .deploy-card-title { & { width: 400px; }
    p {  margin: 10px 0px !important;  color: #737373; font-weight: 500; font-size: 28px; line-height: 40px; font-family: 'F37 Jan'; }
  }
  .deploy-button-container { & { display: flex; justify-content: center; }
    .deploy-button { & { background-color: #FFFFFF; border: 1px solid #E5E5E5; width: 226px; padding: 16px 15px; border-radius: 10px; display: flex ;  align-items: center;justify-content: center; gap: 7px; cursor: pointer; }
      .deploy-button-btn-text { color: #262626; font-weight: 700; font-size: 14px; line-height: 21px; }
    }
  }
}

//animation
@keyframes slideInRight {
  0% { transform: translateX(10%); opacity: 0; }
  100% { transform: translateX(0) ;opacity: 1;}
}

@keyframes slideOutLeft {
  0% { transform: translateX(0); opacity: 1; }
  100% { transform: translateX(-10%); opacity: 0; }
}
.slide-in-right {  animation: slideInRight 0.5s ease forwards; }
.slide-out-left { animation: slideOutLeft 0.5s ease forwards; }