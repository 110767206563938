.comparison-bar-chart { height: 367px; width: 100%;
    #blurBar {filter: blur(5px);}
    .comparison-bar-tooltip { font-family: "Poppins"; text-align: left;  font-weight: 300; width: 200px; height: 116px; border-radius: 12px; background-color: #fff; padding: 12px 12px; color: #262626; box-shadow: 0px 0px 10px #b2b2b2; pointer-events: all;
        p { margin-bottom: 5px; font-size: 12px;}
        .btn-box {
            display: flex; justify-content: center; width: 100%;
            
            .tool-tip-btn {
              display: flex; align-items: center; justify-content: center; width: 168px; height: 32px; border-radius: 8px; gap: 8px;  color: #FFF; font-weight: 700; border: none; background: var(--charcoal-900-black, #171717); cursor: pointer; font-size: 12px; transition: background 0.3s ease, width 200ms ease, transform 150ms ease; box-sizing: border-box; max-width: 180px;
              
              &:hover {
                background: #424142; width: 200px;
              }
              
              &:active {
                background: #262626; transform: translateY(5px);
              }
              
              img {
                height: 11px; transition: transform 0.3s ease;
          
                &:hover {
                  transform: translateX(3px);
                }
              }
            }
          }          
    }
    .comparison-bar-tooltip:after { content: ""; position: absolute; left: 85px; top: 116px; margin: 0 auto; width: 0; height: 0; border-top: 8px solid #fff; border-left: 8px solid transparent; border-right: 8px solid transparent; }
    .comparison-bar-tooltip button { display: flex; align-items: center; justify-content: center; width: 168px; height: 32px; border-radius: 8px; gap: 8px;  color: #FFF; font-weight: 700; border: none; background: var(--charcoal-900-black, #171717); cursor: pointer;
    }
    .comparison-bar-tooltip button:focus {
        outline: none;
    }
}

