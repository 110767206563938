$F37: 'F37 Jan', sans-serif;
$Poppins: 'Poppins', sans-serif;

.flourishing-main { padding: 0 24px;
  .flourishing-title { color: #000000; font-weight: 400; font-size: 32px; line-height: 44px; font-family: $F37; }
  .flourishing-subtitle { & { color: #737373; font-weight: 400; font-size: 18px; line-height: 24px; height: 48px; margin-top: 4px; display: flex; justify-content: space-between; align-items: center; }
    .left-paragraph { max-width: 44ch; font-family: $Poppins; font-size: 18px; font-weight: 400; line-height: 24px; color: #737373; }
    .right-paragraph { text-align: end; font-family: $Poppins; font-size: 18px; font-weight: 400; line-height: 24px; color: #737373; }
  }
  .flourishing-container { & { margin: 24px 0px; background-color: #ffffff; border-radius:  20px; box-shadow: 0px 0px 6px 0px #0000000D, 0px 0px 15px -3px #0000001A; padding: 32px 60px 32px 32px; display: flex; justify-content: space-between; align-items: center; } 
    .img-container { & { width: 65%; max-width: 536px; position: relative; }
      img { width: 100%; height: auto; }
      .absolute-content { & { width: 100%; height: 100%; position: absolute; top: 0; left: 0; display: flex; justify-content: center; align-items: center; gap: 20px; flex-direction: column; text-align: center; } 
        h1 { font-weight: 400; font-size: 24px; line-height: 29px; color: #171717; max-width: 285px; margin: 0 auto; }
        button { & { display: flex; justify-content: center; align-items: center; padding: 12px 24px; background-color: #171717; color: #FFFFFF; gap: 8px; border-radius: 8px; cursor: pointer; } 
          p { font-size: 14px; line-height: 21px; font-weight: 700; margin: 0; }
          img { width: 24px; height: 24px; }
        }
      }
    }
    .content { & { display: flex;  font-family: $Poppins; flex-direction: column; justify-content: center; align-items: flex-start; gap: 44px; width: 36%; max-width: 330px;} 
      .content-card { & { width: 100%; } 
        h1 { font-size: 24px; font-family: $F37; line-height: 29px; font-weight: 400; color: #171717; margin: 0; margin-left: 10px; margin-bottom: 16px; }
        .chart-item {
          & { display: flex; align-items: center; justify-content: space-between; padding: 8px; text-decoration: none; color: unset; margin-bottom: 6px; }
          .item-heading {
            & { display: flex; align-items: center; gap: 12px; }
            img { height: 32px; }
            h3 { font-size: 14px; font-weight: 400; font-family: $Poppins; line-height: 21px; color: #171717; text-transform: capitalize; }
          }
          .item-score { 
            & { display: flex; align-items: center; justify-content: flex-end; gap: 24px; }
            p { font-size: 14px; font-weight: 400; line-height: 17px; text-transform: capitalize; color: #737373; }
          }
        }
        p { font-size: 14px; line-height: 20px; font-weight: 400; color: #737373; margin: 0 }
      }
    }
  }
}

.pieTooltiptext { max-width: 250px; padding: 10px; box-shadow: 0px 0px 10px #b2b2b2; background: #fff; font-size: 6.78px; font-weight: 300; line-height: 21px; color: #171717; font-family: Poppins; line-height: 10.17px; border-radius: 4px;}