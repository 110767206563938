.tools-container { & { padding: 40px 50px; color: #171717; background-color: #FFFFFF; } 
  .tools-header { & { max-width: 956px; }
    h3 { font-family: $F37; font-weight: 700; font-size: 24px; line-height: 24px; margin-bottom: 12px; }
    p { font-family: $Poppins; font-weight: 300; font-size: 18px; line-height: 26px; }
    p.smaller { font-size: 16px; line-height: 22px; margin-top: 24px; }
  }
  .assessment-cards-container { & { width: 100%; margin: 54px 0; display: flex; justify-content: flex-start; align-items: center; gap: 16px; flex-wrap: wrap; }
    .assessment-card { & { width: calc(50% - 8px); border: 1px solid #EEEEEE; box-shadow: 0 12px 24px -6px #0000003D; border-radius: 16px; padding: 16px; background-color: #FAFAFA; max-width: 589px; }
      .image-container { & { width: 75px; height: 75px; background-color: #D03138; border-radius: 8px; display: flex; justify-content: center; align-items: center; }
        img { width: 48px; height: 48px; }
      }
      .heading { & { margin-top: 50px; display: flex; justify-content: flex-start; align-items: center; gap: 15px; } 
        h3 { font-weight: 700; font-size: 18px; line-height: 28px; font-family: $F37; color: #000000; }
        p.assessment-tag { padding: 8px 12px; border: 1px solid #D4D4D4; border-radius: 18px; font-family: $Poppins; font-weight: 700; font-size: 12px; line-height: 18px; color: #262626; }
      }
      .assessment-desc { margin-top: 15px; color: #000000; font-size: 16px; line-height: 22px; font-family: $Poppins; font-weight: 300; width: 100%; max-width: 286px; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; }
      .footer { & { margin-top: 60px; display: flex; justify-content: space-between; gap: 20px; align-items: center; white-space: nowrap; flex-wrap: wrap; } 
        .assessment-info { & { display: flex; justify-content: center; align-items: center; gap: 5px; font-size: 14px; line-height: 20px; font-weight: 300; font-family: $Poppins; color: #737373; } 
          .seperator { width: 3px; height: 3px; background-color: #737373; border-radius: 10px; }
        }
        .respondants { font-family: $Poppins; font-weight: 700; font-size: 12px; line-height: 18px; color: #5F6368; }
      }
    }
  }
  @media screen and (max-width: 900px) {
    .assessment-cards-container .assessment-card .footer { flex-direction: column; align-items: flex-start; gap: 10px; }
  }
  @media screen and (max-width: 780px) {
    .assessment-cards-container .assessment-card .heading { flex-direction: column; align-items: flex-start; gap: 5px; }
  }
  @media screen and (max-width: 768px) {
    .assessment-cards-container { flex-direction: column; align-items: flex-start; }
    .assessment-cards-container .assessment-card { width: 100%; }
    .assessment-cards-container .assessment-card .heading { flex-direction: row; align-items: center; gap: 15px; flex-wrap: wrap; }
    .assessment-cards-container .assessment-card .footer { flex-direction: row; align-items: center; gap: 20px; }
  }
}