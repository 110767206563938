.assessment-container {
  & { background-color: #ffffff; width: 100%; height: fit-content; padding-bottom: 40px; max-width: 1256px; height: fit-content; margin: auto; }
  .error-name { padding: 0px 24px; color: red; font-size: 0.75rem; }
  .MuiTabs-root { border-bottom: 0.5px solid #E5E5E5; padding: 0 24px; margin-top: -19px;
  .MuiTab-textColorPrimary { font-family: Poppins; font-size: 14px; font-weight: 700; line-height: 21px; color: #737373; }
  .MuiTabs-indicator { height: 4px; background-color: #262626; }
	.Mui-selected { color: #262626; }
	.MuiTab-labelIcon { display: flex; align-items: center; text-transform: none; flex-direction: row; justify-content: flex-start; gap: 9px; padding: 2px; font-family: $Poppins; margin-right: 24px;
		img { margin-bottom: 0px; }
	}
	.MuiTabs-flexContainer { height: 60px; }
  }
	.date-container { 
    & { display: flex; margin-top: 36px; flex-direction: column; }
    .startdate {
      & { display: flex; flex-direction: column; gap: 15px; }
    }
    .enddate {
      & { display: flex; flex-direction: column; margin-top: 32px; gap: 15px; }
    }
    .lable { font-weight: 600; font-size: 14px; line-height: 21px; color: #171717; font-family: $Poppins; }
    input { border-radius: 8px; background-color: #FAFAFA; border: 1px solid #E5E5E5; padding: 8px 16px; font-family: $Poppins; font-size: 14px; font-weight: 300; line-height: 21px; color: #737373; min-width: 225px; max-width: 225px; width: 100%; }
    .error-date { color: red; font-size: 0.75rem }
  }
  .collection { margin: 20px 0; display: flex; align-items: center; gap: 10px;
    .planningcenter { width: 120px; }
  }
  .bottom-line { padding: 32px 0; margin: 2px 0; border-bottom: 1px solid #E5E5E5; }
  .cta-add { color: #262626; font-weight: 700; line-height: 18px; font-size: 12px; width: 180px; padding: 12px; border: 1px solid var(--Charcoal-200, #E5E5E5); border-radius: 8px; font-family: Poppins;  text-transform: none;}
  .cta-close { color: #262626; font-weight: 700; line-height: 18px; font-size: 12px; width: 161px;height: 40px; padding: 12px; border: 1px solid var(--Charcoal-200, #E5E5E5); border-radius: 8px; font-family: Poppins;  text-transform: none;}
  .remove-img-btn {color: #B91C1C;text-decoration: underline;font-weight: 700;line-height: 18px;font-size: 12px;text-transform: none;}
  .remove-img-btn:hover {
    text-decoration: underline !important;
  }
  .selected-image-container { display: flex; gap: 8px; align-items: center; margin-top: 16px;
    .selected-image { width: 48px; height: 48px; border-radius: 100px; }
    .selected-file-into {
      .selected-name  { color: #171717; font-family: Poppins; font-weight: 700; font-size: 14px; line-height: 21px; }
      .selected-filesize { color: #737373; font-family: Poppins; font-weight: 300; font-size: 12px; line-height: 18px; }
    }
  }
  .gloo { background-color: #171717; border-radius: 8px; width: 64px; height: 34px; }
  .namebox { display: flex; gap: 12px; height: 66px; grid-column: auto;
    p { font-family: Poppins; font-size: 14px; font-weight: 700; line-height: 21px; text-align: left; color:  #171717; }
    .input { border: 1px solid var(--Charcoal-200, #E5E5E5); background-color: #FAFAFA; width: 296px; height: 48px; border-radius: 10px; padding: 0px 16px; }
  }
  
  .nextbutton { display: flex; justify-content: flex-end;
    .next-button { background-color: #171717; border-radius: 12px; width: 170px; padding: 12px 20px; display: flex; align-items: center; color: #ffffff; line-height: 21px; font-weight: 700; font-size: 14px; cursor: pointer; }
  }
  .tab {
    position: relative;
    .result-count { background-color: #D9F1E1; height: 16px; width: 16px; border-radius: 100%; color: #0E6434; font-size: 10px; font-family: Poppins; font-weight: 700; line-height: 15px; text-align: center; display: flex; justify-content: center; align-items: center; position: absolute; top: 28px; left: 336px; }
  }
 
}



.assessment-header { display: flex; flex-wrap: wrap; justify-content: space-between; gap: 10px; width: 100%; align-items: center; padding: 20px 24px; position: relative;
  .left { & { display: flex; justify-content: center; align-items: center; gap: 16px; }
    .search-bar { & { display: flex; justify-content: center; align-items: center; gap: 8px; } 
      .back-button { & { width: 40px; height: 40px; display: flex; justify-content: center; align-items: center; cursor: pointer; margin-left: -13px; }
        img { width: 16px; height: auto; }
      }
      input { font-family: Poppins; font-size: 14px; font-weight: 300; line-height: 20px; text-align: left; background-color: #FAFAFA; padding: 13.2px 16px; border: 1px solid #E5E5E5; width: 365px; border-radius: 4px; color: #171717; 
        &::placeholder { color: #acacac }
      }
      input:focus { outline: none; }
    }
    .auto-save-container { display: flex; gap: 10px; align-items: center;
      .status-indicator { position: relative; display: inline-block;
        svg { width: 1.3rem;  height: 1.3rem; color: #e5e7eb;  fill: #22c55e;  animation: spin 1s linear infinite; }
        .sr-only { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0); white-space: nowrap; border-width: 0; }
      }
    p { font-family: Poppins; font-size: 14px; font-style: italic; font-weight: 300; line-height: 20px; text-align: left; color: #737373; }
  }
  }
  .right { & { display: flex; justify-content: center; align-items: center; gap: 16px; }}
    // .copy-container { & { display: flex; justify-content: center; align-items: center; padding: 12px 24px; border: 1px solid #E5E5E5; border-radius: 4px; gap: 4px; } 
    //   img { width: 12px; height: auto; }
    //   p { margin: 0; font-size: 12px; line-height: 18px; font-weight: 700; color: #262626; }
    // }
    .date-contents{&{display: flex;gap: 8px;}
    .cancel-btn{ background:#FFFFFF;color:#262626;border: 1px solid var(--Charcoal-200, #E5E5E5);width:92px;height:40px;font-weight:700;font-size:12px;line-height:18px;border-radius:8px; cursor: pointer; }
    .create-btn{ background:#171717;color:#FFFFFF;width:92px;height:40px;font-weight:700;font-size:12px;line-height:18px;border-radius:8px; cursor: pointer}
  }
    .absolute-date-content {  right: 40px; bottom: -20px; }

}
.MuiModal-backdrop.MuiBackdrop-root  {background: rgba(255, 255, 255, 0.1) ;
  backdrop-filter: blur(5px) }
.mui-classes .css-1wnsr1i {
  & { border-radius: 10px;padding: 0 !important; width: 640px !important; max-height: 740px; max-width: 100%; border: none !important; }
  .modal-container {
    & { display: flex; flex-direction: column; backdrop-filter: blur(8.899999618530273px); border-radius: 10px; }
    .modal-heading {
      & { display: flex;  justify-content: space-between; border-bottom: 1px solid #E5E5E5; padding: 16.5px 24px; }
      .heading-left {
        & { display: flex; flex-direction: column;  }
        .modal-title {
          & { font-family: $Poppins; font-size: 18px; font-weight: 800; line-height: 28px; color: #171717; }
        }
        .modal-title-info {
          & { font-family: $Poppins; font-size: 14px; font-weight: 300; line-height: 21px; color: #737373; }
        }
      }
    }
    .mid-container {
      & { display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 10px; padding: 33px 24px; }
      .confirmation-msg {
        & { display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 12px; }
        .message {
          & { font-family: 'Poppins', sans-serif; font-size: 16px; font-weight: 700; line-height: 24px; color: #171717; max-width: 34ch; text-align: center; }
        }
        .delete-info {
          & { font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 300; line-height: 21px; color: #000000; max-width: 34ch; }
        }
      }
    }
    .buttons-container {
      & { display: flex; padding: 24px; align-items: center; justify-content: flex-end; border-top: 1px solid #E5E5E5; gap: 12px; }
      .cancel {
        & { padding: 12px 24px; border-radius: 8px; border: 1px solid #E5E5E5; font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 700; line-height: 21px; color: #262626; text-transform: none; }
      }
      .delete-btn {
        & { padding: 12px 24px; border-radius: 8px; background-color: #171717; color: #FFFFFF; font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 21px; text-transform: none;padding-right: 24px; }
      }
    }
    .unique-link-container {
      & { padding: 18px 24px 22px; border-bottom: 1px solid #E5E5E5; display: flex; flex-direction: column; gap: 16px; }
      .link-heading-container {
        & { display: flex; flex-direction: column; gap: 8px; }
        .unique-link-heading {
          & { font-family: $F37; font-size: 21px; font-weight: 400; line-height: 25.2px; color: #171717; }
        }
        .unique-link-info {
          & { font-family: $Poppins; font-size: 12px; font-weight: 300; line-height: 18px; color: #525252; }
        }
      }
      .copy-link-section {
        & { display: flex; flex-direction: column; gap: 2px; }
        .link-title {
          & { font-family: $F37;  }
        }
        .copy-link-container { 
          & { display: flex; justify-content: space-between; position: relative; align-items: center; width: 400px; padding: 8px 16px; border-radius: 8px; border: 1px solid #D4D4D4; background: #FAFAFA; font-size: 12px; font-weight: 700; font-family: $Poppins; }
          .clink {
            & { background-color: #D9F1E1; color: #117D41; word-break: break-word; white-space: normal; flex-grow: 1; padding: 4px 8px; border-radius: 18px; font-family: $Poppins; font-size: 12px; font-weight: 700; }
            }
          & svg { cursor: pointer; width: 16px; height: 18px; margin-left: 10px; }
          .tooltip .tooltiptext {
            & { left: calc(0% - 50px); min-width: 120px; border-radius: 10px; text-align: center; }
            }
          .tooltip:hover .tooltiptext::after { 
            & { width: 10px; height: 10px; bottom: -5px; left: calc(50% - 5px); }
          }
        }
      }
    }
    .qr-code-container {
      & { padding: 18px 24px 22px; display: flex; flex-direction: column; align-items: flex-start; gap: 16px; border-bottom: 1px solid #E5E5E5; }
      .qr-code-heading-section {
        & { display: flex; flex-direction: column; gap: 12px; }
        .qr-heading {
          & { font-family: $F37; font-size: 21px; font-weight: 400; line-height: 25.2px; color: #171717; }
        }
        .qr-code-info, .qr-code-info-limite {
          & { font-family: $Poppins; font-size: 12px; font-weight: 300; line-height: 18px; color: #525252; }
        }
        .qr-code-info-limite { max-width: 370px; }
      }
      .qr-download-btn {
        & { padding: 12px 24px; border: 1px solid #E5E5E5; border-radius: 8px; display: flex; gap: 4px; text-transform: capitalize; }
        p { font-family: $Poppins; font-size: 12px; font-weight: 700; line-height: 18px; color: #262626; }
      }
    }
    .done-btn {
      & { padding: 24px 24px; display: flex; align-items: center; justify-content: space-between;  }
      .done {
        & { padding: 12px 24px; border-radius: 8px; background-color: #171717; color: #FFFFFF; font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 21px; }
      }
      .resources { & { padding: 10.5px 24px; border: 1px solid #E5E5E5; border-radius: 8px; display: flex; gap: 4px; text-transform: capitalize; }
        p { font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 21px; color: #262626; }
      }
    }
  }
}

.loader-container { display: flex; align-items: center; justify-content: center; height: 100vh;
  & {
    .loader { width: 72px; height: 72px; border: 5px solid #000; border-bottom-color: transparent; border-radius: 50%; display: inline-block; box-sizing: border-box; animation: rotation 1s linear infinite }
    @keyframes rotation { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }
  }
}
@media screen and (max-width: 520px) {
  .mui-classes .css-1wnsr1i .modal-container .done-btn { padding: 0px; }
}