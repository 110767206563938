.recommended-section { background-color: #ffffff;  border-radius: 16px; padding: 24px 0px; } 
.recommended-title { color: #171717; font-size: 28px; font-weight: 400; line-height: 44px; margin-bottom: 24px; font-family:  "F37 Jan"; padding-left: 24px; } 
.recommended-description { font-size: 16px; line-height: 24px; font-weight: 300; color: #737373; display: flex; margin-bottom: 24px; } 
.recommended-wrapper { display: flex; gap: 24px; flex-wrap: nowrap; overflow-x: auto; padding-bottom: 10px; scrollbar-width: thin; scrollbar-color: #ccc transparent; &::-webkit-scrollbar { height: 8px; background: transparent; } &::-webkit-scrollbar-thumb { background-color: #ccc; border-radius: 4px; } &::-webkit-scrollbar-track { background: transparent; } &:hover::-webkit-scrollbar-thumb { background-color: #aaa; } &:hover::-webkit-scrollbar-track { background: #f9f9f9; }  padding-left: 24px;} 
.recommended-item { flex: 0 0 auto; width: 284px; cursor: pointer; height: 261.56px; box-shadow: 0px 4px 6px 0px #0000000D, 0px 10px 15px -3px #0000001A; background: var(--White-White, #FFFFFF); border-radius: 16px;} 
.recommended-image { width: 284px; height: 171px; border-radius: 16px;} 
.recommended-footer{padding: 12px; display: flex; flex-direction: column;  gap: 24px; position: relative; height: 80px;
    .recommended-info { display: flex; justify-content: space-between; align-items: center; 
        p { font-family: "Poppins"; font-weight: 500; font-size: 14px; line-height: 16px; color: var(--charcoal-900-black, #171717); }
        .recommended-tag-image { width: 22.06px; height: 22.06px; border-radius: 100%;} 
    }
    .recommended-badge { width: fit-content; padding: 4.5px 5px ; font-family: "Poppins"; font-weight: 700; font-size: 12px; line-height: 18px;  border-radius: 18px; height: 20.5px; display: flex; align-items: center; justify-content: center; position: absolute; bottom: 0; background-color: #F4E7FF; color: #851DD3; }
}
.shimmer-container { display: flex; justify-content: space-between;  }
.shimmer { opacity: 50%; }
.shimmer-badge { opacity: 50%; padding: 0px; }

@media only screen and (max-width: 560px) { 
    .recommended-wrapper { display: flex; flex-direction: column; justify-content: center; padding: 24px; }
    .recommended-item { width: 100%; }
    .recommended-image { width: 100%; }
    .shimmer-container { display: flex; flex-direction: column;  }
}


 

