.spiritual-container {
  & {  max-width: 1256px; margin:auto; background-color: #FFFFFF; }
  .heading {
    & { display: flex; align-items: center; justify-content: flex-start; gap: 12px; margin: 24px 0;padding: 0 24px; }
    h1 {
      & { font-size: 36px; font-weight: 400; line-height: 44px; color: #171717; text-transform: capitalize; font-family: $F37; }
    }
  }
  .paragraph-container {
    & { margin: 34px 24px; padding: 32px; background-color: #ffffff; box-shadow: 0px 0px 15px -3px #0000001A; border-radius: 16px; }
    .para-heading {
      & { display: flex; gap: 4px; font-size: 20px; font-weight: 400; line-height: 24px; color: #1D846F; margin-bottom: 16px; font-family: $F37; }
    }
    .paragraph {
      & { font-size: 16px; font-weight: 300; line-height: 24px; color: #171717; font-family: $Poppins; }
    }
  }
  .question-buttons-container {
    & { display: flex; align-items: center; justify-content: flex-start; gap: 4px; padding: 24px 24px; overflow-x: scroll; }
    .question {
      & { cursor: pointer; background-color: #ffffff; border: 1px solid  #D4D4D4; outline: none; color: #262626; padding: 12px 24px; border-radius: 40px; font-size: 14px; font-weight: 700; line-height: 21px; text-transform: capitalize; text-align: center; white-space: nowrap; font-family: $Poppins; }
    }
    .active { background-color: #1D846F; color: #F2FBF8; }
  }
  .question-buttons-container::-webkit-scrollbar{
    display: none;
  }
  .chart-container {
    & { max-height: 600px; display: flex; gap: 25px; padding: 24px; }
    .left-container {
      & { width: 30%; padding: 44px 30px; border-radius: 16px; background-color: #F2FBF8; display: flex; flex-direction: column; justify-content: space-between; border: 0.27px solid #1D846F; }
      .paragraph {
        & { font-size: 24px; line-height: 42px; font-weight: 500; color: #41C0A1; font-family: $Poppins; }
        .highlight {
          & { color: #1D846F; }
        }
      }
    }
    .right-container {
      & { width: 70%; padding: 32px; border-radius: 16px; background-color: #FFFFFF; display: flex; flex-direction: column; justify-content: space-between; box-shadow: 0px 0px 15px 0px #0000001A; }
      .right-container-heading {
        & { display: flex; flex-direction: column; gap: 12px; }
        .questions-number {
          & { font-size: 16px; font-weight: 700; text-transform: capitalize; line-height: 20px; color: #1D846F; font-family: $F37; }
        }  
        .question-quote {
          & { font-size: 20px; font-weight: 400; line-height: 24px; color: #171717; margin: 0px 0 14px 0; font-family: $Poppins; }
        }
      }
      .right-container-footer {
        & { display: flex; align-items: flex-start; justify-content: space-between; margin-top: 30px ; }
        p { font-size: 14px; font-weight: 300; text-transform: capitalize; color: #171717; font-family: $Poppins; }
      }
      .chart { & { max-height: 280px; display: flex; justify-content: space-between; margin: 2px 0px 0px 0px; }
       .chart-bar { margin-right: 10px;
        p{ color: #171717;font-family: $Poppins; font-size: 14px; font-weight: 400; text-align: center; }
      }
        .databar { background-color: #E5E5E5; height: 280px; width: 43px; display: flex; align-items: flex-end; border-radius: 8px; }
        .dataheight { border-radius: 8px; background-color: #1D846F; width: 100%; }
      }
    }
  }
}

@media only screen and (max-width: 880px) {
  .spiritual-container { padding: 6px; width: 100%; }
  .spiritual-container .chart-container { display: block; max-height: 830px; }
  .spiritual-container .chart-container .left-container, .spiritual-container .chart-container .right-container { margin-bottom: 26px; width: 100%; }
  .spiritual-container .question-buttons-container { gap: 10px; }
  .spiritual-container .chart-container .right-container .chart .databar { height: 180px; }
  .spiritual-container .chart-container .left-container .paragraph { margin-top: 32px; }
  .spiritual-container .question-buttons-container .question { padding: 5px 14px; font-size: 10px; }
  .spiritual-container .paragraph-container .paragraph { font-size: 12px; }
}
