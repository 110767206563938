.assesments-container { & { padding: 28px;  margin: 0 auto; background-color: #ffffff; width: 100%; }
  .heading { margin-left: 15px; display: flex; align-items: center;
    .heading-aline { display: flex; }
    .barnahead { font-size: 14px; font-weight: 700; line-height: 21px; height: 20px; margin-top: 2px; }
    .asshead { color: #737373; font-size: 14px; font-weight: 700; line-height: 21px; }
  }
  .barnacharch { & { background-image: url('../../Assests/Images/ProductBanner.png'); height: 280px; background-size: cover; margin-top: 20px; border-radius: 14px; box-shadow: 0px 12px 24px -6px #0000003D; padding: 36px; }
  img { width: 48px; height: 48px; }
  h1 { font-size: 24px; font-weight: 700; line-height: 36px; color: #FAFAFA; margin: 100px 0 10px; }
  .barnacharchinfo { & { display: flex; gap: 4px; }
    .barnacharchtime, .barnacharchquestion { border-radius: 18px; font-size: 12px; font-weight: 700; line-height: 18px; color: #262626; background-color: #F5F5F5; padding: 1px 8px; }
  }
  }
  .main-content { & { display: flex; gap: 20px; margin: 40px 0 30px; }
    .leftside { width: 70%;
      .sub-content { font-weight: 300; font-size: 16px; line-height: 22px; width: 90%; }
      .review { margin: 40px 0 10px;
        h2 { color: #171717; font-size: 24px; font-weight: 700; line-height: 36px; }
        .totalreview { font-weight: 300; font-size: 16px; display: flex; gap: 20px; margin: 8px 0 40px; align-items : center ;
          .circular-images { display: flex; margin-right: 20px;
            img { width: 33px; height: 33px; border-radius: 50%; border: 2px solid white; margin-right: -15px; }
          }
        }
        .review-box { display: flex; gap: 10px; flex-wrap: wrap;
          .review-box-content { background-color: #D9D9D9; max-width: 350px; border-radius: 19px; padding: 18px;
            .review-message { height: 75%; color: hsl(0, 0%, 9%); font-family: Poppins; font-size: 16px; font-weight: 300; line-height: 22px; text-align: left; }
            .profile { height: 25%; display: flex; align-items: center; margin: 10px 0;
              img { height: 53px; width: 53px; margin-right: 20px; }
              .userdetail1 { font-size: 16px; line-height: 22px; font-weight: 300; color: #A3A3A3; width: 40%; display: flex; justify-content: center; margin-left: 40px; }
              .userdetail2 { font-size: 16px; line-height: 22px; font-weight: 300; color: #A3A3A3; width: 150px; }
            }
          }
        }
      }
      .about { & { margin: 40px 0 10px; } 
        h2 { font-weight: 700; font-size: 24px; line-height: 36px; color: #171717; margin: 40px 0 8px; }
        p { font-size: 16px; font-weight: 300; line-height: 24px; color: #171717; }
      }
    }
    .rightside { width: 30%; 
      .key-features-box { & { border: 1px solid #E5E5E5; box-shadow: 0px 10px 15px -3px #0000001A; border-radius: 18px; padding: 20px; margin: 0 auto; max-width: 380px; } 
      .key-features-title { font-size: 18px; font-weight: 700; line-height: 28px; color: #000000; }
      .features-list { font-weight: 300; font-size: 14px; line-height: 20px; color: #000; margin: 20px 0 28px; }
      .btn { height: 40px; width: 95%; font-size: 12px; line-height: 18px; border-radius: 8px; } 
      .button { & { display: flex; justify-content: center; align-items: center; flex-direction: column;  }
        .configure-btn { background: var(--charcoal-900-black, #171717);  color: #ffffff; margin: 10px 0; cursor: pointer; }
        .preview-btn { font-size: 12px; font-weight: 700; color: #262626; margin: 10px 0; border: 1px solid var(--Charcoal-200, #E5E5E5) }
      }
    }
    }
  }
}