.resource-contaioner { & { max-width: 1257px; margin: 0 auto; padding: 24px; }
  .pageheader { font-family: $F37; color: #171717; font-size: 36px; font-weight: 400; line-height: 44px; padding: 14px 0 24px; margin-left: -6px; }
  .resource-card-container { display: flex; align-items: center; gap: 16px; flex-wrap: wrap;
    .cardone { height: 250px; width: 588px; background: #171717 url('../../Assests/Images/Glowcardone.png'); background-position: top right; background-repeat: no-repeat; background-size: 222px; padding: 24px 24px 24px 32px; display: flex; gap: 32px; align-items: center; border-radius: 16px; justify-content: space-between;
      .centerdeatil {
        .detailtext { margin: 32px 0 32px;
          h3 { color: #F5F5F5; font-family: $F37; font-size: 36px; font-weight: 700; line-height: 43.2px; }
          p{ color: #D4D4D4; font-family: $Poppins; font-size: 14px; font-weight: 400; line-height: 21px; max-width: 353px; }
        }
      }
      .logo {
        img { width: 117.99px; height: 63px; }
      }
    }
    .cardtwo { height: 250px; width: 588px; background: url('../../Assests/Images/resourcecardtwo.png'); padding: 24px 24px 24px 32px; display: flex; gap: 32px; align-items: center; border-radius: 16px; justify-content: space-between;
      .centerdeatil {
        .detailtext { margin: 6px 0 16px;
          h3 { color: #F5F5F5; font-family: Helvetica; font-size: 30px; font-weight: 700 !important; line-height: 32.2px; max-width: 324px;
            span { background-color: #D03038; padding: 0px 5px;}
          }
          p{ color: #D4D4D4; font-family: $Poppins; font-size: 14px; font-weight: 400; line-height: 21px; max-width: 410px; }
        }
        .logo { border: 6px solid #D03038; width: 84px; height: 81px; }
      }
      .logo { border: 6px solid #D03038; width: 84px; height: 81px; }
    }
    .cta-card { background-color: #FFFFFF; border: 1px solid #E5E5E5; font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 21px; color: #262626; padding: 12.5px 19.2px; text-transform: none; text-decoration: none; border-radius: 8px; transition: all 0.1s ease-in-out;
      &:hover { background-color: #E5E5E5; padding: 12.5px 29.2px; }
      &:active { background: #e5e5e5e6; transform: translateY(8px); }
    }
  }
  .filteroption { display: flex; align-items: center; gap: 24px; padding: 24px 0;
    .option { display: flex; align-items: center; padding: 6.5px 24px; border-radius: 18px; font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 21px; cursor: pointer; gap: 4px; transition: all 0.1s ease-in-out; }
    .selected { color: #ffffff; background-color: #171717; border: 1px solid #171717;
      &:hover { background-color: #424142; padding: 6.5px 34px; }
      &:active { background: #262626; transform: translateY(3px); }
    }
    .unselected { border: 1px solid #D4D4D4; 
      &:hover { background-color: #E5E5E5; padding: 6.5px 34px; }
      &:active { background: #e5e5e5e6; transform: translateY(3px); }
    }
   }
  .resources { display: flex; flex-wrap: wrap; align-items: center; gap: 24px; 
    .resourcescard { background-color: #ffffff; box-shadow: 0px 4px 6px 0px #0000000D, 0px 10px 15px -3px #0000001A; border-radius: 16px; max-width: 280px; max-height: 262px; transition: transform 0.3s ease, box-shadow 0.3s ease; transform-origin: center top; cursor: pointer; }
    .resourcescard:hover { transform: scaleX(1.1) scaleY(1.06);  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); }
    .resourceimg { border-radius: 16px; 
      img { width: 280px; height: 171px; border-radius: 16px; }
    }
    .description { padding: 12px; height: 91px; display: flex; flex-direction: column; justify-content: space-between;
      .descriptionicon { display: flex; justify-content: space-between; align-items: center; gap: 10px; margin-bottom: 11px; cursor: pointer;
        p { color: #171717; font-family: $Poppins; font-size: 14px; font-weight: 500; line-height: 16px; }
        img { width: 22.07px; height: 22.07px; border-radius: 100%; }
      }
      .dimensioniconcontainer { display: flex; flex-wrap: nowrap; overflow-x: auto; max-width: 100%; gap: 8px; cursor: pointer; scrollbar-width: none; -ms-overflow-style: none;
        &::-webkit-scrollbar { display: none; }
        .belong { font-family: $Poppins; font-size: 12px; font-weight: 700; line-height: 18px; border-radius: 18px; padding: 2px 9px 2px 4.5px; display: flex; align-items: center; white-space: nowrap; background-color: #F4E7FF;  color: #851DD3;  gap: 2.5px;
          .dimensionicon { width: 15px; 
          }
          .text { height: 17px;}

        }
      }
      
    }
   }
}

@media screen and (max-width: 560px) {
  .resource-contaioner .resource-card-container .cardone, .resource-contaioner .resource-card-container .cardtwo { width: 100%; height: 345px; padding: 32px 24px 24px 24px; }
  .resource-contaioner .resource-card-container .cardone { background: #171717 url('../../Assests/Images/Glowcardonemobile.png'); background-position: top left; background-size: 178px; background-repeat: no-repeat; }
  .resource-contaioner .resource-card-container .cardtwo { width: 100%; height: 345px; }
  .resource-contaioner .resource-card-container .cta-card { display: block; text-align: center; width: 100%; }
  .resource-contaioner .resource-card-container .cardtwo .centerdeatil .detailtext h3  { margin-top: 64px; line-height: 34.5px; }
  .resource-contaioner .resource-card-container .cardone .centerdeatil .detailtext { margin: 42px 0 32px; }
  .resource-contaioner .resources .resourceimg img { width: 100%; }
  .resource-contaioner .resources .resourcescard { max-width: 100%; width: 100%; }
  .resource-contaioner .filteroption { gap: 12px;  overflow-x: auto; }
}
