.flourishingoverview-container { min-width: 596px; height: 657px; padding: 32px; border-radius: 16px; border: 1px solid #E5E5E5;
    & {
        .theader { display: flex; flex-direction: column; align-items: center; gap: 4px;
            & {
                .text { font-family: "Poppins"; font-size: 12px; font-weight: 600; line-height: 18px; letter-spacing: 1px; text-align: center; text-transform: uppercase; color: #737373; }
                .sub-heading { font-family:"F37 Jan"; font-size:28px; font-weight:400; line-height:33.6px; text-align:center; color: #171717; }
                .resultBtn { display: flex; gap: 8px; align-items: center; width: 153px; height: 40px; padding: 12px 16px; border-radius: 8px; border: 1px solid #E5E5E5; background-color: white; color: #262626; font-family: Poppins; font-size: 12px; font-weight: 700; line-height: 18px; text-align: left; cursor: pointer; margin-top: 16px; }
            }
        }

        .tContent {display: grid; margin-top: 6px; gap: 20px;}

        .barContent { display: flex; flex-direction: column; gap: 8px;
            .bHeader { display: flex; justify-content: space-between;
                & > span {display: flex; align-items: center; gap: 4px; color: #171717; font-family: "F37 Jan"; font-size: 21px; font-weight: 400; line-height: 25.2px; text-align: left; }
                .bIcons { display: flex; gap: 8px; align-items: center;
                    & > div { display: flex; justify-content: center; width: 36px; height: 36px; border-radius: 7px; padding: 11px; background: #F2FBF8; cursor: pointer; 
                        & > svg > path { fill: #1D846F; }
                    }

                    .tooltip .tooltiptext { left: inherit; bottom: calc(100% + 15px); text-align: center;font-family: "Poppins" ;}
                }
            }

        }
    }
}


// @media screen and (max-width: 1440px) {
//     .flourishingoverview-container {
//       width: 580px;
//     }
//   }
