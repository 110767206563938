.growthgoal-container {   height: 280px;  max-width: 1180px; margin: 10px auto auto auto;
    .growthgoal-header { display: flex; align-items: center; justify-content: space-between; 
        .growthgoal-header-title { font-family: "F37 Jan"; font-weight: 700; font-size: 28px; line-height: 33.6px; color: #000000; }
        .growthgoal-header-subtitle { font-family: "Poppins"; font-weight: 300; font-size: 14px; line-height: 24px; color: #525252; margin-top: 2px; }
        .growthgoal-header-scrollar { 
            display: flex; width: 121px; height: 35.21px; gap: 8px; text-align: center;
            .arrow-rigth { width: 55px; height: 35.21px; border-radius: 8.64px; padding: 8.8px; background: var(--White-White, #FFFFFF);
                  display: flex; justify-content: center; align-items: center; text-align: center; border: 1.1px solid var(--Charcoal-200, #E5E5E5) ; cursor: pointer;transition: width 200ms ease, background 0.3s ease,transform 80ms ease;
                  &:hover{background: #E5E5E5;width: 75px}
                  &:active {
                    background: #E5E5E5;
                    transform: translateY(5px);
                  }
            }
            .arrow-left {  width:55px;height: 35.21px; border-radius: 8.64px; padding: 8.8px; background: var(--White-White, #FFFFFF);
                  display: flex; justify-content: center; align-items: center; text-align: center; border: 1.1px solid var(--Charcoal-200, #E5E5E5) ; cursor: pointer;transition: width 200ms ease, background 0.3s ease,transform 80ms ease;
                  &:hover{background: #E5E5E5;width: 75px}
                  &:active {
                    background: #E5E5E5;
                    transform: translateY(5px);
                  }
            }
         }  
    }
    .growthgoal-card-container { display: flex; gap: 24px;  margin-top: 22px; overflow-x: auto; scroll-behavior: smooth; 
        .growthgoal-card-box { background: var(--White-White, #FFFFFF); width: 400px; height: 193px; border-radius: 16px; padding: 32px; display: flex; 
            .growthgoal-card-text { width: 232px ; 
                .card-text-header { display: flex; gap: 16px; align-items: center;
                    .card-text-header-title { font-family: "F37 Jan"; font-weight: 700; font-size: 18px; line-height: 21.6px; color: var(--Charcoal-900, #171717);
                    } 
                 }
                 .card-text-detail { font-family: "Poppins"; font-weight: 400; font-size: 14px;  line-height: 21px; color: var(--Charcoal-500, #525252); margin-top: 24px; width: 183px;
                 }
            }
            .growthgoal-card-bar { width: 121px;  height: 129px; display: flex; gap: 16px;
                .blur-bar-container {
                        .blur-box {  position: relative; height: 100px; width: 50px; background: #F5F5F5; border-top-left-radius: 8px; border-top-right-radius: 8px; overflow: hidden;
                            .percentage-bar { position: absolute; bottom: 0; left: 0; right: 0; background-color: #D4D4D4; border-top-left-radius: 8px; border-top-right-radius: 8px; transition: height 300ms ease-in-out; }
                        }
                    .blur-bar-percentage { color: var(--Charcoal-400, #A3A3A3);
                        font-family: "F37 Jan"; font-weight: 700;  font-size: 22px;  line-height: 26.4px; margin-top: 10px; text-align: center;
                    } 

                }
                .national-avg-bar-container {  width: 50px; display: flex; flex-direction: column; justify-content: space-between; text-align: center;
                    .national-avg-box { position: relative; height: 100px; width: 50px; background: #F5F5F5; border-top-left-radius: 8px; border-top-right-radius: 8px; overflow: hidden;
                        .national-avg-percentage-bar { position: absolute; bottom: 0; left: 0; right: 0; background-color: #00C980; border-top-left-radius: 8px; border-top-right-radius: 8px; transition: height 300ms ease-in-out;
                          }
                      }
                    .bar-percentage { color: var(--gloo-green-500-main, #00C980);  font-family: "F37 Jan"; font-weight: 700;  font-size: 22px;  line-height: 26.4px; margin-top: 10px; text-align: center;
                    }
                }
            }
        }
    }
}