
.ai-insights-people-flourishing {display: flex;gap: 16px;flex-wrap: wrap;
 .ai-insights-people-flourishing-card {  font-family: $F37; background: #FAFAFA; border-radius: 16px; padding: 24px; flex: 1; min-width: 240px;text-align: left; display: flex; flex-direction: column; justify-content: space-between;
  .powered-by-wrapper { display: flex; align-items: center; gap: 4px; margin-bottom: 16px; justify-content: space-between;
    .powered-text-container {  display: flex; align-items: center; gap: 4px; 
      .sparkle { font-size: 16px; padding-right: 4px; }
      .powered-text { color: #171717;  font-size: 16px;  font-weight: 700; }
    }
    .beta-container { 
        width: 45px; height: 24px;  padding: 8px ; background: var(--Charcoal-200, #E5E5E5); font-family: $Poppins;
        font-weight: 700; font-size: 12px; line-height: 18px;   color: var(--charcoal-700-brand, #424142); display: flex; align-items: center;
        text-align: center; border-radius: 9999px;
    }
   }
  .ai-insights-people-flourishing-insights-text { font-size: 22px; line-height: 26.4px; color:  #171717;
 font-weight: 700; padding-bottom: 20px; width: 318px;
   }
 
   .highlight-green { background: linear-gradient(90deg, #00C980 0.62%, #1F59FF 100.96%, #7C3AED 100%);
     -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; background-clip: text; font-weight: 700;
   }
 
   .highlight-blue { background: linear-gradient(90deg, #1F59FF, #7C3AED); -webkit-background-clip: text;
     -webkit-text-fill-color: transparent; background-clip: text; font-weight: 700;
      }
  }
 }
 
 .ai-insights-people-flourishing-button { display: flex; align-items: center;justify-content: center;gap: 8px; color: #262626; font-family: $Poppins; padding: 12px 24px; border-radius: 7px; border: 1px solid #E5E5E5; background-color: #fff; cursor: pointer; font-size: 14px; font-weight: 700;line-height: 18px;transition: padding 200ms ease, background 0.3s ease,transform 80ms ease;
  &:hover{padding: 12px 34px;background: #E5E5E5;}
  &:active {
    background: #E5E5E5;
    transform: translateY(5px);
  }
 }
 
