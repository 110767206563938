.sidebar { display: flex; flex-direction: column; height: 100%; width: 100%; padding: 8px 13px;
  &::-webkit-scrollbar { width: 5px;  background: transparent; height: 3px;  min-height: 3px; }
  &::-webkit-scrollbar-thumb { background-color: #737373; border-radius: 20px; border: 1px solid #1a1b1e;  height: 3px;  min-height: 3px; }
  .row { display: flex; flex-direction: row; justify-content: space-between; align-items: center; width: 100%; height: 48px;}
  .header-row { display: flex; flex-direction: row; justify-content: space-between; align-items: center; width: 100%; height: 56px; padding: 0px 3px;}
  .sidebar-header .icons { display: flex; gap: 16px; }
  .sidebar-header .icons > svg { cursor: pointer; }


  .sidebar-content { flex: 1; 
    & .insights{
      & .iheader {display: flex; gap: 3px; 
        .insights-text { font-family: "F37 Jan"; font-weight: 700; font-size: 22px; line-height: 36px; color: #F5F5F5; }
      }
      & .text {font-family: 'Poppins', sans-serif; font-size: 22px; font-weight: 700; line-height: 36px; text-align: left;}
    }

    & .section {
      .section-header { text-transform: uppercase; font-family: 'Poppins', sans-serif; font-size: 12px; font-weight: 600; line-height: 18px; letter-spacing: 2px;text-align: left; cursor: pointer; height: 40px; }
      .section-content {display: flex; flex-direction: column; gap: 8px;}
      .content { display: flex; justify-content: flex-start; align-items: center; gap: 4px; font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 600; line-height: 21px; text-align: left; color: #A3A3A3; padding: 8px; text-decoration: none; cursor: pointer; height: 40px; }
      .active { color: #FAFAFA; background-color: #262626; border-radius: 8px; }
      .dashboards-line { border: 1px solid #424142; height: 0px; width: 83px; margin: 0 3px; }
      .assesment-line { border: 1px solid #424142; height: 0px; width: 79px; margin: 0 3px; }
    }
  }
    
  .sidebar-footer { display: flex; justify-content: space-between; align-items: flex-end; border-top: 1px solid #424142; margin: -12px;
    & .fContent { display: flex; flex-direction: column; align-items: center; gap: 4px; font-size: 8px; font-weight: 700; line-height: 9.6px; text-align: center; padding: 12px; color: #A3A3A3; }
    .footer-text { font-family: "F37 Jan" ; font-weight: 700;  font-size:  8px; line-height: 9.6px ; text-align: center; }
  }
}
