.church-health-mobile-container { display: flex; flex-direction: column; gap: 24px; padding-top: 70px; background-color: #F5F5F5;
    .deploy-asesessment-banner-container { 
        display: flex; justify-content: center;
        .deploy-asesessment-banner { height: 353px; width: 345px; background: var(--White-White, #FFFFFF);  border-radius: 24px;  padding: 24px 26px; box-shadow: 0px 4px 6px 0px #0000000D, 0px 10px 15px -3px #0000001A; display: flex; flex-direction: column; gap: 24px;
            .mobile-banner-title { width: 297px; height: 54px; font-family: "F37 Jan"; font-weight: 700; font-size: 28px; line-height: 33.6px; color: var(--charcoal-900-black, #171717); 
                span { color: #851DD3; }
            }
            .mobile-banner-description { width: 297px;  height: 155px; font-family: "Poppins"; font-weight: 300; font-size: 16px; line-height: 24px; color: #000000; }
            .mobile-banner-cta { width: 297px; height: 48px; border-radius: 8px; padding: 12px 24px;background: var(--charcoal-900-black, #171717); color: var(--White-White, #FFFFFF); font-family: "Poppins"; font-weight: 700; font-size: 14px; line-height: 21px;
            margin-top: 7px;
            }
        }
    }
    .mobile-people-flourishing-container { display: flex; flex-direction: column; margin-top: 106px;
        &__heading { font-family: $F37; font-size: 28px; font-weight: 700; padding: 0px 24px; color: #171717;}
        &__description { font-family: $Poppins; font-size: 14px; font-weight: 300; padding: 0px 24px; color: #525252;}
        .people-flourishing-button { display: flex; align-items: center;justify-content: center; gap: 8px;
         font-family: $Poppins; padding: 12px 24px; border-radius: 8px; border: 1px solid #E5E5E5; background-color: #FFFFFF; color: #000;cursor: pointer; font-size: 14px; font-weight: 700; line-height: 18px; margin-top: 10px; width: 100%;
        }
    }
    .shimmer-container-growthgoal { padding: 12px 0; margin : auto;
        .shimmer-header-container { 
            .shimmer-tabs { display: flex; width: 343px; height: 70px; justify-content: space-between;}
        }
        .shimmer-cards { display: flex; gap: 16px; justify-content: center; }
        .shimmer-aiinsight { margin-top: 50px; }
      }
    
    .mobile-gowth-goals-container { display: flex; flex-direction: column;
        &__heading { font-family: $F37; font-size: 28px; font-weight: 700; padding: 0px 24px; color: #171717; margin-top: 14px; }
        &__description { font-family: $Poppins; font-size: 14px; font-weight: 300; padding: 0px 24px; color: #525252;}
        .gowth-goals-button { display: flex; align-items: center;justify-content: center; gap: 8px;
         font-family: $Poppins; padding: 12px 24px; border-radius: 8px; border: 1px solid #E5E5E5; background-color: #FFFFFF; color: #000;cursor: pointer; font-size: 14px; font-weight: 700; line-height: 18px; margin-top: 10px; width: 100%;
        }
    }
    .mobile-thriving-container { display: flex; flex-direction: column;
        &__heading { font-family: $F37; font-size: 28px; font-weight: 700; padding: 0px 24px; color: #171717; margin-top: 14px; }
        &__description { font-family: $Poppins; font-size: 14px; font-weight: 300; padding: 0px 24px; color: #525252; 
            span { color: var(--Charcoal-600, #525252); font-weight: 700; }
        }
        .mobile-thriving-tab-container {  
            display: flex; margin: 24px 24px 12px 24px; gap: 12px; overflow-x: auto;
            div { font-family: $Poppins; font-weight: 700; font-size: 12px; color: #262626; border: 1px solid #D4D4D4; border-radius: 18px; padding: 7px 28px; text-align: center; }
            .active { background: #171717; color: #FFFFFF; border-radius: 18px; }
        }
    }
    .mobile-demographics-container { display: flex; flex-direction: column;
        &__heading { font-family: $F37; font-size: 28px; font-weight: 700; padding: 0px 24px; color: #171717; margin-top: 14px; }
        &__description { font-family: $Poppins; font-size: 14px; font-weight: 300; padding: 0px 24px; color: #525252; width: 98%;
            span { color: var(--Charcoal-600, #525252); font-weight: 700; }
        }
        .viewcta { padding: 0px 24px; margin: 16px 0px;
            .cta-viewall { background-color: #FFFFFF; border: 1px solid var(--Charcoal-200, #E5E5E5); display: flex; align-items: center; gap: 8px; font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 21px; color: #262626; padding: 12px 24px; border-radius: 8px; cursor: pointer; }
          }
        .mobile-demographics-tab-container {  
            padding: 24px 24px;  height: 34px;  padding: 4px; display: flex; justify-content: center; margin : 10px 10px 20px 10px; gap: 12px;
            div { width: 108.8px; display: flex; justify-content: center; align-items: center; font-family: "Poppins"; font-weight: 700; font-size: 12px; line-height: 18px; color: var(--Charcoal-800, #262626); border: 1px solid var(--Charcoal-300, #D4D4D4); border-radius: 18px;}
            .active { background: var(--charcoal-900-black, #171717); color: var(--White-White, #FFFFFF); border-radius: 18px;
            }
        }
    }
		.filter-container-mobile { padding: 16px 24px; display: flex; flex-direction: column; gap: 6px; background-color: #FFFFFF; width: 100vw; position: fixed; z-index: 10;
			.divider { border-bottom: 1px solid #E5E5E5; width: 100%; }
			.add-comparison-container { width: 182px; height: 32px; border-radius: 8px; padding: 12px 24px; border: 1px solid #E5E5E5; display: flex; justify-content: center; align-items: center; gap: 2px; margin-top: 0px;
				.add-comparison-text { font-family: "Poppins"; font-weight: 700; font-size: 12px; line-height: 18px; color: #262626; margin-top: 1px; 
				}
			}
		}
        
}

//For iPhone
.isiPhone {
    padding-bottom: 120px;
  }
  