.church-thriving-card { font-family: $F37;  background: #FAFAFA;  border-radius: 16px;  padding: 24px;  flex: 1;  min-width: 240px; text-align: left; display: flex; flex-direction: column; justify-content: space-between;
    .powered-by-wrapper { display: flex; align-items: center; gap: 4px; margin-bottom: 16px; justify-content: space-between;
        .powered-text-container {  display: flex; align-items: center; gap: 4px; 
          .sparkle { font-size: 16px; padding-right: 4px; }
          .powered-text { color: #171717;  font-size: 16px;  font-weight: 700; }
        }
        .beta-container { 
            width: 45px; height: 24px;  padding: 8px ; background: var(--Charcoal-200, #E5E5E5); font-family: $Poppins;
            font-weight: 700; font-size: 12px; line-height: 18px;   color: var(--charcoal-700-brand, #424142); display: flex; align-items: center; text-align: center; border-radius: 9999px;
        }
       }
    .ai-insights-people-flourishing-insights-text { font-size: 22px; line-height: 26.4px; color:  #171717;
        font-weight: 700; padding-bottom: 25px; width: 318px;
    }
    .insights-text { font-size: 22px; line-height: 26.4px; color: #171717; font-weight: 700;
    }

    .highlight-green { background: linear-gradient(90deg, #1F59FF, #00C980, #7C3AED); -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; background-clip: text; font-weight: 700;
    }

    .highlight-blue { background: linear-gradient(90deg, #1F59FF, #7C3AED); -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; background-clip: text; font-weight: 700;
    }
}