@import "../../../Assests/Styles/Fonts.scss";

.people-flourishing-container {background: #FFFFFF;border-radius: 12px;padding: 32px; box-shadow: 0px 0px 6px 0px #0000000D,0px 0px 15px -3px #0000001A;
}
.people-flourishing-title{font-family: $F37;line-height: 33.6px;font-size: 28px;font-weight: 700;margin-bottom: 8px; color: #171717;
}
.people-flourishing-subtitle { max-width: 400px; font-family: $Poppins; line-height: 18px; font-weight: 300; font-size: 14px; color: #525252; margin-bottom: 24px;

 strong { font-weight: 700;}
}

.people-flourishing-chart-section { margin-bottom: 32px; padding: 10px 0px 10px 0px;
 .people-flourishing-chart-image {  width: 100%;  border-radius: 8px; height: 100%; border-radius: 10px; object-fit:contain;
 }
}

.people-flourishing-chart-legend {margin-bottom: 32px;font-family: $Poppins;font-size: 12px;font-weight: 300;color:#171717;display: flex;gap: 16px;flex-wrap: wrap;justify-content: center;align-items: center;
 .people-flourishing-chart-legend-item { display: flex; align-items: center; gap: 8px;
  .grey-dot {  background-color: #C581FF;  border-radius: 50%;  width: 8px;  height: 8px;
  }
  .green-dot {  background-color: #00A469;  border-radius: 50%;  width: 8px;  height: 8px;
  }
 }
}


.people-flourishing-insights {display: flex;gap: 16px;flex-wrap: wrap;
.people-flourishing-card {  font-family: $F37; background: #FAFAFA; border-radius: 16px; padding: 24px; flex: 1; min-width: 240px;text-align: left; display: flex; flex-direction: column; justify-content: space-between;
 .powered-by-wrapper { display: flex; align-items: center; gap: 4px; margin-bottom: 16px;
 .sparkle {font-size: 16px; padding-right: 4px;
   }
 .powered-text { color: #171717;  font-size: 16px;  font-weight: 700;
   }
  }
 .people-flourishing-insights-text { font-size: 22px; line-height: 26.4px; color:  #171717;
font-weight: 700; width: 318px;
  }

  .highlight-green { background: linear-gradient(90deg, #00C980 0.62%, #1F59FF 100.96%, #7C3AED 100%);
    -webkit-background-clip: text;
 -webkit-text-fill-color: transparent; background-clip: text; font-weight: 700;
  }

  .highlight-blue { background: linear-gradient(90deg, #1F59FF, #7C3AED); -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; background-clip: text; font-weight: 700;
     }
 }
}

.people-flourishing-button { display: flex; align-items: center;justify-content: center;gap: 8px; color: #262626; font-family: $Poppins; padding: 12px 24px; border-radius: 7px; border: 1px solid #E5E5E5; background-color: #fff; cursor: pointer; font-size: 14px; font-weight: 700;line-height: 18px; }
 

