.result-container {
  .interval { margin: 20px 0 30px; display: flex; align-items: flex-end;flex-direction:column;min-height:55px;
    .intervalbutton { background-color: transparent; cursor: pointer; border: 1px solid var(--Charcoal-300, #D4D4D4); border-radius: 18px; font-size: 12px; line-height: 18px; align-items: center; font-weight: 700; padding: 4.5px 9px 5px 9px; margin-right: 8px; }
    .intervalbutton:last-child { margin-right: 0px; }
    .active { color: #ffffff; background-color: #171717; cursor: pointer; }
  }
  .headstack { display: flex;  justify-content: space-between; align-items: center;  margin-bottom: 40px; width: 100%; }
  .areagharph { margin-left: -50px; }
  .bottomghaph { & { display: flex; justify-content: center; margin: 30px 0; gap: 16px; }
    .leftgraph { width: 50%; font-family: $F37;
      p { font-size: 32px; font-weight: 400; line-height: 38px; color: #171717; }
      .cta-insights {
        & { background-color: #171717; border-radius: 8px; color: #ffffff; font-size: 14px; font-weight: 700; line-height: 21px; padding: 10px 20px; display: flex; align-items: center; justify-content: space-between; gap: 12px; font-family: $Poppins; }
        img { height: 24px; }
      }
    }
    .rightgrpah { width: 50%; margin-left: 30px;
      .rightheader { & { display: flex; justify-content: space-between; }
        p { font-size: 32px; font-weight: 400; line-height: 38px; color: #171717; font-family: $F37; }
        .cta-rightheader { background-color: #171717; border-radius: 8px; color: #ffffff; font-size: 14px; font-weight: 700; line-height: 21px; padding: 10px 20px; display: flex; align-items: center; font-family: $Poppins;
          img { margin-right: 10px; }
        }
      }
      .thriving-charts { margin-top: 40px; display: flex; flex-direction: column; gap: 15px; }
    }
  }
}

.thriving-overview-chart-container { 
  & { display: flex; justify-content: center; align-items: flex-start; gap: 12px; width: 100%; }
  .bar-section { & { display: flex; justify-content: flex-start; align-items: flex-start; border-radius: 14px; width: 100%; flex-shrink: 1; }
    .category-container { & { padding: 15px; flex-shrink: 0; }
      .category-image { width: 30px; height: auto; }
    }
    .current-value { width: 100%; flex-shrink: 1; padding: 32px 20px; border-radius: 14px; width: 0%; font-size: 18px; line-height: 21px; font-weight: 700; transition: all 1s linear; }
  }
  .tags { & { width: 83px; display: flex; justify-content: flex-start; align-items: center; flex-wrap: wrap; gap: 4px; flex-shrink: 0; }
    .image-container { & { display: flex; justify-content: center; align-items: center; width: 25px; height: 25px; border-radius: 5px; }
      .tag-image { height: 12px; width: auto; }
    }
  }
}

@media screen and (max-width: 1200px) {
  .result-container .bottomghaph { flex-wrap: wrap; }
  }