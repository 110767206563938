.banner-container { width: 100%;  display: flex; justify-content: center; max-width: 1200px;  padding: 10px  8px 0px 8px; margin: 12px auto;
.limited-time-banner-container {  height: 72px; border-radius: 16px; border: 1px solid var(--Charcoal-200, #424142) ; padding: 16px; width: 100%;  background: var(--White-White, #171717); position: relative;  align-items: center;  
    .banner-gradient { position: absolute; top: 0; left: 0px; border-top-left-radius: 12px;  border-bottom-left-radius: 12px; width: 110.32px; }
    .banner-text-container { display: flex; align-items: center; justify-content: space-between; width: 100%;
        .left-container { display: flex; align-items: center;   gap: 12px;  }
        .right-container { color: white;  display: flex; gap: 12px; 
            .banner-cta { width: 131px; height: 40px; border-radius: 8px; padding: 12px 20px; background: var(--White-White, #171717); cursor: pointer;
            border: 1px solid var(--Charcoal-200, #424142) ; font-family: "Poppins"; font-weight: 700; font-size: 12px; line-height: 18px; color: var(--Charcoal-800, #E5E5E5); display: flex; justify-content: center; align-items: center; transition: all 0.1s ease-in-out;
            &:hover { background-color: #424142; width: 140px;  }
            &:active { background: #424142; transform: translateY(5px);}
            }
            img { cursor: pointer; }
        }
    .text-wrapper { color: var(--charcoal-900-black, #F5F5F5); display: flex; flex-direction: column; gap: 1px;  
        .title{ font-family: "Montserrat"; font-weight: 800; font-size: 14px; line-height: 20px; }
        .subtitle { font-family: "Poppins"; font-weight: 300; font-size: 10px; line-height: 16px;  }
    }
    }
}
}

@media screen and (max-width: 650px) {
  .banner-container { margin: 0; padding: 0; width: 95vw; }
  .banner-container .limited-time-banner-container { padding: 6px 12px; height: 40px; }
  .banner-container .limited-time-banner-container .banner-text-container .right-container .banner-cta { width: 142px; height: 25px; border-radius: 8px; padding: 12px 24px; border: 1px solid #E5E5E5; display: flex; justify-content: center; align-items: center; gap: 2px; margin-top: 0px; border: 1px solid var(--Charcoal-200, #424142) }
}