
    .filter-compare-box-mobile { display: flex; justify-content: space-between; 
        .filter-right-box {
            display: flex;  gap: 8px; width: 100%; justify-content: space-between;
            .mychurch-container { display: flex;  height: 25.5px; border-radius: 100px; padding: 9px 12px; border: 0.75px solid var(--Charcoal-300, #D4D4D4)  ; align-items: center; justify-content: center;  gap : 4px;
                .circle { background: #00A469; width: 9px; height: 9px; border-radius: 100%; }
                .mychurch-text { font-family: "Poppins"; font-weight: 700; font-size: 10.5px; line-height: 15.75px; color: var(--Charcoal-800, #262626); margin-top: 1px;
                }
            }
            .yeartodate-container { display: flex;  height: 25.5px; border-radius: 100px; padding: 9px 12px; border: 0.75px solid var(--Charcoal-300, #D4D4D4)  ; align-items: center; justify-content: center;  gap : 4px;
                .yeartodate-text { font-family: "Poppins"; font-weight: 700; font-size: 10.5px; line-height: 15.75px; color: var(--Charcoal-800, #262626); margin-top: 1px;
                }
            }
            .filter-cta { width: 25.5px; height: 25.5px; border-radius: 100%;  border: 0.75px solid var(--Charcoal-300, #D4D4D4) ; display: flex; justify-content: center; align-items: center;  }
            .action-btns {
                & { display: flex; gap: 8px; }

                .cta-type { border: 0.75px solid var(--Charcoal-300, #D4D4D4); background: #FFFFFF; display: flex; justify-content: space-between; align-items: center; font-family: $Poppins; font-size: 10.5px; font-weight: 700; line-height: 15.75px; border-radius: 20px; gap : 4px; padding: 9px 12px; color: var(--Charcoal-800, #262626); height: 25.5px; white-space: nowrap;

                    .status-icon { background: #00A469; width: 9px; height: 9px; border-radius: 100%; }
                }

                .cta { border: 0.75px solid var(--Charcoal-300, #D4D4D4); background: #FFFFFF; display: flex; justify-content: space-between; align-items: center; font-family: $Poppins; font-size: 10.5px; font-weight: 700; line-height: 15.75px; padding: 9px 12px; border-radius: 20px; gap : 4px; height: 25.5px; color: #262626; white-space: nowrap; }
                .cta-menu { background: #FFFFFF; width: 25.5px; height: 25.5px; border-radius: 100%;  border: 0.75px solid var(--Charcoal-300, #D4D4D4) ; display: flex; justify-content: center; align-items: center; }

                .dataset-options { display: flex; gap: 24px; width: 100%; justify-content: space-between; align-items: center; border-top: 1px solid #e5e5e5; border-bottom: 1px solid #e5e5e5; flex-direction: column; padding: 24px;
                    .options-container { display: flex; justify-content: space-between; width: 100%;
                      .options { width: 170.67px; height: 104px; padding: 20px; border-radius: 8px; border: 1px solid var(--Charcoal-300, #d4d4d4); cursor: pointer;
                        p { font-family: $F37; font-size: 15.3px; font-weight: 700; line-height: 18.76px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: var(--charcoal-900-black, #171717); margin-top: 20px; }
                      }
                      .selected-option { border: 2px solid var(--charcoal-900-black, #171717); }
                    }
                    .circles-container { display: flex; flex-direction: column; width: 100%; gap: 24px;
                      .circles-heading { display: flex; justify-content: space-between;
                        .my-circles { display: flex; gap: 8px;
                          p { font-family: $F37; font-size: 18px; font-weight: 700; line-height: 21.6px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: #171717; }
                        }
                      }
                      .circles-btns { display: flex; gap: 12px; flex-wrap: wrap;
                        button { height: 34px; gap: 4px; border-radius: 20px; border: 1px solid var(--Charcoal-300, #D4D4D4); font-family: $Poppins; font-size: 14px; font-weight: 700; line-height: 21px; background: #FFFFFF; cursor: pointer; white-space: nowrap; padding: 6px 16px; }
                        .selected { color: #FFFFFF; background: #171717; }
                      }
                    }
                    .close-circle { display: none; }
                  }
            }
        }
        .filter-left-box { display: flex; justify-content: center;  align-items: center; width: 20px; }
        .slider {
          .slide { height: 5px; width: 36px; background-color: #3C3C434D; border-radius: 100px; margin: 6px auto; }
        }
    }
    .add-comparison-container { width: 182px; height: 32px; border-radius: 8px; padding: 12px 24px; border: 1px solid var(--Charcoal-400, #A3A3A3); display: flex; justify-content: center; align-items: center; gap: 2px; margin-top: 13px;
        .add-comparison-text { font-family: "Poppins"; font-weight: 700; font-size: 12px; line-height: 18px; color: var(--Charcoal-400, #A3A3A3); margin-top: 1px; 
        }
    }
.rbd-se0qml50krh-cw { padding: 0; }
.calendar-drawer-content {
  .content-heading {
      display: flex; width: 100%; height: 64px; justify-content: space-between; align-items: center; padding: 24px;

      .heading { font-family: F37 Jan; font-size: 22px; font-weight: 700; line-height: 26.4px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: #171717; }
      .clear-span { font-family: Poppins; font-size: 12px; font-weight: 700; line-height: 18px; text-align: left; text-decoration-line: underline; text-decoration-style: solid; text-underline-position: from-font; text-decoration-skip-ink: none; color: #262626; }
  }


  .items-container {
      & { height: 252px; border-top: 1px solid var(--Charcoal-200, #E5E5E5); border-bottom: 1px solid var(--Charcoal-200, #E5E5E5); }

      ul {
          & { list-style-type: none; padding: 24px; display: flex; flex-direction: column; gap: 18px; }

          li { width: 100%; height: 42px; font-family: F37 Jan; font-size: 14px; font-weight: 700; line-height: 16.8px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: #262626; cursor: pointer; display: flex; justify-content: space-between; align-items: center; }
      }
      .items{
        &{width: 345px;}
        .item{width:100%}
      }
  }

  .btns {
      height: 96px; width: 100%; padding: 24px; display: flex; justify-content: space-between; gap: 12px;

      .cancel-cta { width: 99px; height: 48px; border-radius: 10px; border: 1px solid var(--Charcoal-200, #E5E5E5); font-family: Poppins; font-size: 14px; font-weight: 700; line-height: 21px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; background: #FFFFFF; display: flex; justify-content: center; align-items: center; }
      .apply-cta { width: 234px; height: 48px; border-radius: 10px; border: 1px solid var(--Charcoal-200, #E5E5E5); font-family: Poppins; font-size: 14px; font-weight: 700; line-height: 21px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; background: #171717; display: flex; justify-content: center; align-items: center; color: #FFFFFF; }
  }
}
.church-health-mobile-container .css-m38dr8-MuiPaper-root-MuiDrawer-paper { border-radius: 16px 16px 0 0; }
.dataset-drawer-content {
    .content-heading {
        display: flex; width: 100%; height: 64px; justify-content: space-between; align-items: center; padding: 24px;
  
        .heading { font-family: F37 Jan; font-size: 22px; font-weight: 700; line-height: 26.4px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; color: #171717; }
        .clear-span { font-family: Poppins; font-size: 12px; font-weight: 700; line-height: 18px; text-align: left; text-decoration-line: underline; text-decoration-style: solid; text-underline-position: from-font; text-decoration-skip-ink: none; color: #262626; }
    }
  
  
    .items-container {
        & { border-top: 1px solid var(--Charcoal-200, #E5E5E5); border-bottom: 1px solid var(--Charcoal-200, #E5E5E5);display: flex;justify-content: center;align-items: center;padding: 24px;flex-direction: column; }
        .items{
          &{width: 100%;display: flex;flex-direction: column;gap: 16px;}
          .item{width:100%;border: 1px solid var(--Charcoal-300, #D4D4D4);padding: 20px;border-radius: 8px;
            p{margin-top: 8px;font-family: F37 Jan; font-size: 15.63px; font-weight: 700; line-height: 18.76px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none;}
          }
          .item-active { border: solid #171717; }
        }
        .circles-item{
            width: 100%;border-top:1px solid var(--Charcoal-200, #E5E5E5);margin-top:10px;padding-top:10px;
            .circles-heading{width: 100%;display: flex;justify-content: space-between;align-items: center;
                .my-circles{display: flex;gap:8px}
            }
            .circles-btns{display:flex;gap:4px;flex-wrap:wrap;margin-top:20px;
                button{ height: 34px; padding: 12px 16px 12px 16px; gap: 4px; border-radius: 20px;border: 1px solid var(--Charcoal-300, #D4D4D4);background: #FFFFFF;display: flex;justify-content: center;align-items: center;font-family: Poppins; font-size: 14px; font-weight: 700; line-height: 21px; text-align: center; text-underline-position: from-font; text-decoration-skip-ink: none;}
                .selected{background: #171717;color: #FFFFFF;}
            }
        }
        .close-circle { display: none; }
    }
  
    .btns {
        height: 96px; width: 100%; padding: 24px; display: flex; justify-content: space-between; gap: 12px;
  
        .cancel-cta { width: 99px; height: 48px; border-radius: 10px; border: 1px solid var(--Charcoal-200, #E5E5E5); font-family: Poppins; font-size: 14px; font-weight: 700; line-height: 21px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; background: #FFFFFF; display: flex; justify-content: center; align-items: center; }
        .apply-cta { width: 234px; height: 48px; border-radius: 10px; border: 1px solid var(--Charcoal-200, #E5E5E5); font-family: Poppins; font-size: 14px; font-weight: 700; line-height: 21px; text-align: left; text-underline-position: from-font; text-decoration-skip-ink: none; background: #171717; display: flex; justify-content: center; align-items: center; color: #FFFFFF; }
    }
  }