@import '../../../Assests/Styles/Fonts.scss';

.comparative-demographics-container {
    & { background-color: #ffff; }
    .demographics-header { 
        & { display: flex; justify-content: left; align-items: center; padding: 24px; gap: 12px;  }
        .pagename { font-family: $F37; font-weight: 400; font-size: 36px; color: #171717; line-height: 44px; }
    }
    .demographics-first-container {
        & { width: 100%; height: 100%; padding: 0px 32px 0px 32px; gap: 16px; margin-bottom: 50px;}
        .first-inner-container { & { width: 100%; max-height: 330px; margin-bottom: 15px; }
            .cards { 
                & { width: 100%; background-color:  #FFFFFF; }
                .card { 
                    & { height: 172px; max-height: 100%; background-color: #ffffff; border-radius: 16px; padding: 32px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; margin-bottom: 10px; box-shadow: 0px 0px 6px 0px #0000000D; box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.2); gap: 12px;}
                    .dimension-title { height: 24px; display: flex; color: #171717; font-weight: 400; font-size: 20px; line-height: 24px; font-family: $F37; text-align: left; gap: 4px; }
                    .icon { height: 40px; }
                    .statement-box { 
                        & { font-family: Poppins; font-size: 16px; font-weight: 300; line-height: 24px; text-align: left; color: #171717; height: 95px; width: 100%; height: 100%; justify-content: space-between; }
                        .chart-container { 
                            & { display: flex; gap: 16px; align-items: center; justify-content: center; margin-top: 20px; }
                            .legend{
                                & { width: 100px; height: 160px; display: flex; justify-content: center; flex-direction: column; gap: 4px; }
                                .legend-item{
                                    & { width: 100px; gap: 8px; font-family: $Poppins; font-size: 10px; font-weight: 300; line-height: 15px; text-align: left;  }
                                } 
                            }
                        }
                    }
                    .statement { 
                        & { display: flex; flex-direction: column; gap: 24px; }
                        .col-statement { 
                            & { font-size: 25px; font-weight: 700; line-height: 40px; text-align: left; color: rgba(0, 0, 0, 0.44); width: 280px; }
                        }
                        .percentages { font-size: 56px; font-weight: 700; line-height: 36px; color: #1D846F; }
                    }
                }
                .highlight-green { color: #2AAD8F; }
            }
        }

        .third-inner-container {
            & { width: 100%; height: 100%; }
            .cards {
                & { width: 100%; align-items: center; justify-content: space-between; gap: 16px }
                .card {
                    & { width: 100%; height: 552px; padding: 24px 0px 24px 0px; gap: 24px; }
                    .tab-container { 
                        & {width: 100%; height: 46px; padding: 0px 0px 12px 0px; margin-bottom: 20px; gap: 8px; overflow-y: hidden; }
                        .tabs{
                            & { display: flex; height: 46px; padding: 0px 0px 12px 0px; gap: 8px; }
                            .tab-button{background-color: #FFFFFF; width: 120px; display: flex; white-space: nowrap; color: #262626; font-family: Poppins; font-size: 14px; font-weight: 700; line-height: 21px; text-align: center; height: 34px; padding: 12px 24px 12px 24px; gap: 4px; border-radius: 18px ; border: 1px ; justify-content: center; align-items: center; border: 1px solid  #D4D4D4; cursor: pointer; transition: width 200ms ease, background 0.3s ease, transform 80ms ease;
                                &:hover {  background: #E5E5E5; width: 140px;}
                                &:active {  background: #E5E5E5;  transform: translateY(5px);  }
                            }
                            .tab-button.active { background-color: #171717; color: #F2FBF8; cursor: pointer; transition: width 200ms ease, background 0.3s ease, transform 80ms ease;
                                &:hover {  background: #424142; width: 140px;}
                                &:active {  background: #424142;  transform: translateY(5px);  }
                            }
                        }
                    }
                    .question-container{ 
                        & { width: 100%;  height: auto; gap: 24px; background-color: #FAFAFA; border-radius: 16px; padding: 24px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; margin-bottom: 10px; box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.2); gap: 24px;}
                        .dimension-title { 
                            & { height: 47px; gap: 12px; color: #171717; font-weight: 400; font-size: 21px; line-height: 25px; font-family: $F37; text-align: left; margin-bottom: 20px; }
                            p { margin-bottom: 12px;height: 11px; font-family: F37 Jan; font-size: 16px; font-weight: 700; line-height: 19.2px; text-align: left; color: #171717; }
                            span { height: 24px; font-family: Poppins; font-size: 20px; font-weight: 400; line-height: 24px; text-align: left; color: #171717; }
                        }
                        .chart-container { & { display: flex; justify-content: space-around; width: 100%; gap: 8px; }
                          .chart { & { width: 100%; margin: 2px 0px 0px 0px; max-width: 140px; }
                            .chart-bar {  display: flex; gap: 5px; align-items: center; justify-content: center; transition: all 0.3s ease;
                                .databar-one { display: flex; flex-direction: column; align-items: center; gap: 8px; cursor: pointer;
                                    .databar-one-percentage { font-family: "F37 Jan"; font-weight: 700; font-size: 12px; line-height: 19.2px; max-width: 20px;
                                    }
                                    .dataheightone-container { background-color: #F5F5F5; height: 220px; width: 24px; position: relative; border-top-left-radius: 8px; border-top-right-radius: 8px;
                                        .dataheightone { width: 24px; position: absolute; bottom: 0; border-radius: 8px; transition: height 0.5s linear; cursor: pointer; }
                                        .tooltip-container { position: absolute; left: -80px;  width: 200px; height: 120px; background: var(--White-White, #FFFFFF);
                                            z-index: 50;   padding: 12px; border-radius: 12px; box-shadow: 0px 0px 5px #b2b2b2; display: flex; flex-direction: column; gap: 17px;
                                            p { width: 168px; height: 44px; font-family: "Poppins"; font-weight: 300; font-size: 12px; line-height: 18px; color: var(--Charcoal-800, #262626);
                                            }
                                            .btn-box { display: flex; justify-content: center; width: 100%;
                                              .tool-tip-btn { display: flex; align-items: center; justify-content: center; width: 168px; height: 32px; border-radius: 8px; gap: 8px;  color: #FFF; font-weight: 700; border: none; background: var(--charcoal-900-black, #171717); cursor: pointer; font-size: 12px; transition: background 0.3s ease, width 200ms ease, transform 150ms ease; box-sizing: border-box; max-width: 180px;
                                                &:hover { background: #424142; width: 204px; }
                                                &:active { background: #262626; transform: translateY(5px); }
                                                img { height: 11px; transition: transform 0.3s ease;
                                                  &:hover { transform: translateX(3px); }
                                                }
                                              }
                                            }
                                        }
                                            .tooltip-container:after { content: ""; position: absolute; left: 85px; top: 120px; margin: 0 auto; width: 0; height: 0; border-top: 8px solid #fff; border-left: 8px solid transparent; border-right: 8px solid transparent; }
                                    }
                                    
                                    .comparison-inactive { width:43px ; 
                                        .dataheightone {  width:43px ;  }
                                    }
                                }
                                .databar-two { display: flex; flex-direction: column; align-items: center; gap: 8px; cursor: pointer;
                                    .databar-two-percentage { color: #00C980; font-family: "F37 Jan"; font-weight: 700; font-size: 12px; line-height: 19.2px; max-width: 20px; }
                                    .dataheighttwo-container { background-color: #F5F5F5; height: 220px; width: 24px; position: relative; border-radius: 8px;
                                        .dataheighttwo { background-color: #00C980; width: 24px; position: absolute; bottom: 0; border-top-left-radius: 8px; border-top-right-radius: 8px; transition: height 0.5s linear; cursor: pointer; }
                                        }
                                  }

                            }
                            .chartlabel { margin-top: 8px; max-width: 140px; color: #171717; font-family: $Poppins; font-size: 14px; font-weight: 300; line-height: 21px; text-align: center; }
                          }
                        }
                        .lableaboutdata { display: flex; align-items: center; gap: 16px; justify-content: center; width: 100%;
                          .mydata { display: flex; align-items: center; gap: 6px;
                            .circle { background-color: #C581FF; }
                          }
                          .nationalaverage { display: flex; align-items: center; gap: 6px;
                            .circle { background-color: #00C980; }
                          }
                          .circle { width: 8px; height: 8px; border-radius: 8px; }
                          .dataname { color: #171717; font-family: $Poppins; font-size: 12px; font-weight: 300; line-height: 18px; }
                        }
                    }
                    .icon { height: 40px; }
                    .statement-box {
                        & { width: 100%; height: 100%; justify-content: space-between; }
                        .chart-container { display: flex; gap: 16px; }
                    }
                    .statement {
                        & { display: flex; flex-direction: column; gap: 24px; }
                        .col-statement {
                            & { font-size: 25px; font-weight: 700; line-height: 40px; text-align: left; color: rgba(0, 0, 0, 0.44); width: 280px; }
                        }
                        .percentages { font-size: 56px; font-weight: 700; line-height: 36px; color: #1D846F; }
                    }
                }
                .highlight-green { color: #2AAD8F; }
            }
        }
    }
}

@media (max-width: 1200px) {

    .comparative-demographics-container {
        .demographics-first-container {
            &{margin-bottom: 30px;}
            .first-inner-container { 
                &{max-height: 100%;}
                .cards { 
                    .card { 
                        & { height: auto; max-height: 100%; background-color: #Ffffff; border-radius: 16px; padding: 32px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; margin-bottom: 10px; box-shadow: 0px 0px 6px 0px #0000000D; box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.2); gap: 12px;}
                        .statement-box { 
                            & { font-family: Poppins; font-size: 16px; font-weight: 300; line-height: 24px; text-align: left; color: #171717; width: 100%; height: 100%; justify-content: space-between; }
                            .chart-container { 
                                & { display: flex; gap: 16px; align-items: center; justify-content: center; margin-top: 20px;}
                            }
                        }
                    }
                    
                }
            }
        }

        .third-inner-container {         
            .cards {
                .card {
                    .tab-container { 
                        & {width: 100%; height: 46px; padding: 0px 0px 12px 0px; margin-bottom: 20px; gap: 8px; overflow-x: scroll;-ms-overflow-style: none; scrollbar-width: none;}
                        &::-webkit-scrollbar {
                            display: none;          
                        } 
                    }
                    .question-container{ 
                        & { width:100%; gap: 24px; background-color: #FAFAFA; border-radius: 16px; padding: 24px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; margin-bottom: 10px; box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.2); gap: 24px;}
                        .chart-container { & { display: flex; justify-content: space-around; width: 100%; gap: 8px; }}
                    }
                }
            }
        }
    }
}

@media (max-width: 560px) {
    .comparative-demographics-container .demographics-header .pagename { font-size: 28px; }
    .comparative-demographics-container .demographics-first-container .third-inner-container .cards .card .question-container .dimension-title { height: auto; margin-bottom: 0; }
    .comparative-demographics-container .demographics-first-container .third-inner-container .cards .card .question-container { gap: 32px; }
    .comparative-demographics-container .demographics-first-container .third-inner-container .cards .card { height: auto; }
    .comparative-demographics-container .demographics-first-container { padding: 0 24px; }
}
